<template>
    <div class="projects-header flex-column w-100 justify-content-between">
        <div class="title-count d-flex">
            <div class="title helvetica-bold">Projects</div>
            <div class="count helvetica-bold pl-4">{{ totalProjects }}</div>
        </div>
        <div class="projects-controls d-flex w-100 justify-content-between">
            <div class="filter-controls d-flex pt-4">
                <div class="flex-column position-relative">
                    <drop-down-button
                        leftIcon='text'
                        :midText='"By " + sortBy'
                        rightIcon='downArrow'
                        @clicked='dropdowns.sorting = !dropdowns.sorting'
                    />
                    <select-dropdown
                        :options='sortingOptions'
                        class='sorting-dropdown'
                        top='30px'
                        :show='dropdowns.sorting'
                        @selected='closeSortDropdown'
                    />
                </div>
                <div class="flex-column position-relative">
                    <drop-down-button
                        leftIcon='filter'
                        midText='Filter'
                        rightIcon='downArrow'
                        class='pl-4 pr-4 filter-button'
                        @clicked='dropdowns.filter = !dropdowns.filter'
                    />
                    <projects-filter
                        top="30px"
                        :show='dropdowns.filter'
                        @close='dropdowns.filter = false'
                        @filter='filterData' />
                </div>
                <div class="sorting desktop-element" @click='changeView'>
                    <BaseIcons icon='sorting' />
                </div>
            </div>
            <div class="sorting mobile-element pt-3" @click='changeView'>
                <BaseIcons icon='sorting' />
            </div>
            <div class="search-create-controls pt-3 d-flex justify-content-between">
                <search-bar
                    class="desktop-element"
                    borderColor='#99D6E9'
                    icon='magnifyBlue'
                    @query='searchProjects'
                />
                <search-bar
                    class="mobile-element"
                    borderColor='#99D6E9'
                    :expanded='true'
                    icon='magnifyBlue'
                    @query='searchProjects'
                />
                <BaseButton
                    title='Create Project'
                    color='#fff'
                    borderRadius='20px'
                    background='#0BACDB'
                    fontFamily='Helvetica Neue-Medium'
                    width='146.4px'
                    class='ml-3 create-btn desktop-element'
                    @clicked='$emit("createProject")'
                />
                <BaseButton
                    title='Create Project'
                    color='#fff'
                    borderRadius='20px'
                    background='#0BACDB'
                    fontFamily='Helvetica Neue-Medium'
                    width='100%'
                    class='mt-3 create-btn mobile-element'
                    @clicked='$emit("createProject")'
                />
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .projects-header {
        .sorting {
            cursor: pointer;
            position: relative;
            &:active {
                top: 2px;
            }
        }
        .title-count {
            .title {
                font-size: 24px;
            }
            .count {
                font-size: 24px;
                opacity: 0.8;
            }
        }
    }
    @media (max-width: 960px) {
        .projects-header {
            .projects-controls {
                flex-direction: column;
                .filter-controls {
                    justify-content: space-between;
                    .filter-button {
                        padding: 0px !important;
                    }
                }
            }
            .search-create-controls {
                flex-direction: column;
            }
        }
    }
</style>

<script>
import DropDownButton from '../Common/DropDownButton';
import SearchBar from '../Common/SearchBar';
import SelectDropdown from '../Common/SelectDropdown';
import ProjectsFilter from '../Projects/ProjectsFilter';

export default {
    name: 'ProjectsHeader',
    props: ['totalProjects'],
    components: {
        DropDownButton,
        SearchBar,
        SelectDropdown,
        ProjectsFilter,
    },
    data: () => ({
        dropdowns: {
            sorting: false,
            filter: false,
        },
        sortingOptions: [
            { id: 'date', label: 'Date' },
            { id: 'title', label: 'Title' },
            { id: 'views', label: 'Views' },
        ],
        sortBy: 'Date',
        query: '',
    }),
    methods: {
        closeSortDropdown(sortBy) {
            this.sortBy = sortBy.label;
            this.dropdowns.sorting = false;
            // Todo: Backend logic
        },
        filterData(data) {
            this.$emit('filter', data);
            this.dropdowns.filter = false;
        },
        searchProjects(query) {
            this.$emit('query', query);
        },
        changeView() {
            this.$emit("changeView");
        }
    }
}
</script>