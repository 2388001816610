<template>
    <div class="upload-progress">
        <div
            class="progress"
            :class='failed ? "failed" : "uploading"'
            :style='{width: percentage + "%"}'
        ></div>
        <span v-if='!failed && !cancelled' class="poppins-light">
            <span v-if='percentage < 100'>
                Uploading:
            </span>
            <span v-if='percentage >= 100'>
                Upload Complete:
            </span>
        </span>
        <span v-if='failed' class="poppins-light">
            Upload Failed:
        </span>
        <span v-if='cancelled' class="poppins-light">
            Upload Cancelled:
        </span>
        <span class="poppins-light">&nbsp; {{ title }}</span>
    </div>
</template>

<style lang="less" scoped>
    .upload-progress {
        width: 100%;
        height: 5px;
        background: #B6C6D0;
        border-radius: 2px;
        .poppins-light {
            font-style: italic;
            font-size: 11px;
        }
        .progress {
            height: 5px;
            border-radius: 2px;
        }
        .uploading {
            background: #17EB27;
        }
        .failed {
            background: #E60606;
        }
    }
</style>

<script>
export default {
    name: 'UploadProgress',
    props: ['percentage', 'failed', 'cancelled', 'title'],
}
</script>