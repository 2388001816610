<template>
	<div class="appointment-booking">
		<div
			class="response-header-section d-flex justify-content-between poppins-medium"
		>
			<div class="title">Configure appointments & booking</div>
			<div
				class="back-btn base-links cursor-pointer"
				@click="$emit('section', '')"
			>
				Change Interaction
			</div>
		</div>
		<div class="appointment-div mt-3">
			<BaseInput
				placeholder="Enter your booking link"
				border="1px solid #ccc"
				borderRadius="8px"
				class="booking-link"
				:inputValue="booking_link"
				keyTrigger="Enter"
				@value="booking_link = $event"
			/>
			<p class="mt-3">
				Supported Platforms: Calendly, Acuity Scheduling, Hubspot
				Meetings, ScheduleOnce, Calendar, SimplyBook
			</p>
		</div>
		<div class="response-settings">
			<input-with-label
				v-for="(element, index) in inputElements"
				:key="index"
				:headLabel="element.headLabel"
				:subLabel="element.subLabel"
				:class="element.class"
				:inputValue="getDataVar(element.input)"
				@value="handleInputs(element.input, $event)"
				:inputType="element.type"
			/>
		</div>
	</div>
</template>

<style lang="less">
.appointment-booking {
	.appointment-div {
		.booking-link {
			.base-input {
				width: 85%;
			}
		}
		p {
			font-size: 15px;
			color: #1e39419a;
		}
	}
}
</style>

<script>
import InputWithLabel from "../InputWithLabel";

export default {
	name: "AppointmentBooking",
	props: ["interactionsData"],
	components: {
		InputWithLabel,
	},
	mounted() {
		this.populateInteractionsData();
	},
	data: () => ({
		booking_link: "",
		delay_interaction_by: 15,
		inputElements: [
			{
				input: "delay_interaction_by",
				headLabel: "Delay interaction by",
				subLabel: "seconds",
				class: "mb-4",
				type: "number",
			},
		],
	}),
	methods: {
		handleInputs(toggle, value) {
			if (value > 3) {
				value = 3;
			}
			this[toggle] = parseInt(value);
		},
		getDataVar(variable) {
			return this[variable];
		},
		populateInteractionsData() {
			const interactionsData = this.$props.interactionsData;
			if (interactionsData) {
				this.booking_link = interactionsData.booking_link;
				this.delay_interaction_by =
					interactionsData.delay_interaction_by;
			}
		},
	},
	watch: {
		$data: {
			handler(data) {
				let { booking_link, delay_interaction_by } = data;
				this.$emit("data", {
					booking_link,
					delay_interaction_by,
				});
			},
			deep: true,
		},
		interactionsData: {
			handler() {
				this.populateInteractionsData();
			},
			deep: true,
		},
	},
};
</script>
