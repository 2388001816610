<template>
    <div
        class="switch-container cursor-pointer"
        @click="$emit('clicked')"
        :class="{'active-switch': active}"
    >
        <div class="rounded-btn smooth-shadow">
        </div>
    </div>
</template>

<style lang="less">
    .switch-container {
        background: #EE5951;
        border-radius: 20px;
        width: 40px;
        height: 18px;
        position: relative;
        transition: 0.3s;
        .rounded-btn {
            position: absolute;
            background: white;
            left: 0;
            margin: auto;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            transition: 0.3s;
        }
    }
    .active-switch {
        background: #17EB27;
        .rounded-btn {
            right: 0;
            left: inherit;
        }
    }
</style>

<script>
export default {
    name: 'SwitchToggle',
    props: ['active']
}
</script>