<template>
    <transition name="fade">
        <div class="login-container">
            <div class="login-form">
                <div class="base-loader-line">
                    <div v-if='loading' class="base-loading-progress-line"></div>
                </div>
                <div class="login-info">
                    <big-command-logo />
                    <div class="login-info-text">
                        <div class="title-text text-center">
                            <span v-if='pageLevel === "email"'>Sign in</span>
                            <span v-else>Welcome</span>
                        </div>
                        <div class="description-text text-center position-relative">
                            <span v-if='pageLevel === "email"'>Use your BigCommand Account</span>
                            <selected-input
                                :show='pageLevel === "password"'
                                :text='email'
                                animation="animate-down"
                                @clicked='reset'/>
                        </div>
                    </div>
                </div>
                <div class="login-inputs">
                    <div class='input-tag' :class="{'d-none': pageLevel !== 'email'}">
                        <label for="email">
                            Email Address
                        </label>
                        <BaseInput
                            placeholder='Enter Email'
                            className='email'
                            type='email'
                            :inputValue='email'
                            keyTrigger='Enter'
                            @value='email = $event'
                            @keyPressed='attemptLogin'
                        />
                    </div>
                    <div class="input-tag" :class="{'d-none': pageLevel !== 'password'}">
                        <label for="password">Password</label>
                            <BaseInput
                                placeholder='Enter Password'
                                className='password'
                                :type='passwordInput ? "password" : "text"'
                                :inputValue='password'
                                keyTrigger='Enter'
                                inputId='password'
                                :icon='passwordInput ? "eye" : "eyeNoLine"'
                                @value='password = $event'
                                @keyPressed='attemptLogin'
                                @iconClicked='passwordInput = !passwordInput'
                            />
                    </div>
                    <errors
                        :show='errors.length > 0'
                        :errors='errors'
                        color='#EE5951'
                    />
                </div>
                <div class="login-lower-controls">
                    <div class="lower-section d-flex justify-content-between">
                        <div class="remember-container d-flex" @click="rememberMe = !rememberMe">
                            <BaseIcons :icon='rememberMe ? "checked" : "unchecked"'/>
                            <div class="remember-me-text">
                                Remember Me
                            </div>
                        </div>
                        <router-link to='reset-password' class="forget-password">
                            Forgot Password?
                        </router-link>
                    </div>
                    <div class="lower-section login-buttons d-flex justify-content-between">
                        <router-link to='/register' class="register-link base-links">
                            Create Account
                        </router-link>
                        <BaseButton
                            :loading='loading'
                            color='#fff'
                            background='#0DABD8'
                            :title='pageLevel === "email" ? "Next" : "Sign In"'
                            borderRadius='20px'
                            width='97.34px'
                            height='36px'
                            padding='5px'
                            @clicked='attemptLogin'
                        />
                    </div>
                </div>
            </div>
            <div class="lower-text">
                <company-rights-text />
            </div>
        </div>
    </transition>
</template>
<style lang="less" scoped src='../../assets/css/fonts.less'></style>
<style lang="less" scoped>
    .login-container {
        background: #1D2331;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        font-family: "Helvetica Neue";
        .login-form {
            background: #FFFFFF;
            width: 469.64px;
            min-height: 438.89px;
            max-height: 463.89px;
            border-radius: 5px;
            border: 1px solid #C9EDF8;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            .login-info {
                position: relative;
                left: 0;
                right: 0;
                top: 30px;
                margin: auto;
                width: max-content;
                .login-info-text {
                    margin-top: 5px;
                    .title-text {
                        font-family: "Helvetica Neue-Medium";
                        font-size: 24px;
                        padding: 15px 0;
                    }
                    .description-text {
                        font-size: 16px;
                    }
                }
            }
            .login-inputs {
                position: relative;
                top: 50px;
                left: 0;
                right: 0;
                margin: auto;
                width: 395.4px;
                label {
                    font-family: "Helvetica Neue";
                    font-size: 13px;
                }
            }
            .login-lower-controls {
                width: 395.4px;
                position: relative;
                left: 0;
                right: 0;
                margin: auto;
                top: 70px;
                .lower-section {
                    position: relative;
                    .remember-container {
                        cursor: pointer;
                        .remember-me-text {
                            font-size: 14px;
                            position: relative;
                            top: 3px;
                            left: 10px;
                        }
                    }
                    .forget-password {
                        font-size: 14px;
                        color: #A3BAC6;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
                .login-buttons {
                    top: 40px;
                    .login-button {
                        color: #0DABD8;
                        border-radius: 20px;
                        width: 97.34px;
                        height: 36px;
                    }
                    .register-link {
                        display: flex;
                        align-self: center;
                    }
                }
            }
        }
        .lower-text {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            margin: auto;
            width: max-content;
        }
    }
    @media (max-width: 960px) {
        .login-container {
            .login-form {
                width: 90%;
                .login-inputs,
                .login-lower-controls {
                    width: 90%;
                }
            }
        }
    }
</style>

<script>
import BigCommandLogo from '../../components/Common/BigCommandLogo.vue';
import CompanyRightsText from '../../components/Common/CompanyRightsText.vue';
import validator from 'validator';
import Errors from '../../components/Common/Errors.vue';
import SelectedInput from '../../components/Common/SelectedInput.vue';

export default {
    name: 'Login',
    data: () => ({
        loading: false,
        rememberMe: false,
        email: '',
        password: '',
        pageLevel: 'email',
        errors: [],
        passwordInput: true,
    }),
    components: {
        BigCommandLogo,
        CompanyRightsText,
        Errors,
        SelectedInput,
    },
    methods: {
        attemptLogin() {
            const validated = this.validate();
            const level = this.pageLevel;
            if (!validated) return false;
            if (validated) {
                if (level === 'email') this.checkEmail();
                else if (level === 'password') this.login();
            }
        },
        validate() {
            const level  = this.pageLevel;
            const errors = [];
            if (level === 'email') {
                if (validator.isEmpty(this.email)) {
                    errors.push('Please enter your email');
                } else if (!validator.isEmail(this.email)) {
                    errors.push('Please enter a valid email');
                }
            } else if (level === 'password') {
                if (validator.isEmpty(this.password)) {
                    errors.push('Please enter your password');
                }
            }
            this.errors = errors;
            return errors.length < 1;
        },
        checkEmail() {
            const email     = this.email;
            const url       = `auth/user/email?email=${email}`;
            this.loading    = true;
            this.axios.get(url)
                .then(response => this.handleEmailCheckResponse(response.data))
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                })
        },
        handleEmailCheckResponse(response) {
            this.loading = false;
            if (response.success) {
                this.pageLevel = 'password';
            } else {
                this.errors.push(response.message);
            }
        },
        login() {
            const { email, password, rememberMe } = this;
            this.loading = true;
            this.axios.post('auth/user/login', {
                email, password, rememberMe
            }).then(response => {
                window.localStorage.setItem('token', response.data.access_token)
                window.localStorage.setItem('auth-user', JSON.stringify(response.data.user_id))
                this.$router.go('/projects');
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
            });
        },
        reset() {
            this.pageLevel = 'email';
            this.password = '';
        }
    }
};
</script>