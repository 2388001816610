<template>
    <div class="password-checker">
        <div class="password-note helvetica-regular">
            Your password must meet these requirements:
        </div>
        <div class="d-flex justify-content-lg-start pt-3">
            <div class="validator-option text-center" :class="{'active': passed.uppercase}">
                <div class="requirement-symbol">A</div>
                <div class="requirement-note">
                    One uppercase character
                </div>
            </div>
            <div class="validator-option text-center" :class="{'active': passed.lowercase}">
                <div class="requirement-symbol">a</div>
                <div class="requirement-note">
                    One lowercase character
                </div>
            </div>
            <div class="validator-option text-center" :class="{'active': passed.number}">
                <div class="requirement-symbol">7</div>
                <div class="requirement-note">
                    One number
                </div>
            </div>
            <div class="validator-option text-center" :class="{'active': passed.specialChar}">
                <div class="requirement-symbol">!</div>
                <div class="requirement-note">
                    One special character
                </div>
            </div>
            <div class="validator-option text-center" :class="{'active': passed.limit}">
                <div class="requirement-symbol">{{ passLimitText }}</div>
                <div class="requirement-note">
                    8 characters minimum
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .password-checker {
        transition: 0.3s;
        .password-note {
            font-size: 13px;
        }
        .active {
            color: #0ad688;
        }
        .validator-option {
            .requirement-symbol {
                font-size: 16pt;
                font-family: 'Times New Roman,sans-serif';
            }
            .requirement-note {
                width: 100px;
                font-size: 11px;
            }
        }
    }
    @media(max-width: 960px) {
        .password-checker {
            .validator-option {
                .requirement-symbol {
                    font-size: 12pt;
                }
                .requirement-note {
                    font-size: 9px;
                    width: auto;
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'PasswordChecker',
    props: ['password'],
    data: () => ({
        passLimitText: '8<',
        passed: {
            uppercase: false,
            lowercase: false,
            number: false,
            specialChar: false,
            limit: false,
        },
    }),
    methods: {
        checkLowerCase(password) {
            this.passed.lowercase = (/[a-z]/.test(password));
        },
        checkUpperCase(password) {
            this.passed.uppercase = (/[A-Z]/.test(password));
        },
        checkNumber(password) {
            this.passed.number = (/\d/.test(password));
        },
        checkSpecialChar(password) {
            this.passed.specialChar = (/[ `!@#$%^&*()_+\-={};':"|,.<>/?~]/.test(password));
        },
        checkLimit(password) {
            this.passed.limit = password.length >= 8;
        }
    },
    watch: {
        password(password) {
            this.checkLowerCase(password);
            this.checkUpperCase(password);
            this.checkNumber(password);
            this.checkSpecialChar(password);
            this.checkLimit(password);
        },
    }
}
</script>