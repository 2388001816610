<template>
    <div class="input-label-container d-flex justify-content-between">
        <div class="label head-label poppins-medium d-flex align-self-center">{{ headLabel }}</div>
        <BaseInput
            class="type-input"
            border='transparent'
            textAlign='center'
            :inputValue='inputValue'
            @value='$emit("value", $event)'
            :type='inputType'
        />
        <div class="label sub-label poppins-medium d-flex align-self-center">{{ subLabel }}</div>
    </div>
</template>

<style lang="less" scoped>
    .input-label-container {
        width: 280px;
        font-size: 12px;
        .label {
            color: #1e39419a;
        }
        .head-label {
            width: 150px;
        }
        .type-input {
            width: 64px;
            height: 39px;
        }
    }
</style>

<script>
export default {
    name: 'InputWithLabel',
    props: ['headLabel', 'subLabel', 'inputValue', 'inputType'],
}
</script>