<template>
    <transition name="fade">
        <div class="warning-modal" v-if='show'>
            <div class="warning-content">
                <div class="warning-container">
                    <div class="warning-head text-center pt-3 pb-3 helvetica-medium">
                        CLOSE CAMPAIGN
                    </div>
                    <div class="warning-body pt-3 pb-3 flex-column text-center">
                        <div class="body-text pt-2 pb-2 helvetica-medium">Are you sure you want to take this action?</div>
                        <div class="body-text pt-2 pb-2 helvetica-medium">Any unsaved configurations will be lost.</div>
                    </div>
                    <div class="warning-foot pt-3 pb-4 d-flex justify-content-center">
                        <div class="button-container" @mouseover="selected = 'no'" @mouseleave="selected = ''">
                            <BaseButton
                                title='No'
                                background='#862D83'
                                :opacity='selected != "no" ? "0.7" : "1"'
                                color='#fff'
                                height='36px'
                                width='147px'
                                class="mr-5"
                                @clicked='close("no")'
                            />
                        </div>
                        <div class="button-container" @mouseover="selected = 'yes'" @mouseleave="selected = ''">
                            <BaseButton
                                title='Yes'
                                background='#862D83'
                                :opacity='selected != "yes" ? "0.7" : "1"'
                                color='#fff'
                                height='36px'
                                width='147px'
                                @clicked='close("yes")'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .warning-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 20;
        .warning-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .warning-container {
                background: #fff;
                width: 520.64px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                position: relative;
                font-size: 14px;
                .warning-head {
                    border-bottom: 1px solid #0DABD8;
                    font-size: 20px;
                }
                .warning-body {
                    .body-text {
                        font-size: 15px;
                    }
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'CampaignWarning',
    data: () => ({
        selected: 'yes',
        show: false,
    }),
    mounted() {
        this.setEscapeKeyEvent();
    },
    methods: {
        setEscapeKeyEvent() {
            const $this = this;
            window.addEventListener('keyup', function (event) {
                if (event.key === 'Escape') {
                    $this.show = true;
                }
            })
        },
        close(response) {
            this.$emit("clicked", response);
            this.show = false;
        }
    }
}
</script>