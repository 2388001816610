<template>
    <div class="profile-picture-container">
        <div class="outer-header mb-4">
            PROFILE PICTURE
        </div>
        <div class="profile-picture d-flex">
            <div class="picture pr-4">
                <img :src="image" alt="" v-if='image'>
                <BaseIcons icon='noPic' v-else />
            </div>
            <div class="controls">
                <div class="title-text">
                    {{ !image ? "Add Your Picture" : "Change Picture" }}
                </div>
                <div class="description-text pt-1 pb-1">For best results, upload a high resolution image.</div>
                <BaseButton
                    :title='!image ? "Add Picture" : "Change Picture"'
                    border='1px solid #0dabd8'
                    borderRadius='30px'
                    color='#26b2ee'
                    fontSize='16px'
                    width='149px'
                    height='40px'
                    @clicked="showPictureModal = true"
                />
            </div>
        </div>
        <profile-upload
            :height="300"
            :no-rotate="false"
            :width="300"
            @crop-success="cropSuccess"
            field="avatar"
            img-format="jpg"
            lang-type="en"
            ref="avatar_uploader"
            v-model="showPictureModal"
        />
    </div>
</template>

<style lang="less">
    .profile-picture-container {
        .outer-header {
            font-weight: 700;
            font-size: 13px;
        }
        .profile-picture {
            width: max-content;
            .picture {
                img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
            }
            .controls {
                .title-text {
                    font-size: 16px;
                    font-weight: 700;
                }
                .description-text {
                    font-size: 13px;
                    font-weight: 500;
                    overflow-wrap: break-word;
                    word-break: break-all;
                }
            }
        }
    }
    @media (max-width: 960px) {
        .profile-picture-container {
            .profile-picture {
                .controls {
                    .description-text {
                        width: 180px;
                    }
                }
            }
            .vue-image-crop-upload {
                .vicp-wrap {
                    width: 80%;
                    height: 500px;
                }   
            }
        }
    }
</style>

<script>
import profileUpload from "vue-image-crop-upload";
export default {
    name: 'ProfilePicture',
    props: ['picture'],
    components: { profileUpload },
    data: () => ({
        showPictureModal: false,
        image: '',
    }),
    mounted() {
        this.getImage();
    },
    methods: {
        getImage() {
            this.image = this.$props.picture;
        },
        cropSuccess(imageUrl) {
            this.$emit('image', imageUrl);
            this.showPictureModal = false;
        }
    },
    watch: {
        picture() {
            this.getImage();
        }
    }
}
</script>