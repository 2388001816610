<template>
    <div class="big-logo d-flex">
        <span v-if='!smallIcon'>
            <BaseIcons icon="bigCommandLogo"/>
        </span>
        <span v-else>
            <BaseIcons icon="bigCommandLogoSmall"/>
        </span>
        <div class="logo-text pl-3" v-if='!noText'>{{ text ? text : defaultText }}</div>
    </div>
</template>

<script>
export default {
    name: 'BigCommandLogo',
    data: () => ({
        defaultText: process.env.VUE_APP_COMPANY_NAME || 'BigCommand'
    }),
    props: ['text', 'noText', 'smallIcon'],
}
</script>

<style lang="less" scoped>
    .big-logo {
        width: max-content;
        height: max-content;
        position: relative;
        .logo-text {
            font-size: 20px;
            font-family: "Helvetica Neue-Bold";
            display: flex;
            align-self: flex-end;
        }
    }
</style>