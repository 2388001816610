<template>
    <transition name="fade">
        <div class="projects-table" v-if="show">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>TITLE</th>
                        <th>VIEWS</th>
                        <th>COMMENTS</th>
                        <th>LAST MODIFIED</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for='project in projects'
                        :key='project.id'
                        @click='redirectToVideos(project.project_id)'
                        class="table-row"
                    >
                        <td>
                            <BaseIcons icon='folder' />
                        </td>
                        <td>
                            <div class="flex-column">
                                <div class="project-title">{{ project.title }}</div>
                                <div class="videos-count" v-if="project.videos">{{ project.videos.count }} Videos</div>
                            </div>
                        </td>
                        <td>{{ project.views }}</td>
                        <td>{{ project.comments }}</td>
                        <td>{{ project.last_modified }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .projects-table {
        overflow: auto;
        table {
            border-collapse: separate;
            border-spacing: 0px;
            width: 100%;
            background: #fff;
            overflow-x: auto;
            white-space: nowrap;
            thead {
                .image-column {
                    width: 178px;
                }
                .title-column {
                    padding-left: 2.5rem !important;
                }
                th {
                    height: 64px;
                    background: rgba(11,172,219,.02);
                    font-family: "Poppins-Medium";
                    font-size: 17px;
                    padding: 0 20px;
                }
            }
            tbody {
                .table-row {
                    cursor: pointer;
                    &:hover {
                        background: rgba(11,172,219,.02);
                    }
                }
                td {
                    transition: 0.3s;
                    padding: 20px;
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'ProjectsTable',
    props: ['show', 'projects'],
    methods: {
        redirectToVideos(id) {
            this.$router.push({
                path: `/projects/${id}`,
            });
        }
    }
}
</script>