<template>
    <div class="success-container d-flex">
        <div class="icon">
            <BaseIcons icon='successCheck' />
        </div>
        <div class="flex-column text-container pl-3" v-if='text || subText'>
            <div class="text" v-if='text'>{{ text }}</div>
            <div class="sub-text" v-if='subText'>{{ subText }}</div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .success-container {
        background: #F4FDF9;
        padding: 10px;
        border-radius: 50px;
        .icon {
            width: 58.75px;
            position: relative;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        .text-container {
            color: #0AD688;
        }
    }
</style>

<script>
export default {
    name: 'SuccessCheckWithMessage',
    props: ['messsage', 'text', 'subText'],
}
</script>