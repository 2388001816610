<template>
    <div class="profile-contact">
        <div class="outer-header mb-4">CONTACT ADDRESS</div>
        <div class="profile-contact-inputs">
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="street_address">Street Address</label>
                    <BaseInput
                        v-if='userData && userData.settings'
                        :inputValue='userData.settings.street_address'
                        inputId='street_address'
                        placeholder='Enter Street Address'
                        @value='updateInput("street_address", $event)'
                    />
                </div>
                <div class="flex-column form-input">
                    <label class="base-label" for="apartment_suite">Apartment, Suite</label>
                    <BaseInput
                        v-if='userData && userData.settings'
                        :inputValue='userData.settings.apartment_suite'
                        placeholder='Enter Apartment, Suite'
                        inputId='apartment_suite'
                        @value='updateInput("apartment_suite", $event)'
                    />
                </div>
            </div>
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="city">City</label>
                    <BaseInput
                        v-if='userData && userData.settings'
                        :inputValue='userData.settings.city'
                        inputId='city'
                        placeholder='Enter City'
                        @value='updateInput("city", $event)'
                    />
                </div>
                <div class="flex-column form-input">
                    <label class="base-label" for="state">State/Province/Region</label>
                    <custom-selector
                        v-if='userData'
                        :list='statesList'
                        :selected='userData.settings.state'
                        listId='name'
                        listLabel='name'
                        @option='selectedState'
                    />
                </div>
            </div>
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="country">Country</label>
                    <custom-selector
                        v-if='userData'
                        :list='countries'
                        :selected='userData.settings.country'
                        listId='countryName'
                        listLabel='countryName'
                        @option='selectedCountry'
                    />
                </div>
                <div class="flex-column form-input">
                    <label class="base-label" for="zip_code">Zip Code</label>
                    <BaseInput
                        v-if='userData && userData.settings'
                        :inputValue='userData.settings.zip_code'
                        placeholder='Enter Zip Code'
                        inputId='zip_code'
                        @value='updateInput("zip_code", $event)'
                    />
                </div>
            </div>
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="phone">Phone Number</label>
                    <vue-tel-input
                        @input="updateInput('phone', userData.phone, true)"
                        v-if='userData'
                        v-model="userData.phone"
                        :dynamic-placeholder="true"
                        :valid-characters-only="true"
                        :default-country="userData.settings.country"
                    />
                </div>
                <div class="flex-column form-input">
                    <label class="base-label" for="company">Company</label>
                    <BaseInput
                        v-if='userData && userData.settings'
                        :inputValue='userData.settings.company'
                        placeholder='Enter Your Company'
                        inputId='company'
                        @value='updateInput("company", $event)'
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .profile-contact {
        .outer-header {
            font-weight: 700;
            font-size: 13px;
        }
        .profile-contact-inputs {
            .form-group {
                .form-input {
                    width: 45%;
                    position: relative;
                    .email-input {
                        .change-email {
                            display: none;
                            position: absolute;
                            right: 10px;
                            top: 5px;
                            margin: auto;
                            cursor: pointer;
                            display: none;
                            color: #26b2ee;
                        }
                        &:hover {
                            .change-email {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 960px) {
        .profile-contact {
            .profile-contact-inputs {
                .form-group {
                    flex-direction: column;
                    .form-input {
                        width: 100%;
                    }
                    .login-details {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
</style>

<script>
import CustomSelector from '../../Common/CustomSelector';
import countries from '../../../assets/js/countries';

export default {
    name: 'ProfileContact',
    props: ['user'],
    data: () => ({
        userData: null,
        showCountryDropdown: false,
        statesList: [],
        countries,
    }),
    components: {
        CustomSelector,
    },
    mounted() {
        this.getUserDetails();
    },
    methods: {
        getUserDetails() {
            this.userData = this.$props.user;
            const settings = this.userData.settings;
            if (settings) {
                const country = settings.country;
                const countryData = this.countries.filter(c => c.countryShortCode === country);
                if (countryData && countryData.length) {
                    this.statesList = countryData[0].regions;
                }
            }
        },
        updateInput(column, value, base = false) {
            const userData = this.userData;
            if (userData) {
                if (!base) {
                    userData.settings[column] = value;
                } else {
                    userData[column] = value;
                }
            }
            this.$emit('user', userData);
            this.userData = userData;
        },
        selectedState(state) {
            this.updateInput('state', state.name);
        },
        selectedCountry(country) {
            this.statesList = country.regions;
            this.updateInput('country', country.countryName);
            this.updateInput('state', '');
        }
    }
}
</script>