<template>
    <transition name="fade">
        <div class="date-filter" v-if='show'>
            <div class="flex-column controls pt-2 pb-2">
                <div class="selected-option d-flex pb-2">
                    <div class="selected-label position-relative">
                        <span class="label-text">
                            {{ selected.label }}
                        </span>
                        <div @mouseleave="showSelect = false">
                            <select-dropdown
                                :options='selectOptions'
                                :show='showSelect'
                                @selected='selectOption'
                                borderRadius='0px'
                                border='1px solid #ced4da'
                                width='200px'
                                top='40px'
                                left='0'
                            />
                        </div>
                    </div>
                    <div @click="showSelect = !showSelect">
                        <BaseIcons icon='downArrow' class="down-icon" />
                    </div>
                </div>

                <div class="custom-attributes d-flex justify-content-between w-100" v-if='ifNumberType()'>
                    <div class="custom-value w-50">
                        <BaseInput
                            type='number'
                            borderRadius='0px'
                            class='pr-3 w-100'
                            :inputValue='countVal'
                            @value='setValue'
                        />
                    </div>
                    <div class="value-type d-flex w-50 justify-content-between" @mouseleave="showComparisonSelect = false">
                        <div class="label position-relative">
                            <span class="comparison-text">
                                {{ selectedComparison }}
                            </span>
                            <select-dropdown
                                :options='comparisonTypes'
                                :show='showComparisonSelect'
                                borderRadius='0px'
                                border='1px solid #ced4da'
                                width='117.617px'
                                top='40px'
                                left='-11px'
                                @selected='selectComparison'
                            />
                        </div>
                        <div @click="showComparisonSelect = !showComparisonSelect">
                            <BaseIcons icon='downArrow' class='down-icon' />
                        </div>
                    </div>
                </div>
                <div class="date-picker" v-else>
                    <datepicker
                        :value="selectedDate"
                        placeholder='Select Date'
                        class="date-picker-input"
                        @selected='selectedDate = $event'
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less">
    .date-filter {
        .controls {
            .selected-option {
                cursor: pointer;
                .selected-label {
                    border: 1px solid #e2e5ed;
                    min-width: 200px;
                    max-width: max-content;
                    padding: 0 10px;
                    height: 39px;
                    .label-text {
                        position: relative;
                        top: 5px;
                    }
                }
                .down-icon {
                    position: relative;
                    top: 5px;
                    left: 15px;
                    cursor: pointer;
                }
            }
            .custom-attributes {
                .value-type {
                    border: 1px solid #e2e5ed;
                    padding: 0 10px;
                    height: 39px;
                    .down-icon {
                        position: relative;
                        top: 5px;
                        cursor: pointer;
                    }
                    .label {
                        .comparison-text {
                            position: relative;
                            top: 5px;
                        }
                    }
                }
            }
            .date-picker {
                .date-picker-input {
                    width: 100%;
                    input {
                        width: 117.667px;
                        height: 39px;
                        padding: 10px;
                        width: 100%;
                        border: 1px solid #e2e5ed;
                        color: #21455E;
                    }
                }
            }
        }
    }
</style>

<script>
import SelectDropdown from '../SelectDropdown';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';

export default {
    name: 'DateFilter',
    props: ['show', 'selectOptions'],
    components: {
        SelectDropdown,
        Datepicker,
    },
    data: () => ({
        showSelect: false,
        selected: {},
        countVal: 1,
        comparisonTypes: [
            { id: 'days', label: 'days' },
            { id: 'weeks', label: 'weeks' },
            { id: 'months', label: 'months' },
            { id: 'years', label: 'years' },
        ],
        showComparisonSelect: false,
        selectedComparison: 'days',
        selectedDate: moment().format('YYYY-MM-DD'),
    }),
    mounted() {
        this.selectOption(this.$props.selectOptions[0]);
    },
    methods: {
        selectOption(option) {
            this.selected = option;
            this.showSelect = false;
        },
        setValue(count) {
            this.countVal = count;
            this.$emit('count', count);
        },
        selectComparison(option) {
            this.selectedComparison = option.label;
            this.showComparisonSelect = false;
        },
        sendBackData() {
            if (!this.$props.show) return false;
            const { selected, countVal, selectedComparison } = this;
            const compareObject = {
                type: selected.id,
                date: '',
                till_date: '',
            }
            switch (selected.id) {
                case 'created_in_last':
                case 'updated_in_last':
                    compareObject.date = moment().subtract(countVal, selectedComparison).format('YYYY-MM-DD');
                    compareObject.till_date = moment().format('YYYY-MM-DD');
                break;
                case 'created_before':
                case 'updated_before':
                case 'created_after':
                case 'updated_after':
                    compareObject.date = moment(this.selectedDate).format('YYYY-MM-DD');
                break;
            }
            this.$emit('comparisonObject', compareObject);
        },
        ifNumberType() {
            const selected = this.selected.id;
            return selected === 'created_in_last' || selected === 'updated_in_last';
        }
    },
    watch: {
        selected() {
            this.sendBackData();
        },
        countVal() {
            this.sendBackData();
        },
        selectedComparison() {
            this.sendBackData();
        },
        selectedDate() {
            this.sendBackData();
        },
        show() {
            this.sendBackData();
        }
    }
}
</script>