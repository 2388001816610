<template>
    <div class="features-listing">
        <feature-tile
            v-for='(item, index) in listItems'
            :key='index'
            :title='item.name'
            :icon='item.icon'
            class="mt-3 mb-3 pl-5"
        />
        <div class="text-center">
            <div class="features-divider pt-5"></div>
            <div class="d-flex mt-2 align-items-center justify-content-center expander helvetica-medium base-links cursor-pointer" @click="toggle">
                See {{ expanded ? "less" : "all" }} features of {{ `${name} Plan` || 'Plan' }}
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .features-listing {
        .features-divider {
            background: #F5F8FA;
        }
        .expander {
            height: 40px;
        }
    }
</style>

<script>
import FeatureTile from '../Common/Widgets/FeatureTile';

export default {
    name: 'FeaturesListing',
    props: ['items', 'name'],
    components: {
        FeatureTile,
    },
    data: () => ({
        listItems: [],
        expanded: false,
    }),
    mounted() {
        this.splitData();
    },
    methods: {
        splitData() {
            const items = this.$props.items;
            if (items) {
                if (!this.$props.skipSplit) {
                    this.listItems = items.slice(0, 5);
                } else {
                    this.listItems = items;
                }
            }
        },
        toggle() {
            if (!this.expanded) {
                this.listItems = this.$props.items;
                this.expanded = true;
            } else {
                this.splitData();
                this.expanded = false;
            }
        }
    }
}
</script>