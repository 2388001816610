<template>
  <transition name="fade">
       <div class="Error-intergration-embed-modal" v-if="show">
            <div class="Error-intergration-embed-content">
                <div class="Error-intergration-embed-container">
                    <div class="Error-intergration-embed-head d-flex justify-content-center p-3">
                        <div></div>
                        <div class="Error-intergration-embed-head-title helvetica-medium">INTERGRATIONS & MAPPING</div>
                         </div> 
                          <div class="intergration-error-body">
                            <span class="intergration-error-body-text">Add a step with lead genration included</span>
                           <div class="intergration-error-body-button" @click="$emit('close')">
                             <BaseButton
                                title='OK'
                                background='#862D83'
                                color='#fff'
                                height='36px'
                                width='147px'
                              :loading='loading'
                                />
                        </div>
                        </div>
                     </div>
                 </div>
             </div>            
  </transition>    
</template>

<script>
export default {
props:['show'],
data () {
return{

}
}
}
</script>

<style lang="less" scoped>

    .Error-intergration-embed-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        .divide {
            border-bottom: 1px solid rgba(187, 187, 187, 0.616);
        }
        .highlighted {
            background: rgba(0, 0, 0, 0.274) !important;
        }
        .Error-intergration-embed-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .Error-intergration-embed-container {
                background: #fff;
                width: 637px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                height: max-content;
                position: relative;
                font-size: 14px;
                .Error-intergration-embed-head {
                    border-bottom: 1px solid #0DABD8;
                    .Error-intergration-embed-head-title {
                        font-size: 15px;
                        position: relative;
                        top: 5px;
                    }
                }
                .intergration-error-body{
                  height: 100px;
                  padding: 100px;
                  position: relative;
                }
                 .intergration-error-body-text{
                   position: absolute;
                   bottom: 117px;
                   left: 178px;
                   font-size: 17px;
                 }
                 .intergration-error-body-button{
                    position: relative;
                    top: 3px;
                    left: 160px;
                    background: rgb(134, 45, 131);
                    color: rgb(255, 255, 255);
                    width: 147px;
                    height: 36px;
                    border-radius: 5px;
                 }
            }
         }
      }
</style>