<template>
    <div class="upload-video-container">
        <BaseIcons
            class="close-icon mobile-element"
            icon="cross"
            @clicked="close"
        />
        <div class="upload-video-content" :class="containerClass">
            <div
                class="upload-controls-section upload-section"
                v-if="!previewScreen"
            >
                <library-header
                    class="pb-5"
                    @showPreview="previewScreen = true"
                />
                <!-- {{video_id}} -->
                <upload-library
                    @options="videoOptions = $event"
                    @selectedVideo="video = $event"
                    @overlay="overlay = $event"
                    :activeClass="activeClass"
                    :videoId="video ? video.video_id : null"
                    :videoCampaign="video ? video.campaign_video : null"
                    @videos="videos = $event"
                    @dbVideos="getVideos"
                    :videos="videos"
                />
            </div>
            <div
                class="upload-preview-section upload-section live-preview-section"
                v-if="!isMobile || (isMobile && previewScreen)"
            >
                <div class="preview-title poppins-medium text-center">
                    LIVE PREVIEW
                </div>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <BaseButton
                        title="Back to Campaign Editor"
                        background="#862D83"
                        hoverBg="#862D83"
                        color="#fff"
                        height="36px"
                        width="max-content"
                        fontFamily="Poppins-Medium"
                        class="justify-self-end mt-4 mobile-element"
                        @clicked="previewScreen = false"
                    />
                </div>
                <live-preview
                    :options="videoOptions"
                    @isMobile="containerClass = $event ? 'mobile-view' : ''"
                    :videoUrl="video ? video.path : null"
                    :overlay="overlay"
                />
            </div>
        </div>
        <div class="lower-controls d-flex" v-if="!previewScreen">
            <BaseButton
                title="Back"
                background="#862d8380"
                hoverBg="#862D83"
                color="#fff"
                height="36px"
                width="147px"
                fontFamily="Poppins-Medium"
                class="align-self-end back-btn"
                :loading="loading"
                @clicked="goBack"
            />
            <BaseButton
                title="Continue"
                background="#862D83"
                hoverBg="#862D83"
                color="#fff"
                height="36px"
                width="147px"
                fontFamily="Poppins-Medium"
                class="align-self-end continue-btn ml-4"
                @clicked="saveData"
                :loading="loading"
            />
        </div>
        <div v-else class="mobile-text text-center poppins-medium mt-5">
            Only Mobile Preview Available
        </div>
        <BaseLoader :showLoader="loading" :centered="true" />
    </div>
</template>

<style lang="less" scoped>
.upload-video-container {
    width: 100%;
    background: #f5f8fa;
    padding-bottom: 200px;
    .close-icon {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        position: relative;
        top: 15px;
        right: 15px;
    }
    .mobile-view {
        .upload-section {
            width: 90% !important;
        }
    }
    .upload-video-content {
        width: 100%;
        height: max-content;
        display: flex;
        justify-content: space-between;
        padding: 15px 70px;
        .upload-section {
            width: 45%;
        }
        .upload-preview-section {
            position: relative;
            top: 100px;
            .preview-title {
                color: #6e8186;
                font-size: 25px;
            }
        }
    }
    .lower-controls {
        display: flex;
        width: max-content;
        justify-content: flex-end;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
    .mobile-text {
        font-size: 17px;
    }
}

@media (max-width: 1400px) {
    .upload-video-container {
        .upload-video-content {
            .upload-section {
                position: relative;
                left: 0;
                right: 0;
                margin: auto;
                width: 70%;
            }
            .upload-preview-section {
                position: inherit;
            }
        }
    }
}

@media (max-width: 960px) {
    .upload-video-container {
        .upload-video-content {
            flex-direction: column;
            padding: 5px;
            .upload-section {
                width: 100%;
            }
        }
        .lower-controls {
            position: relative !important;
            justify-content: center;
            top: 20px;
            right: inherit;
            width: 100%;
        }
    }
}
</style>

<script>
import LibraryHeader from "../../components/Videos/Upload/LibraryHeader";
import UploadLibrary from "../../components/Videos/Upload/UploadLibrary";
import LivePreview from "../../components/Videos/LivePreview";

export default {
    name: "Upload",
    components: {
        LibraryHeader,
        UploadLibrary,
        LivePreview,
    },
    data: () => ({
        videoOptions: null,
        isMobile: false,
        previewScreen: false,
        containerClass: "",
        video: null,
        videos: [],
        overlay: "",
        loading: false,
        activeClass: null,
        video_id: null,
        notFound: false,
        message: "",
    }),
    mounted() {
        this.handleScreenChange();
        this.getVideos();
        window.addEventListener("resize", this.handleScreenChange);
    },
    methods: {
        close() {},
        getVideos() {
            this.loading = true;
            const campaignId = this.$route.params.id;
            this.axios
                .get(`videos?campaign_id=${campaignId}`)
                .then((response) => {
                    this.videos = response.data.videos;
                    this.video = this.videos[this.videos.length - 1];
                    this.activeClass = 'active';
                    // this.video = this.videos[0];
                    // this.activeClass = "active";
                    this.loading = false;
                })
                .catch((error) => {
                    const errorCode = error.response
                        ? error.response.status
                        : 500;
                    if (errorCode === 404) {
                        this.$router.push("/projects");
                    }
                    this.notFound = true;
                    this.loading = false;
                });
        },
        handleScreenChange() {
            const width = window.innerWidth;
            if (
                width <= 1400 ||
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return (this.isMobile = true);
            }
            this.previewScreen = false;
            return (this.isMobile = false);
        },
        saveData() {
            const campaign_id = this.$route.params.id;
            const { videoOptions, video, overlay } = this;
            const { switches } = videoOptions;
            const { darkenVideo, fitVideo } = switches;
            const current_step = this.$route.params.current_step;
            const hashId = this.$route.params.id;

            const data = {
                video,
                overlay,
                darkenVideo,
                fitVideo,
                current_step,
                hashId,
                campaign_id,
            }
            
            if (data.video) {
                // Send Request to save.
                const url = "/storeCampaignVideo";
                this.loading = true;
                this.axios.post(url, data).then(response => {
                    this.loading = false;
                    if(response.data.success) {
                        const videoId = response.data.video_id;
                        console.log(videoId);
                        const campaignId = this.$route.params.id;
                        const redirectPath = `/campaigns/${campaignId}/videos/${videoId}`;
                        this.$router.push(redirectPath);
                    }
                }).catch(error => {
                    this.loading = false;
                    console.log(error);
                })  
            }
        },
        goBack() {
            const campaignId = this.$route.params.id;
            const current_step = this.$route.params.current_step;
            const path = `/campaigns/${campaignId}/${current_step}/create`;
            this.$router.push({ path });
        },
    },
};
</script>
