<template>
    <div class="campaign-list-table position-relative">
        <table>
            <thead>
                <tr>
                    <th class="image-column"></th>
                    <th class="title-column">TITLE</th>
                    <th>LAST MODIFIED</th>
                    <th>TOOLS</th>
                </tr>
            </thead>
            <tbody class="position-relative">
                <tr
                    v-for="campaign in campaigns"
                    :key='campaign.id'
                >
                    <td class="image-container">
                        <img :src='campaign.thumbnail' />
                    </td>
                    <td>
                        <div class="campaign-info d-flex">
                            <div class="campaign-text-details pl-4 flex-column">
                                <div class="campaign-title poppins-semi-bold pb-4">{{ campaign.title }}</div>
                                <div class="campaign-steps">{{ campaign.total_steps }} steps</div>
                                <div class="campaign-impressions">0 impressions</div>
                                <div class="campaign-views">0 unique view-thrus</div>
                                <div class="campaign-clicks">0 unique clicks</div>
                                <div class="campaign-leads">0 leads</div>
                            </div>
                        </div>
                    </td>
                    <td>
                        {{ campaign.last_modified }}
                    </td>
                    <td>
                        <BaseButton
                            title='Share & embed'
                            border='2px solid #07A2D0'
                            color='#07A2D0'
                            fontFamily='Poppins-Medium'
                            fontSize='12px'
                            width='136px'
                            class='mb-2'
                            hoverBg='#07A2D0'
                            hoverColor='#fff'
                            @clicked='$emit("shareAndEmbed", campaign)'
                        />
                        <BaseButton
                            title='Edit campaign'
                            border='2px solid #1E3941'
                            color='#1E3941'
                            fontFamily='Poppins-Medium'
                            fontSize='12px'
                            width='136px'
                            class='mb-2'
                            hoverBg='#1E3941'
                            hoverColor='#fff'
                            @clicked='$emit("edit",campaign)' 
                        />  
                        <BaseButton
                            title='View performance'
                            border='2px solid #A3089D'
                            color='#A3089D'
                            fontFamily='Poppins-Medium'
                            fontSize='12px'
                            width='136px'
                            class='mb-2'
                            hoverBg='#A3089D'
                            hoverColor='#fff'
                        />
                        <BaseButton
                            title='Delete campaign'
                            border='2px solid #C1420A'
                            color='#C1420A'
                            fontFamily='Poppins-Medium'
                            fontSize='12px'
                            width='136px'
                            class='mb-2'
                            @clicked='$emit("delete", campaign)'
                            hoverBg='#C1420A'
                            hoverColor='#fff'
                        />
                    </td>
                </tr>
                <div v-if='!loading && !campaigns || !loading && !campaigns.length' class="p-4">
                    No campaigns found.
                </div>
                <BaseLoader :showLoader='loading' :centered='true' class="p-5" />
            </tbody>
        </table>
    </div>
</template>

<style lang="less" scoped>
    .campaign-list-table {
        overflow: auto;
        table {
            border-collapse: separate;
            border-spacing: 0px;
            width: 100%;
            background: #fff;
            overflow-x: auto;
            white-space: nowrap;
            thead {
                .image-column {
                    width: 178px;
                }
                .title-column {
                    padding-left: 2.5rem !important;
                }
                th {
                    height: 64px;
                    background: rgba(11,172,219,.02);
                    font-family: "Poppins-Medium";
                    font-size: 17px;
                    padding: 0 20px;
                }
            }
            tbody {
                .image-container {
                    width: 178px;
                    img {
                        width: 178px;
                        height: 178px;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }
                td {
                    padding: 20px;
                    .campaign-info {
                        .campaign-text-details {
                            height: 100%;
                            .campaign-title {
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<script>

export default {
    name: 'CampaignTable',
    props: ['loading', 'campaigns'],
}
</script>