<template>
    <div class="tiles-container position-relative">
        <div class="outer position-relative">
            <div class="tile d-flex">
                <!-- Image -->
                <!-- div class="image-count flex-column w-50 pl-1 position-relative">
                    
                    <div class="count" v-if='count && count > 0'>
                        <div class="number">+{{ count }}</div>
                    </div>
                </div -->
            </div>
            <div class="tile-information flex-column">
                <div class="title helvetica-medium">{{ title }}</div>
                <div class="count">{{ count ? `${count} Campaigns` : 'No Campaigns' }}</div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .tiles-container {
        height: 100%;
        cursor: pointer;
        .outer {
            height: 100%;
            padding: 10px;
            .tile {
                background: #21455E;
                width: 100%;
                height: 10vw;
                padding: 10px;
                .primary-image {
                    height: 100%;
                }
                .image-count {
                    img {
                        width: 100%;
                    }
                    .count {
                        color: #fff;
                        height: 50%;
                        position: relative;
                        .number {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            width: max-content;
                            height: inherit;
                            margin: auto;
                            font-size: 1.2em;
                        }
                    }
                }
            }
            .tile-information {
                .count {
                    color: #B5C0CA;
                }
            }
        }
    }
    @media (max-width: 960px) {
        .tiles-container {
            .outer {
                .tile {
                    height: 100%;
                }
            }
        }
    }

</style>

<script>
export default {
    name: 'Tile',
    props: ['count', 'title'],
    methods: {
    }
}
</script>