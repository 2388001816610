<template>
    <div class="settings-accounts">
        <div class="settings-accounts-header d-flex justify-content-between w-100">
            <div class="settings-header-title">
                Account Information
            </div>
            <div class="settings-header-control">
                <BaseButton
                    title='Save'
                    fontSize='16px'
                    background='#0dabd8'
                    borderRadius='20px'
                    color='#fff'
                    width='115px'
                    height='39px'
                    @clicked="save"
                    :loading='loading'
                />
            </div>
        </div>
        <BaseLoader :showLoader='loading' :centered='true' />
        <div class="settings-accounts-body mt-5" v-if='user && user.id'>
            <profile-picture
                :picture='user.photo'
                @image='user.photo = $event'
            />
            <profile-personal
                :user='user'
                @user='user = $event'
                class="mt-5"
                :errors='personalErrors'
            />
            <profile-contact
                class="mt-5"
                :user='user'
                @user='user = $event'
            />
            <profile-timezone
                class="mt-5"
                :user='user'
                @user='user = $event'
            />
            <profile-password
                class="mt-5"
                :user='user'
                @user='user = $event'
                :errors='passwordErrors'
            />
        </div>
        <messages-box
            v-if='message'
            :messages='message'
            @closeMessages='message = null'
            background='#0DABD8'
        />
    </div>
</template>

<script>
import ProfilePicture from '../Modules/ProfilePicture';
import ProfilePersonal from '../Modules/ProfilePersonal';
import ProfileContact from '../Modules/ProfileContact';
import ProfileTimezone from '../Modules/ProfileTimezone';
import ProfilePassword from '../Modules/ProfilePassword';
import MessagesBox from '../../Common/MessagesBox';

import validator from 'validator';

export default {
    name: 'AccountSettings',
    components: {
        ProfilePicture,
        ProfilePersonal,
        ProfileContact,
        ProfileTimezone,
        ProfilePassword,
        MessagesBox,
    },
    data: () => ({
        user: {
            id: '',
            first_name: '',
            last_name: '',
            photo: null,
            email: '',
            phone: '',
            password: '',
            old_password: '',
            confirm_password: '',
            last_login: 'Mar 18, 2021 at 1:08 AM',
            created_at: 'Mar 18, 2021 at 1:08 AM',
            settings: {
                street_address: '',
                apartment_suite: '',
                city: '',
                state: '',
                country: '',
                zip_code: '',
                company: '',
                timezone: '',
            }
        },
        passwordErrors: null,
        personalErrors: null,
        liveValidation: false,
        message: null,
        loading: false,
    }),
    mounted() {
        this.getUserDetails();
    },
    methods: {
        getUserDetails() {
            this.loading = true;
            this.axios.get('user-details')
                .then(response => {
                    this.loading = false;
                    if (!response.data.success) {
                        return this.message = 'Failed to get user data';
                    }
                    this.user = response.data.user;
                }).catch(error => {
                    console.log(error);
                    this.loading = false;
                    return this.message = 'Failed to get user data';
                });
        },
        save() {
            this.validate();

            if (!this.passwordErrors && !this.personalErrors) {
                this.loading = true;
                this.axios.post('user-details', this.user)
                    .then(response => {
                        this.loading = false;
                        if (response.data.password_failed) {
                            this.message = 'Failed to update password';
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.loading = false;
                        this.message = 'Failed to update user data';
                    })

            } else {
                this.message = 'Password cannot be updated, please check form again.';
            }
        },
        validate() {
            this.validatePassword();
            this.validatePersonal();
            this.liveValidation = true;
        },
        validatePassword() {
            const { password, confirm_password, old_password } = this.user;
            this.passwordErrors = null;
            let errors = {}

            if (password) {
                if (validator.isEmpty(password)) {
                    errors.password = 'Please enter the password';
                } else if (!this.strongPassword(password)) {
                    errors.password = 'Please enter a strong password';
                }
                if (validator.isEmpty(confirm_password)) {
                    errors.confirm_password = 'Please confirm the password';
                }
                if (validator.isEmpty(old_password)) {
                    errors.old_password = 'Please confirm your old password';
                }
                if (!validator.equals(password, confirm_password)) {
                    errors.confirm_password = 'Passwords not matching';
                }
            }
            errors = Object.keys(errors).length ? errors : null;
            this.passwordErrors = errors;
        },
        strongPassword(password) {
          /* eslint-disable no-useless-escape */
          let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
          return re.test(String(password));
        },
        validatePersonal() {
            const { first_name, last_name } = this.user;
            let errors = {};
            if (validator.isEmpty(first_name)) {
                errors.first_name = 'Please enter your first name';
            }
            if (validator.isEmpty(last_name)) {
                errors.last_name = 'Please enter your last name';
            }
            errors = Object.keys(errors).length ? errors : null;
            this.personalErrors = errors;
        },
    },
    watch: {
        user: {
            handler() {
                if (this.liveValidation) {
                    this.validate();
                }
            },
            deep: true,
        }
    }
}
</script>

<style lang="less">
    .settings-accounts {
        .settings-accounts-header {
            .settings-header-title {
                font-size: 20px;
                font-weight: 700;
            }
        }
        .settings-accounts-body {
            background: #fff;
            padding: 30px;
            border-radius: 12px;
        }
    }
</style>