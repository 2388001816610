<template>
    <div class="settings-sidebar">
        <div
            class="cursor-pointer d-flex"
            v-for='option in sideBarOptions'
            :key='option.id'
            @click='$emit("section", option.section)'
        >   
            <div class="d-flex sidebar-btn mt-4" :class="{'active-section': option.section === section}">
                <div class="sidebar-icon pr-3 pl-3 d-flex align-items-center">
                    <BaseIcons :icon='option.icon' />
                </div>
                <div class="sidebar-text pl-3 pr-3 d-flex align-self-center">
                    {{ option.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
    .settings-sidebar {
        background-color: #fff;
        padding: 0 40px;
        width: max-content;
        height: 100%;
        .sidebar-btn {
            outline: 0;
            display: flex;
            height: 54.4px;
            border: 1px solid #eef9fc;
            border-radius: 4px;
            position: relative;
            width: 100%;
            .sidebar-icon {
                border-right: 1px ridge #eef9fc;
                height: inherit;
            }
            .sidebar-text {
                font-weight: 501;
                font-size: 15px;
            }
            &:hover {
                background-color: #ebf9fa;
                border-color: #87d4ea;
                .sidebar-icon {
                    border-right: 1px ridge #c3ebf7;
                }
            }
        }
        .active-section {
            background-color: #ebf9fa;
            border-color: #87d4ea;
            .sidebar-icon {
                border-right: 1px ridge #c3ebf7;
            }
        }
    }
</style>

<script>
import { SETTINGS_SIDEBAR } from '../../assets/js/constants';

export default {
    name: 'SideBar',
    props: ['section'],
    data: () => ({
        sideBarOptions: SETTINGS_SIDEBAR
    }),
}
</script>