<template>
    <div class="integration-listing">
        <div class="integration-modal-body d-flex">
            <div class="integration-menu pl-5 pb-5 pt-5 mt-5">
                <div class="navbar-item mb-5" @click="$emit('categories', 'all')">
                    All Integrations
                </div>
                <div
                    class="navbar-item"
                    v-for="item in navbarItems"
                    :key='item.id'
                    @click='$emit("categories", item.id)'
                >
                    {{ item.label }}
                </div>
            </div>
            <div class="integrations-list pt-5">
                <div class="integrations-list-head">
                    <search-bar
                        :expanded='true'
                        borderColor='#00ACDC'
                        icon='magnifyBlue'
                        class="pl-2 pr-2 pb-2"
                        placeholder='Search Integrations'
                        @query='$emit("query", $event)'
                    />
                </div>
                <div class="integrations-list-body">
                    <div
                        class="list-item d-flex justify-content-between pl-5"
                        v-for="item in integrationsList"
                        :key='item.icon'
                        @click="$emit('selected', item)"
                        :class="{'selected': selected && selected.icon === item.icon}"
                    >
                        <div class="d-flex justify-content-between">
                            <BaseIcons
                                :icon='item.icon'
                                class="integration-icon"
                                imageWidth='100%'
                                imageHeight='100%'
                            />
                            <div class="integration-name pl-5 d-flex align-self-center">{{ item.name }}</div>
                        </div>  
                        <div class="integration-description d-flex align-self-center">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
    .integration-listing {
        
    }
</style>

<script>
import SearchBar from '../../../Common/SearchBar';
export default {
    name: 'IntegrationListing',
    components: {
        SearchBar,
    },
    props: ['integrationsList', 'navbarItems', 'selected'],
}
</script>