<template>
    <div class="live-preview">
        <div class="preview-section mt-5 smooth-shadow" :class="containerClass">
            <div class="preview-content">
                <div class="controls-strip p-3 d-flex">
                    <div class="top-circles default-red"></div>
                    <div class="top-circles default-yellow ml-2 mr-2"></div>
                    <div class="top-circles default-green"></div>
                </div>
                <div class="preview-body d-flex">
                    <div class="dark-video" v-if="darkVideo"></div>
                    <div class="video-container">
                        <video
                            :src="videoUrl"
                            autoplay
                            loop
                            :style="{ objectFit: mode }"
                        />
                        <div
                            class="overlay-text"
                            v-if="overlay"
                            :style="{
                                fontSize: overlay.fontSize,
                                color: overlay.color,
                            }"
                        >
                            {{ overlay.text }}
                        </div>
                        <div
                            class="overlay-text"
                            v-else-if="overlayOptions"
                            :style="{
                                fontSize: overlayOptions.size,
                                color: overlayOptions.color,
                            }"
                        >
                            {{ overlayOptions.text }}
                        </div>
                    </div>
                    <div
                        class="video-reply-controls flex-column align-self-center pb-4"
                    >
                        <div
                            class="description-text poppins"
                            v-if="
                                interactionType === 'viewerResponse' ||
                                    interactionType === 'viewers_response'
                            "
                        >
                            Choose how you respond…
                        </div>
                        <div
                            class="d-flex justify-content-center"
                            v-if="
                                interactionType === 'viewerResponse' ||
                                    interactionType === 'viewers_response'
                            "
                        >
                            <square-button
                                v-if="toggles.video"
                                text="VIDEO"
                                icon="videoCamSmall"
                                class="square-btn"
                            />
                            <square-button
                                v-if="toggles.audio"
                                text="AUDIO"
                                icon="recordingMicSmall"
                                class="square-btn"
                            />
                            <square-button
                                v-if="toggles.text"
                                text="TEXT"
                                icon="textEditor"
                                class="square-btn"
                            />
                        </div>
                        <div
                            class="video-choose-optn"
                            v-if="
                                interactionType === 'multiChoice' ||
                                    interactionType === 'multi_choice'
                            "
                        >
                            <div
                                v-for="(option, index) in chooseOption"
                                :key="index"
                            >
                                <BaseButton
                                    :title="option"
                                    background="#862D83"
                                    hoverBg="#862D83"
                                    color="#fff"
                                    height="36px"
                                    width="max-content"
                                    fontFamily="Poppins-Medium"
                                    class="justify-self-end mt-4 optnBtn"
                                />
                            </div>
                        </div>
                        <div
                            class="cta-btn-div"
                            v-if="
                                interactionType === 'cta' ||
                                    interactionType === 'cta_button'
                            "
                        >
                            <button
                                class="justify-self-end mt-4 cta-btn btn d-flex"
                                :style="{ background: ctaButton.color }"
                                v-if="ctaButton.text"
                            >
                                <BaseIcons
                                    :icon="'loader-arrow1'"
                                    class="device-icon cta-icn"
                                />
                                {{ ctaButton.text }}
                            </button>
                        </div>
                        <div
                            class="appointment-booking-interaction"
                            v-if="
                                interactionType === 'appointments' ||
                                    interactionType ===
                                        'appointments_and_booking'
                            "
                        >
                            <div class="calendar-div">
                                <!-- <vue-calendly
                                    url="https://calendly.com/sapna-rani/test?month=2021-06"
                                    :height="600"
                                ></vue-calendly> -->
                                <!-- <div
                                    class="calendly-inline-widget"
                                    data-url="https://calendly.com/sapna-rani/test?month=2021-06"
                                    style="position: relative;min-width:320px;height:750px;"
                                    data-processed="true"
                                ></div> -->
                                <iframe
                                    :src="appointmentBookingUrl"
                                    width="100%"
                                    height="950"
                                    scrolling="no"
                                    frameborder="0"
                                ></iframe>
                            </div>
                        </div>
                        <div
                            class="lead-generation-form"
                            v-if="
                                interactionType === 'leadGeneration' ||
                                    interactionType === 'lead_generation'
                            "
                        >
                            <h4>{{ leadGeneration.headline }}</h4>
                            <div class="form-div">
                                <p class="mt-3">
                                    {{ leadGeneration.subHeadline }}
                                </p>
                                <BaseInput
                                    v-if="toggles.firstName"
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    class="lead-input mb-3"
                                    placeholder="Enter your first name"
                                    type="text"
                                />
                                <BaseInput
                                    v-if="toggles.lastName"
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    class="lead-input mb-3"
                                    placeholder="Enter your last name"
                                    type="text"
                                />
                                <BaseInput
                                    v-if="toggles.email"
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    class="lead-input mb-3"
                                    placeholder="Enter your email address"
                                    type="email"
                                />
                                <BaseInput
                                    v-if="toggles.phoneNo"
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    class="lead-input mb-3"
                                    placeholder="Enter your phone number"
                                    type="text"
                                />
                                <BaseInput
                                    v-if="toggles.cityCountry"
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    class="lead-input mb-3"
                                    placeholder="Enter your City or Country"
                                    type="text"
                                />
                                <BaseInput
                                    v-if="toggles.postalCode"
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    class="lead-input mb-3"
                                    placeholder="Enter your postal code"
                                    type="text"
                                />
                                <div v-if="leadGeneration.inputOptions">
                                    <div
                                        v-for="(option,
                                        index) in leadGeneration.inputOptions"
                                        :key="index"
                                    >
                                        <BaseInput
                                            border="1px solid #ccc"
                                            borderRadius="8px"
                                            class="lead-input mb-3"
                                            :placeholder="option"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="lead-genration-btn"
                                v-if="leadGeneration.submitBtnText"
                            >
                                <button
                                    class="justify-self-end mt-4 cta-btn btn d-flex"
                                    style="background: #862d83; color:#fff"
                                >
                                    <BaseIcons
                                        :icon="'loader-arrow1'"
                                        class="device-icon cta-icn"
                                    />
                                    {{ leadGeneration.submitBtnText }}
                                </button>
                            </div>
                        </div>
                        <div
                            class="card-details"
                            v-if="
                                interactionType === 'eCommerce' ||
                                    interactionType === 'ecommerce_card'
                            "
                        >
                            <h4>{{ cardDetails.headline }}</h4>
                            <div class="add-cart">
                                <p>{{ cardDetails.subHeadline }}</p>
                                <div
                                    class="product-div mb-4"
                                    v-for="(option,
                                    index) in cardDetails.cardOptions"
                                    :key="index"
                                >
                                    <div class="img-preview">
                                        <img
                                            v-if="option.image"
                                            :src="option.image"
                                        />
                                    </div>
                                    <h5>{{ option.product_name }}</h5>
                                    <h4 v-if="option.discounted_price">
                                        <span v-if="option.regular_price"
                                            >${{ option.regular_price }}</span
                                        >
                                        ${{ option.discounted_price }}
                                    </h4>
                                    <p
                                        class="coupon-code"
                                        v-if="option.coupon_code"
                                    >
                                        Discount Coupon:
                                        {{ option.coupon_code }}
                                    </p>
                                    <div
                                        class="add-cart-btn"
                                        v-if="option.btn_text"
                                    >
                                        <button
                                            class="justify-self-end mt-4 cart-btn btn d-flex"
                                            style="background: #862d83; color:#fff"
                                        >
                                            <BaseIcons
                                                :icon="'shopping-cart'"
                                                class="device-icon cart-icn"
                                            />
                                            {{ option.btn_text }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="payment-collection"
                            v-if="
                                interactionType === 'paymentCollectHand' ||
                                    interactionType === 'payment_collection'
                            "
                        >
                            <div class="radio-div d-flex">
                                <BaseRadio
                                    :label="'credit or debit card'"
                                    :selected="paymentCollection.accept_card"
                                    class="radio-optn"
                                />
                                <BaseRadio
                                    :label="'paypal'"
                                    :selected="paymentCollection.accept_paypal"
                                    style="margin-left:20px;"
                                    class="radio-optn"
                                />
                            </div>
                            <div class="card-payment-form">
                                <!-- <card-payment-form /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="devices-controls d-flex justify-content-center"
            v-if="screenControls"
        >
            <BaseIcons
                :icon="desktopStyle ? 'desktopPurple' : 'desktopDark'"
                class="device-icon"
                @clicked="desktopStyle = true"
            />
            <BaseIcons
                :icon="!desktopStyle ? 'mobilePurple' : 'mobileDark'"
                class="device-icon"
                @clicked="desktopStyle = false"
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
.live-preview {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    .preview-mobile {
        width: 50% !important;
        min-width: 311px;
        left: 0;
        right: 0;
        margin: auto;
        .preview-body {
            .video-container {
                width: 100% !important;
                min-width: 311px;
                border-bottom-right-radius: 12px;
                video {
                    border-radius: 12px;
                }
            }
            .dark-video {
                width: 100% !important;
                left: 0 !important;
            }
            .video-reply-controls {
                position: absolute !important;
                width: max-content !important;
                left: 0;
                right: 0;
                bottom: 25px;
                margin: auto;
                height: auto;
                max-height: 625px;
                overflow: auto;
                .description-text {
                    color: #fff;
                }
            }
        }
    }
    .preview-section {
        position: relative;
        height: 665px;
        border-radius: 12px;
        width: 100%;
        .controls-strip {
            width: 100%;
            height: 39.76px;
            background: #eeedee;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            .top-circles {
                width: 11.18px;
                height: 11.18px;
                border-radius: 50%;
            }
        }
        .preview-body {
            .dark-video {
                position: absolute;
                z-index: 19;
                background: #1e3941;
                left: 0;
                margin: auto;
                top: 0;
                bottom: 0;
                opacity: 40%;
                border-radius: 12px;
                width: 50%;
            }
            .video-container {
                position: relative;
                width: 50%;
                height: 625px;
                border-bottom-left-radius: 12px;
                transition: 0.3s;
                background: black;
                video {
                    width: 100%;
                    height: 100%;
                    border-bottom-left-radius: 12px;
                }
                .overlay-text {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    margin: auto;
                    overflow-wrap: break-word;
                    word-break: break-all;
                    z-index: 20;
                }
            }
            .video-reply-controls {
                width: 50%;
                justify-self: center;
                text-align: center;
                position: relative;
                z-index: 20;
                height: auto;
                max-height: 500px;
                overflow: auto;
                .description-text {
                    font-size: 17px;
                    margin-bottom: 20px;
                }
                .square-btn {
                    margin: 7px;
                }
                .video-choose-optn {
                    .optnBtn {
                        margin: 0px auto;
                        padding: 0px 12px;
                    }
                }
                .cta-btn-div {
                    position: relative;
                    .cta-btn {
                        margin: 0px auto;
                        padding: 12px 12px;
                        width: max-content;
                        color: #fff;
                        font-family: Poppins-Medium;
                        text-transform: capitalize;
                    }
                    .cta-icn {
                        padding-right: 12px;
                        border-right: 2px solid #fff;
                        margin-right: 15px;
                    }
                }
                .lead-generation-form {
                    h4 {
                        font-family: Poppins-Medium;
                        color: #862d83;
                        font-size: 26px;
                        font-weight: 600;
                        text-transform: capitalize;
                    }
                    .form-div {
                        width: 80%;
                        margin: 0px auto;
                        p {
                            font-family: Poppins;
                            font-size: 14px;
                            text-align: left;
                        }
                    }
                    .lead-genration-btn {
                        position: relative;
                        .cta-btn {
                            margin: 0px auto;
                            padding: 12px 12px;
                            width: max-content;
                            color: #fff;
                            font-family: Poppins-Medium;
                            text-transform: capitalize;
                        }
                        .cta-icn {
                            padding-right: 12px;
                            border-right: 2px solid #fff;
                            margin-right: 15px;
                        }
                    }
                }
                .card-details {
                    h4 {
                        font-family: Poppins-Medium;
                        color: #862d83;
                        font-size: 28px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                    .add-cart {
                        width: 85%;
                        margin: 0px auto;
                        p {
                            font-family: Poppins;
                            font-size: 14px;
                            text-align: left;
                        }
                        .product-div {
                            h4 {
                                font-family: Poppins-Medium;
                                font-size: 36px;
                                color: #068e06;
                                font-weight: 600;
                                span {
                                    color: #e60606;
                                    font-size: 28px;
                                    margin-right: 12px;
                                    text-decoration: line-through;
                                }
                            }
                            h5 {
                                font-family: Poppins-Medium;
                                color: #1e39419a;
                                font-size: 18px;
                                font-weight: 500;
                                text-transform: capitalize;
                                margin-top: 10px;
                            }
                            .coupon-code {
                                font-size: 17px;
                                text-align: center;
                                color: #1e39419a;
                            }
                            .add-cart-btn {
                                position: relative;
                                .cart-btn {
                                    margin: 0px auto;
                                    padding: 12px 12px;
                                    width: 80%;
                                    color: #fff;
                                    font-family: Poppins-Medium;
                                    text-transform: capitalize;
                                    font-weight: 600;
                                    font-size: 18px;
                                }
                                .cart-icn {
                                    padding-right: 12px;
                                    border-right: 2px solid #fff;
                                    margin-right: 30px;
                                }
                            }
                        }
                    }
                }
                .payment-collection {
                    padding-left: 25px;
                    .radio-div {
                        .radio-optn {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
    .devices-controls {
        padding-top: 20px;
        .device-icon {
            margin: 0 10px;
        }
    }
}
</style>

<!-- <script
    type="text/javascript"
    src="https://assets.calendly.com/assets/external/widget.js"
    async
></script> -->

<script>
import SquareButton from "../Common/SquareButton";
// import CardPaymentForm from "../Videos/Interactions/Sections/CardPaymentForm";

export default {
    name: "LivePreview",
    props: [
        "options",
        "videoUrl",
        "overlay",
        "video",
        "interactionsData",
        "section",
    ],
    data: () => ({
        desktopStyle: false,
        mode: "",
        darkVideo: false,
        isMobile: false,
        containerClass: "",
        screenControls: true,
        toggles: {
            video: true,
            audio: true,
            text: true,
            firstName: true,
            lastName: true,
            email: true,
            phoneNo: true,
            cityCountry: true,
            postalCode: true,
        },
        overlayOptions: {
            text: "",
            color: "",
            size: "",
        },
        chooseOption: "",
        redirectVideoUrl: "",
        ctaButton: {
            text: "",
            color: "",
            url: "",
        },
        leadGeneration: {
            headline: "",
            subHeadline: "",
            inputOptions: "",
            submitBtnText: "",
            submissionUrl: "",
        },
        cardDetails: {
            headline: "",
            subHeadline: "",
            cardOptions: "",
        },
        interactionType: "",
        paymentCollection: {
            accept_card: "",
            accept_paypal: "",
            currency: "",
            price: "",
        },
        appointmentBookingUrl: "",
    }),
    mounted() {
        this.handleOptions();
        this.handleScreenChange();
        this.handleVideoData();
        window.addEventListener("resize", this.handleScreenChange);
    },
    components: {
        SquareButton,
        // CardPaymentForm,
    },
    methods: {
        handleOptions() {
            const options = this.$props.options;
            if (options) {
                const { switches } = options;
                this.mode = switches.fitVideo ? "cover" : "contain";
                this.darkVideo = switches.darkenVideo;
            }
        },
        handleScreenChange() {
            const width = window.innerWidth;
            if (width <= 1400) {
                this.desktopStyle = false;
                this.isMobile = true;
                this.containerClass = "preview-mobile";
                this.screenControls = false;
                this.$emit("isMobile", true);
            } else {
                this.desktopStyle = true;
                this.isMobile = false;
                this.containerClass = "";
                this.screenControls = true;
                this.$emit("isMobile", false);
            }
        },
        handleVideoData() {
            const interactionsData = this.$props.interactionsData;
            const section = this.$props.section;

            if (interactionsData) {
                this.darkVideo = interactionsData.darken_video;
                this.toggles.audio = interactionsData.audio > 0;
                this.toggles.video = interactionsData.video > 0;
                this.toggles.text = interactionsData.text > 0;
                this.overlayOptions.text = interactionsData.overlay_text;
                this.overlayOptions.color = interactionsData.font_color;
                this.overlayOptions.size = interactionsData.font_size;
                this.mode =
                    interactionsData.fit_video > 0 ? "cover" : "contain";
                this.chooseOption = interactionsData.optionTitle;
                this.redirectVideoUrl = interactionsData.url;
                this.ctaButton.text = interactionsData.cta_btn_text;
                this.ctaButton.color = interactionsData.cta_btn_color;
                this.ctaButton.url = interactionsData.cta_url;
                this.toggles.firstName = interactionsData.first_name > 0;
                this.toggles.lastName = interactionsData.last_name > 0;
                this.toggles.email = interactionsData.email > 0;
                this.toggles.phoneNo = interactionsData.phone > 0;
                this.toggles.cityCountry = interactionsData.city_country > 0;
                this.toggles.postalCode = interactionsData.postal_code > 0;
                this.leadGeneration.headline = interactionsData.headline;
                this.leadGeneration.subHeadline = interactionsData.sub_headline;
                this.leadGeneration.submitBtnText =
                    interactionsData.submit_btn_text;
                this.leadGeneration.submissionUrl =
                    interactionsData.submit_btn_url;
                this.leadGeneration.inputOptions = interactionsData.inputTitle;
                this.cardDetails.headline = interactionsData.e_comm_headline;
                this.cardDetails.subHeadline =
                    interactionsData.e_comm_sub_headline;
                this.cardDetails.cardOptions = interactionsData.cardOptions;
                this.interactionType = interactionsData.interaction_type
                    ? interactionsData.interaction_type
                    : section;
                this.paymentCollection.accept_card =
                    interactionsData.accept_card;
                this.paymentCollection.accept_paypal =
                    interactionsData.accept_paypal;
                this.paymentCollection.currency = interactionsData.currency;
                this.paymentCollection.price = interactionsData.price;
                this.appointmentBookingUrl = interactionsData.booking_link;
                console.log(this.appointmentBookingUrl);
            }
        },
    },
    watch: {
        options: {
            handler() {
                this.handleOptions();
            },
            deep: true,
        },
        desktopStyle(toggle) {
            if (!toggle) {
                this.containerClass = "preview-mobile";
            } else {
                this.containerClass = "";
            }
        },
        interactionsData: {
            handler() {
                this.handleVideoData();
            },
            deep: true,
        },
    },
};
</script>
