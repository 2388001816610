<template>
    <div class="progress-meter">
        <div
            class="progress"
            :style='{
                width: percentage ? `${percentage}%` : "10px",
            }'
            :class="{
                'progress-animate': loading,
                'default-green': percentage < 80,
                'default-yellow': percentage > 80 && percentage < 90,
                'default-red': percentage > 90,
            }"
        >
        </div>
    </div>
</template>

<style lang="less">
    .progress-meter {
        width: 100%;
        height: 15px;
        background: #ebeef5;
        border-radius: 12px;
        transition: 0.3s;
        overflow: hidden;
        .progress {
            height: 100%;
            width: 20px;
            border-radius: 12px;
            transition: 0.1s;
        }
        .progress-animate {
            animation-name: progressLoader;
            animation-duration: 0.3s;
            animation-iteration-count: infinite;
        }
    }
    @keyframes progressLoader {
        0% {
            width: 0%;
        }
        50% {
            width: 50%; 
        }
        100% {
            width: 100%; 
        }
    }
</style>

<script>
export default {
    name: 'ProgressMeter',
    props: ['percentage', 'loading'],
}
</script>