<template>
    <div class="invoice-details">
        <subscription-billing-tab @section='setSection' section='billingInfo' />
        <div class="invoice-receipt">
            <div class="receipt-title-row">
                <div class="receipt-id">
                    <span>Receipt {{ invoice.receipt_id }}</span>
                </div>
                <button class="btn btn-secondary admin-receipt-button admin-receipt-button-pdf">Download PDF</button>
                <button class="btn btn-secondary admin-receipt-button admin-receipt-button-print">Print</button>
                <button class="btn btn-primary admin-receipt-button admin-receipt-button-email">Email Receipt</button>
            </div>
            <div class="row billing-issued">
                <div class="col-md-6">
                    <h4>Issued By</h4>
                    <h5>BigCommand LLC</h5>
                    <div class="text">
                        <span>108 West 13th Street,</span>
                    </div>
                    <div class="text">
                        <span>Wilmington, DE</span>
                    </div>
                    <div class="text">
                        <span>19801</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <h4>Details</h4>
                    <h5>Order {{ invoice.receipt_id }}</h5>
                   <div class="text-dtl">
                        <span>Due April 1, 2021 at 5:46 AM</span>
                    </div>
                    <div class="text-dtl">
                        <span>Paid April 1, 2021 at 5:46 AM</span>
                    </div>
                </div>
            </div>
            <div class="row billing-statement">
                <div class="col-md-12">
                    <h4>Billing Statement</h4>
                    <div class="div-hr hr-margin"></div>
                    <div class="row" v-if="invoice.plan">
                        <div class="col-md-9 subscription-title">
                            {{ invoice.plan.name }}
                        </div>
                        <div class="col-md-3">
                            ${{ invoice.plan.amount }}
                        </div>
                    </div>
                    <div class="div-hr hr-margin"></div>
                    <div class="row">
                        <div class="col-md-9 subscription-title">
                            Adilo Overage Charge
                        </div>
                        <div class="col-md-3" v-if="invoice.amount != null">
                            ${{ invoice.amount }}
                        </div>
                        <div class="col-md-3" v-else>
                            $0
                        </div>
                    </div>
                    <div class="div-hr hr-margin"></div>
                    <div class="row">
                        <div class="col-md-9 subscription-title">
                            Adilo Discount
                        </div>
                        <div class="col-md-3" v-if="invoice.discount != null">
                            ${{ invoice.discount }}
                        </div>
                        <div class="col-md-3" v-else>
                            $0
                        </div>
                    </div>
                    <div class="div-hr half-top-hr-margin"></div>
                    <div class="row total">
                        <div class="col-md-6 offset-md-6">
                            <span>${{ billingStatementTotal }}</span>
                            <div class="div-total-hr"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <messages-box
            v-if='message'
            :messages='message'
            background='#00ACDC'
            @closeMessages='message = ""'
        />
    </div>
</template>

<style lang="less" scoped>
    .invoice-details{
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        width: max-content;
        .invoice-receipt{
            background: #fff;
            border-radius: 7px;
            padding: 25px 25px;
            font-family: "Helvetica Neue-Medium";
            .receipt-title-row{
                display: flex;
                flex-direction: row;
                margin-bottom: 40px;
                .receipt-id{
                    font-size: 20px;
                    // width: 20%;
                    display: flex;
                    align-items: flex-end;
                    margin-right: 20px;
                }
                .admin-receipt-button{
                    padding:6px 30px;
                    font-weight:600;
                    border-radius:20px;
                    font-family: "Helvetica Neue";
                    font-size:14px;
                    margin-right:15px;
                    height: 40px;
                }
                .btn-secondary{
                    background-color: #909399;
                    border-color: #909399;
                }
                .btn-primary{
                    background-color: #0DABD8;
                    border-color: #0DABD8;
                }
            }
            .billing-issued{
                margin-bottom:35px;
                h4{
                   font-size:20px;
                }
                h5{
                   font-size:16px;
                }
                .text{
                    font-family: "Helvetica Neue";
                    font-size:16px;
                }
                .text-dtl{
                    font-family: "Helvetica Neue";
                    font-size:14px;
                }
            }
            .billing-statement{
                h4{
                    font-size: 20px;
                    margin-bottom:20px;
                }
                .subscription-title{
                    font-size:16px;
                }
                .div-hr{
                    height: 1px;
                    border-bottom: 1px solid #e8e8ea;
                }
                .hr-margin{
                    margin: 10px 0;
                }
                .half-top-hr-margin{
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
                .total{
                    span{
                        font-size: 20px;
                    }
                    .div-total-hr{
                        height: 1px;
                        border-bottom: 1px solid #e8e8ea;
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
</style>

<script>
import MessagesBox from '../../Common/MessagesBox';
import SubscriptionBillingTab from '../../Settings/Modules/Subscription/SubscriptionBillingTab';

export default {
    name: 'Invoice',
    components: {
        MessagesBox,
        SubscriptionBillingTab,
    },
    data: () => ({
        invoice: '',
        message: '',
        section: 'plan',
        billingStatementTotal: '',
    }),
    mounted() {
        // console.log(this.section)
        this.getInvoice();
    },
    methods: {
        getInvoice() {
            // console.log(this.$route);
            const id = this.$route.params.id;
            if (id) {
                this.axios.get(`billing/invoices/${id}`)
                    .then(response => {
                        // console.log(response.data);
                        if (!response.data.success) {
                            this.message = 'Failed to find invoice';
                            this.$router.push({path: '/settings'});
                            location.reload();
                        }else{
                            this.invoice = response.data.invoice;
                            // console.log(this.invoice);
                            const plan_amt = response.data.invoice.plan.amount;
                            const overusage_chrg = response.data.invoice.amount ? response.data.invoice.amount : 0;
                            const discount = response.data.invoice.discount ? response.data.invoice.discount : 0;
                            
                            this.billingStatementTotal = (plan_amt + overusage_chrg) - discount;
                            console.log(this.billingStatementTotal);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        },
        setSection() {
            this.$emit('section');
        }
    }
};
</script>
