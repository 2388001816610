<template>
    <div class="nav-user-control" >
        <div class="profile-control pr-3 d-flex" @click.prevent="showPopover=!showPopover">
            <div class="personal-details flex-column">
                <div class="user-name">{{ user.name }}</div>
                <div class="user-company" v-if='user.company'>{{ user.company }}</div>
            </div>
            <BaseIcons icon='defaultProfilePicture' class='pl-2'/>
            <BaseIcons icon='downArrow' class='pl-2' />
        </div>
        <div :style="{top: topMargin ? topMargin : 'auto'}" class="profile-control-popover d-flex flex-column justify-content-center" v-if='showPopover'>
            <div class="flex-column profile-name align-self-center">
                <BaseIcons icon='defaultProfilePicture' class='pl-2'/>
                <div class="user-name text-center mt-1">{{ user.name }}</div>
            </div>
            <router-link class="text-decoration-none"  @click.native="logout" to='/logout'>Logout</router-link>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .nav-user-control {
        .profile-control {
            display: flex;
            align-items: center;
            height: 100%;
            cursor: pointer;
            .user-name {
                font-size: 13px;
                font-family: 'Helvetica Neue-Medium';
            }
            .user-company {
                color: #A3BAC6;
                font-family: 'Helvetica Neue-Medium';
                font-size: 11px;
            }
        }
        .profile-control-popover {
            position: absolute;
            z-index: 4;
            right: 0;
            width: 200px;
            height: max-content;
            top: 40px;
            padding: 15px;
            background: #fff;
            animation-name: appear;
            animation-iteration-count: 1;
            animation-duration: 0.3s;
            transition: 0.3s;
            -webkit-box-shadow: 3px 3px 5px 6px #f5f8fa;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
            -moz-box-shadow: 3px 3px 5px 6px #f5f8fa;  /* Firefox 3.5 - 3.6 */
            box-shadow: 3px 3px 5px 6px #f5f8fa;
            .profile-name {
                width: max-content;
                font-family: 'Helvetica Neue-Medium';
            }
            a {
                color: #21455E;
            }
        }
    }
</style>

<script>
export default {
    props: ['topMargin'],
    data: () => ({
        showPopover: false,
        user: {
            name: 'Test',
            company: 'Adilo'
        }
    }),
    methods: {
        logout(){
            this.axios.post('/logout', {
               headers: {
                    Authorization : `Bearer ${window.localStorage.getItem('token')}`
                } 
            })
            .then(response => {
                console.log(response);

                localStorage.removeItem('token');

                // remove any other authenticated user data you put in local storage

                // Assuming that you set this earlier for subsequent Ajax request at some point like so:
                // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token ;
                delete this.axios.defaults.headers.common['Authorization'];

                // If using 'vue-router' redirect to login page
                this.$router.go('/');
                
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
};
</script>