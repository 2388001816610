<template>
    <div class="upload-video-header">
        <div class="upload-title poppins-semi-bold">{{ title || 'Build your campaign' }}</div>
        <div class="sub-header pt-4 d-flex justify-content-between">
            <div class="upload-sub-title poppins-regular">{{ subtitle || 'Add your video' }}</div>
            <span :class='mobilePreviewButton ? "mobile-preview-button" : "preview-button"'>
                <BaseButton
                    title='Preview'
                    background='#862D83'
                    color='#fff'
                    height='36px'
                    width='147px'
                    fontSize='14px'
                    @clicked='$emit("showPreview")'
                />
            </span>
        </div>
        <div class="cross-icon cross-icon-purple flex-column" @click='showWarning = true'>
            <BaseIcons icon='cross' />
            <div class="icon-text poppins-medium">ESC</div>
        </div>
        <div class="modals">
            <campaign-warning @clicked='handleWarningConfirm' />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .upload-video-header {
        .upload-title {
            color: #1E3941;
            font-size: 20px;
        }
        .upload-sub-title {
            color: #6E8186;
            font-size: 18px;
        }
        .sub-header {
            .preview-button,
            .mobile-preview-button {
                display: none;
            }
        }
        .exit-library-button {
            position: fixed;
            top: 20px;
            right: 20px;
        }
    }
    @media (max-width: 1400px) {
        .sub-header {
            .preview-button {
                display: block !important;
            }
        }
    }
    @media (max-width: 960px) {
        .sub-header {
            .mobile-preview-button {
                display: block !important;
            }
        }
    }

</style>

<script>
import CampaignWarning from '../../Projects/CampaignWarning';
export default {
    name: 'LibraryHeader',
    data: () => ({
        showWarning: false,
    }),
    props: ['subtitle', 'title', 'mobilePreviewButton'],
    components: { CampaignWarning },
    methods: {
        handleWarningConfirm(response) {
            this.showWarning = false;
            const goBack = response === 'yes';
            if (goBack) {
                this.$router.push({ path: '/projects' });
            }
        }
    }
}
</script>