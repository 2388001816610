<template>
    <div class="project-campaigns-header w-100 flex-column">
        <div class="desktop-section w-100 d-flex justify-content-between">
            <div class="left-section d-flex">
                <div class="title helvetica-bold">{{ title }}</div>
                <div class="count pl-4 helvetica-regular">{{ count }}</div>
            </div>
            <div class="right-section">
                <div class="d-flex header-controls">
                    <search-bar @query='$emit("query", $event)' class="pr-4 desktop-search-bar" />
                    <BaseButton
                        title='+ New campaign'
                        border='1px solid #1E3941'
                        fontFamily='Poppins-Medium'
                        fontSize='14px'
                        @clicked='$emit("openCampaignModal")'
                    />
                </div>
            </div>
        </div>
        <div class="mobile-section">
            <search-bar class="mobile-search-bar pt-3 w-100" :expanded='true' />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .project-campaigns-header {
        height: max-content;
        .left-section {
            .title,
            .count {
                font-size: 24px;
            }
        }
        .mobile-section {
            .mobile-search-bar {
                display: none;
            }
        }
    }
    @media (max-width: 960px) {
        .project-campaigns-header {
            .left-section {
                align-self: center;
                .title,
                .count {
                    font-size: 12px;
                }
            }
            .right-section {
                .desktop-search-bar {
                    display: none;
                }
                .mobile-search-bar {
                    display: block !important;
                }
                .header-controls {
                    flex-direction: column-reverse;
                }
            }
        }
    }
</style>

<script>
import SearchBar from '../Common/SearchBar';
export default {
    name: 'CampaignsHeader',
    props: ['count', 'title'],
    components: {
        SearchBar,
    },
    data: () => ({
        searchQuery: ' ',
    }),

};
</script>