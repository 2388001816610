<template>
    <transition name='slide-fade'>
        <div class="subscription-features" v-if="subscriptionData">
            <div class="subscription-features-header d-flex align-items-center">
                <div class="subscription-features-title pl-5 pt-2   -2 helvetica-bold">
                    SUBSCRIPTION
                </div>
            </div>
            <div class="subscription-features-body pt-4 pb-2">
                <div class="plan-controls d-flex justify-content-between pl-5 pb-4">
                    <div class="plan-details">
                        <div class="plan-name helvetica-bold small-fonts">
                            {{ subscriptionData.plan_data ? subscriptionData.plan_data.name : 'Adilo Plan' }}
                        </div>
                        <div class="plan-note helvetica-medium small-fonts">
                            Your current plan includes:
                        </div>
                    </div>
                    <div class="plan-change-controls d-flex ">
                        <BaseButton
                            title='Change Subscription'
                            class="d-flex align-self-end mr-5"
                            border="1px solid #0dabd8 !important"
                            color="#0dabd8"
                            hoverBg="#f5f6f9"
                            fontSize="13px"
                            width='max-content'
                            borderRadius='18px'
                            fontFamily="Helvetica Neue-Medium"
                        />
                        <BaseButton
                            title='Cancel Subscription'
                            class="d-flex align-self-end mr-5"
                            border="1px solid #FF5F56 !important"
                            color="#FF5F56"
                            hoverBg="#f5f6f9"
                            fontSize="13px"
                            width='max-content'
                            borderRadius='18px'
                            fontFamily="Helvetica Neue-Medium"
                        />
                    </div>
                </div>
                <features-listing
                    v-if='subscriptionData && subscriptionData.plan_data'
                    :items='subscriptionData.plan_data.features'
                    :name='subscriptionData.plan_data.name'
                />
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .subscription-features {
        .subscription-features-header {
            background: #0bacdb07;
            height: 48px;
            .subscription-features-title {
                font-size: 13px;
            }
        }
    }

    @media (max-width: 960px) {
        .subscription-features {
            .subscription-features-body {
                .plan-controls {
                    flex-direction: column-reverse;
                    .plan-details {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
</style>

<script>
import FeaturesListing from '../../../Common/FeaturesListing';
export default {
    name: 'SubscriptionFeatures',
    props: ['subscriptionData'],
    components: {
        FeaturesListing,
    },
    data: () => ({
    })
}
</script>