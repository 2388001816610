<template>
    <div
        class="icon-container"
        @click="$emit('clicked')"
        @dblclick="$emit('dbClicked')"
        @mouseover='showToolTip = true'
        @mouseleave="showToolTip = false"
    >
        <div class="clickable" v-if="iconPath">
            <img
                v-bind:src="iconPath"
                alt="icon"
                :style='{
                    width: imageWidth ? imageWidth : "auto",
                    height: imageHeight ? imageHeight : "auto",
                }'
            />
        </div>

        <!-- Tooltip -->
        <div class="base-tooltip desktop-element" v-if='tooltip && showToolTip' :style="{
            top: tooltipTop,
            left: tooltipLeft,
            background: tooltipBg,
            color: tooltipColor,
        }">
            {{ tooltip }}
        </div>
    </div>
</template>

<style lang="less" scoped>
    .icon-container {
        position: relative;
        height: max-content;
        width: max-content;
        .clickable {
            cursor: pointer;
        }
        .base-tooltip {
            position: absolute;
            color: #fff;
            background: #1E3941;
            color: rgba(255, 255, 255, 0.685);
            width: max-content;
            border-radius: 5px;
            padding: 10px 15px;
        }
    }
</style>

<script>
export default {
    name: 'BaseIcons',
    props: [
        'icon',
        'isMobileIcon',
        'tooltip',
        'tooltipTop',
        'tooltipLeft',
        'tooltipBg',
        'tooltipColor',
        'imageWidth',
        'imageHeight',
    ],
    data: () => ({
        showToolTip: false,
        iconPath: null,
    }),
    mounted() {
        this.getIconPath();
    },
    methods: {
        getIconPath() {
            const icon = this.$props.icon;
            if (icon) {
                this.iconPath = require(`../../../assets/svg/${icon}.svg`);
            }
        }
    },
    watch: {
        icon() {
            this.getIconPath();
        }
    }
}
</script>