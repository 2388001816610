<template>
    <transition name="fade">
        <div class="number-filter" v-if="show">
            <div class="flex-column controls pt-2 pb-2">
                <div class="selected-option d-flex pb-2">
                    <div class="selected-label position-relative">
                        <span class="label-text">
                            {{ selected.label }}
                        </span>
                        <div @mouseleave="showSelect = false">
                            <select-dropdown
                                :options='numberOptions'
                                :show='showSelect'
                                borderRadius='0px'
                                border='1px solid #ced4da'
                                width='200px'
                                top='40px'
                                left='0'
                                @selected='selectComparison'
                            />
                        </div>
                    </div>
                    <div @click="showSelect = !showSelect">
                        <BaseIcons icon='downArrow' class="down-icon" />
                    </div>
                </div>
                <div class="d-flex justify-content-between w-100">
                    <div class="first-comparison" :class="selected.label === 'is between' ? 'w-40' : 'w-100'">
                        <BaseInput
                            type='number'
                            borderRadius='0px'
                            class='w-100'
                            :inputValue='mainInput'
                            @value='mainInput = $event'
                        />
                    </div>
                    <div class="mid-text pt-1" v-if='selected.label === "is between"'>
                        and
                    </div>
                    <div class="second-comparison w-40" v-if='selected.label === "is between"'>
                        <BaseInput
                            type='number'
                            borderRadius='0px'
                            class='w-100'
                            :inputValue='secondaryInput'
                            @value='secondaryInput = $event'
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .number-filter {
        .w-40 {
            width: 40%;
        }
        .controls {
            .selected-option {
                cursor: pointer;
                .selected-label {
                    border: 1px solid #e2e5ed;
                    min-width: 200px;
                    max-width: max-content;
                    padding: 0 10px;
                    height: 39px;
                    .label-text {
                        position: relative;
                        top: 5px;
                    }
                }
                .down-icon {
                    position: relative;
                    top: 5px;
                    left: 15px;
                    cursor: pointer;
                }
            }
        }
    }
</style>

<script>
import SelectDropdown from '../SelectDropdown';

export default {
    name: 'NumberFilter',
    props: ['show'],
    components: {
        SelectDropdown,
    },
    data: () => ({
        selected: '',
        showSelect: false,
        numberOptions: [
            { id: 'equal', label: 'is equal to' },
            { id: 'between', label: 'is between' },
            { id: 'greater_than', label: 'is greater than' },
            { id: 'less_than', label: 'is less than' },
        ],
        mainInput: 1,
        secondaryInput: 1,
    }),
    mounted() {
        this.selectComparison(this.numberOptions[0]);
    },
    methods: {
        selectComparison(option) {
            this.selected = option;
            this.showSelect = false;
        },
        sendBackOptions() {
            if (!this.$props.show) return false;
            const { selected, mainInput, secondaryInput } = this;
            const typeId = selected.id;
            const filterObject = {
                type: typeId,
                primary_number: parseInt(mainInput),
                secondary_number: typeId === 'between' ? parseInt(secondaryInput) : null,
            };
            this.$emit('comparisonObject', filterObject);
        },
    },
    watch: {
        show() {
            this.sendBackOptions();
        },
        selected() {
            this.sendBackOptions();
        },
        mainInput() {
            this.sendBackOptions();
        },
        secondaryInput() {
            this.sendBackOptions();
        }
    }
}
</script>