<template>
    <div class="integration-card smooth-shadow" v-if="integrations">
        <div class="name-section pt-4 pl-4">
            <div class="category-name">{{ type }} {{ integrations.length }}</div>
        </div>
        <div
            class="d-flex justify-content-between integration"
            v-for='integration in integrations'
            :key='integration.id'
        >
            <BaseIcons
                :icon='integration.service_key'
                imageHeight='60px'
                imageWidth='60px'
                class="p-4"
            />
            <integration-card-details
                :integration='integration'
                @process='processCardRequest'
            />
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<style lang="less">
    .integration-card {
        background: #fff;
        border-radius: 12px;
        .name-section {
            .category-name {
                font-size: 20px;
                font-weight: 501;
            }
            .category-icon {
                width: 60px;
                height: 50px;
            }
        }
        .detail-section {
            font-weight: 500;
            padding: 15px;
            position: relative;
        }
        .integration {
            padding: 30px 0;
            border-bottom: 1px solid #e8e8ea;
        }
        .disconnect-button {
            float: right;
            color: #00ACDC;
            width: max-content;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            font-weight: 600;
            letter-spacing: 2px;
            cursor: pointer;
            transition: 0.2s;
            .dots {
                position: relative;
                bottom: 2px;
            }
            &:hover {
                background: #00acdc18;
            }
            &:active {
                transform: scale(0.9);
            }
        }
    }
</style>

<script>
import IntegrationCardDetails from '../../../Common/Widgets/IntegrationCardDetails';
import { INTEGRATIONS } from '../../../../assets/js/constants';

export default {
    name: 'IntegrationCard',
    props: ['type', 'integrations'],
    data: () => ({
        integrationDetails: INTEGRATIONS,
    }),
    components: {
        IntegrationCardDetails
    },
    methods: {
        processCardRequest(data) {
            const details = this.integrationDetails;
            const integration = data.integration;
            const selected = details.filter((d) => {
                return d.icon === integration.service_key;
            })[0];
            if (selected) {
                this.$emit('action', {
                    type: data.type,
                    authDetails: selected,
                    integration,
                });
            }
        }
    }
}
</script>