<template>
    <transition name="fade">
        <div class="share-embed-modal" v-if="show">
            <div class="share-embed-content">
                <div class="share-embed-container">
                    <div class="share-embed-head d-flex justify-content-between p-3">
                        <div></div>
                        <div class="share-embed-head-title helvetica-medium">SHARE & EMBED</div>
                        <div class="share-close-btn cursor-pointer" @click="$emit('close')">
                            <BaseIcons icon='cross' />
                        </div>
                    </div>
                    <div class="share-embed-body d-flex">
                        <div class="embed-sidebar">
                            <div
                                class="side-tile text-center poppins-regular divide"
                                :class="{'highlighted': activeSection === 'direct_link'}"
                                @click='activeSection = "direct_link"'
                            >
                                Direct <br> Link
                            </div>
                            <div
                                class="side-tile text-center poppins-regular divide"
                                :class="{'highlighted': activeSection === 'widget_embed'}"
                                @click='activeSection = "widget_embed"'
                            >
                                Widget <br> Embed
                            </div>
                            <div
                                class="side-tile text-center poppins-regular divide"
                                :class="{'highlighted': activeSection === 'inline_embed'}"
                                @click='activeSection = "inline_embed"'
                            >
                                Inline <br> Embed
                            </div>
                            <div
                                class="side-tile text-center poppins-regular"
                                :class="{'highlighted': activeSection === 'email_embed'}"
                                @click='activeSection = "email_embed"'
                                >
                                Email <br> Embed
                            </div>
                        </div>
                        <div class="embed-sections p-4">
                            <embed-link
                                :show='activeSection === "direct_link"'
                                :url='video.url ? video.url : "" '
                            />
                            <embed-inline
                                :show='activeSection === "inline_embed"'
                            />
                            <embed-email
                                :show="activeSection === 'email_embed'"
                            />
                        </div>
                    </div>
                    <div class="share-embed-footer">
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .share-embed-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        .divide {
            border-bottom: 1px solid rgba(187, 187, 187, 0.616);
        }
        .highlighted {
            background: rgba(0, 0, 0, 0.274) !important;
        }
        .share-embed-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .share-embed-container {
                background: #fff;
                width: 637px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                height: max-content;
                position: relative;
                font-size: 14px;
                .share-embed-head {
                    border-bottom: 1px solid #0DABD8;
                    .share-embed-head-title {
                        font-size: 15px;
                        position: relative;
                        top: 5px;
                    }
                }
                .share-embed-body {
                    .embed-sidebar {
                        width: 20%;
                        .side-tile {
                            background: rgba(187, 187, 187, 0.315);
                            width: min-content;
                            cursor: pointer;
                            width: 100%;
                            padding: 30px 0;
                        }
                    }
                    .embed-sections {
                        width: 80%;
                    }
                }
            }
        }
    }
</style>

<script>
import EmbedLink from '../Sections/EmbedLink';
import EmbedInline from '../Sections/EmbedInline';
import EmbedEmail from '../Sections/EmbedEmail';

export default {
    name: 'ShareAndEmbed',
    props: ['show', 'campaign', 'video'],
    components: {
        EmbedLink,
        EmbedInline,
        EmbedEmail,
    },
    data: () => ({
        activeSection: 'direct_link',
    })
}
</script>