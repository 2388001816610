<template>
    <div class="errors-container" v-if='show'>
        <div
            class="error d-flex"
            v-for='(error, index) in errors'
            :key='index'
            :style='{ color: color ? color : "#21455E" }'
        >
            <base-icons icon='warning' />
            <div class="error-text ml-2">{{ error }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Errors',
    props: ['errors', 'color', 'show'],
}
</script>