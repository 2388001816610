var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-input-container",class:_vm.notValidClass},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"form-control base-input",class:_vm.className,style:({
            borderRadius: _vm.borderRadius,
            border: _vm.border,
            borderStyle: _vm.borderStyle,
            background: _vm.background,
            width: _vm.width,
            opacity: _vm.opacity,
            padding: _vm.padding,
            color: _vm.color,
            textAlign: _vm.textAlign,
        }),attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"id":_vm.inputId,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"input":_vm.sendValue,"keypress":_vm.ifKeyPressed,"change":function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"form-control base-input",class:_vm.className,style:({
            borderRadius: _vm.borderRadius,
            border: _vm.border,
            borderStyle: _vm.borderStyle,
            background: _vm.background,
            width: _vm.width,
            opacity: _vm.opacity,
            padding: _vm.padding,
            color: _vm.color,
            textAlign: _vm.textAlign,
        }),attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"id":_vm.inputId,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"input":_vm.sendValue,"keypress":_vm.ifKeyPressed,"change":function($event){_vm.value=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"form-control base-input",class:_vm.className,style:({
            borderRadius: _vm.borderRadius,
            border: _vm.border,
            borderStyle: _vm.borderStyle,
            background: _vm.background,
            width: _vm.width,
            opacity: _vm.opacity,
            padding: _vm.padding,
            color: _vm.color,
            textAlign: _vm.textAlign,
        }),attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"id":_vm.inputId,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.value=$event.target.value},_vm.sendValue],"keypress":_vm.ifKeyPressed}}),_c('div',{staticClass:"input-icon",class:{'mobile-show': _vm.mobileOnlyIcon},on:{"click":_vm.iconClicked}},[_c('base-icons',{attrs:{"icon":_vm.icon}})],1),(_vm.error)?_c('span',{staticClass:"error-text"},[(typeof _vm.error === "string")?_c('div',[_c('errors',{attrs:{"errors":[_vm.error],"show":_vm.error,"color":"#EE5951"}})],1):(typeof _vm.error === 'boolean')?_c('div',[_c('div',{staticClass:"default-red-text"},[_vm._v("This field is required")])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }