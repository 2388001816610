<template>
	<div class="cta-btn-container">
		<div
			class="response-header-section d-flex justify-content-between poppins-medium"
		>
			<div class="title">Configure CTA Button</div>
			<div
				class="back-btn base-links cursor-pointer"
				@click="$emit('section', '')"
			>
				Change Interaction
			</div>
		</div>
		<div class="cta-btn mt-3">
			<input-with-label
				headLabel="Enter button text"
				inputType="text"
				class="mt-4 mb-3 btn-txt"
				:inputValue="cta_btn_text"
				keyTrigger="Enter"
				@value="cta_btn_text = $event"
			/>
			<div class="misc-option d-flex pb-2">
				<div class="action-text poppins-medium">Button color</div>
				<div class="action-trigger">
					<color-picker
						:color="cta_btn_color"
						@color="cta_btn_color = $event"
					/>
				</div>
			</div>
			<BaseInput
				placeholder="On click, go to this URL"
				border="1px solid #ccc"
				borderRadius="8px"
				class="url-search mt-5"
				:inputValue="cta_url"
				keyTrigger="Enter"
				@value="cta_url = $event"
			/>
			<p class="mt-3">
				This link setting is optional and if added, ends any conditional
				logic, connected to this step and redirects viewer to 3rd party
				link.
			</p>
		</div>
		<div class="response-settings">
			<input-with-label
				v-for="(element, index) in inputElements"
				:key="index"
				:headLabel="element.headLabel"
				:subLabel="element.subLabel"
				:class="element.class"
				:inputValue="getDataVar(element.input)"
				@value="handleInputs(element.input, $event)"
				:inputType="element.type"
			/>
			<switch-with-label
				class="collect-data-toggle"
				:toggle="toggles.collect_data"
				label="Collect data on submission"
				@clicked="handleToggles('collect_data')"
			/>
		</div>
	</div>
</template>

<style lang="less">
.cta-btn-container {
	.cta-btn {
		.btn-txt {
			width: 100%;
			.label {
				color: #1e39419a;
				font-size: 14px;
			}
			.type-input {
				width: 300px;
			}
		}
		.misc-option {
			.action-text {
				align-self: center;
				font-size: 14px;
				color: #1e39419a;
				width: 150px;
				text-align: center;
			}
			.action-trigger {
				width: 159px;
				height: 45px;
				background: #fff;
				border-radius: 10px;
				display: flex;
				justify-content: flex-end;
				margin-left: 45px;
			}
		}
	}
	.response-settings {
		.collect-data-toggle {
			width: 224px;
			.switch-label-trigger-text {
				padding-right: 0px !important;
				font-size: 12px;
			}
		}
	}
}
</style>

<script>
import InputWithLabel from "../InputWithLabel";
import ColorPicker from "../../../Common/Widgets/ColorPicker";
import SwitchWithLabel from "../../../Common/SwitchWithLabel";

export default {
	name: "CtaButton",
	props: ["interactionsData"],
	data: () => ({
		cta_btn_color: "#00ACDC",
		cta_url: "",
		cta_btn_text: "",
		delay_interaction_by: 15,
		inputElements: [
			{
				input: "delay_interaction_by",
				headLabel: "Delay interaction by",
				subLabel: "seconds",
				class: "mb-4",
				type: "number",
			},
		],
		toggles: {
			collect_data: false,
		},
		ctaBtnColor: "",
	}),
	components: {
		InputWithLabel,
		ColorPicker,
		SwitchWithLabel,
	},
	mounted() {
		this.ctaBtnColor = this.cta_btn_color;
		this.populateInteractionsData();
	},
	methods: {
		handleToggles(toggle) {
			this.toggles[toggle] = !this.toggles[toggle];
		},
		handleInputs(toggle, value) {
			if (value > 3) {
				value = 3;
			}
			this[toggle] = parseInt(value);
		},
		getDataVar(variable) {
			return this[variable];
		},
		populateInteractionsData() {
			const interactionsData = this.$props.interactionsData;

			if (interactionsData) {
				const toggles = this.toggles;
				toggles.collect_data = interactionsData.collect_data;
				this.toggles = toggles;
				this.delay_interaction_by =
					interactionsData.delay_interaction_by;
				this.cta_btn_text = interactionsData.cta_btn_text;
				this.cta_btn_color = interactionsData.cta_btn_color
					? interactionsData.cta_btn_color
					: interactionsData.ctaBtnColor;
				this.cta_url = interactionsData.cta_url;
			}
		},
	},
	watch: {
		$data: {
			handler(data) {
				let {
					toggles,
					delay_interaction_by,
					cta_btn_color,
					cta_btn_text,
					cta_url,
					ctaBtnColor,
				} = data;
				const { collect_data } = toggles;
				this.$emit("data", {
					collect_data,
					delay_interaction_by,
					cta_btn_color,
					cta_btn_text,
					cta_url,
					ctaBtnColor,
				});
			},
			deep: true,
		},
		interactionsData: {
			handler() {
				this.populateInteractionsData();
			},
			deep: true,
		},
	},
};
</script>
