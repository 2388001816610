var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-meter"},[_c('div',{staticClass:"progress",class:{
            'progress-animate': _vm.loading,
            'default-green': _vm.percentage < 80,
            'default-yellow': _vm.percentage > 80 && _vm.percentage < 90,
            'default-red': _vm.percentage > 90,
        },style:({
            width: _vm.percentage ? (_vm.percentage + "%") : "10px",
        })})])}
var staticRenderFns = []

export { render, staticRenderFns }