<template>
    <div class="subscription-settings-container">
        <subscription-billing-tab @section='section = $event' :section="section"/>
        <div class="subscription-sub-section" v-if="section === 'plan'">
            <div class="subscription-settings-header helvetica-medium p-5 flex-column">
                <div class="subscription-settings-title">Subscription Plan</div>
                <div class="subscription-settings-description mt-2">
                    View and manage your subscriptions plans and upgrades
                </div>
            </div>
            <div class="subscription-settings-sections mb-5 h-100">
                <subscription-status :loading='loading' :subscriptionData='subscriptionData' />
                <subscription-usage v-if='subscriptionData' :loading='loading' :subscriptionData='subscriptionData' />
                <subscription-features v-if='subscriptionData' :subscriptionData='subscriptionData'/>
            </div>
        </div>
        <div v-if="section === 'billingInfo' && subscriptionData">
            <subscription-billing v-if='subscriptionData' :subscriptionData='subscriptionData' />
            <invoice-listing @updateSection='$emit("updateSection", $event)' :userId='subscriptionData.id' :section="section"/>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .subscription-settings-container {
        .subscription-sub-section {
            background: #fff;
            border-radius: 7px;
            font-family: "Helvetica Neue";
            .subscription-settings-header {
                .subscription-settings-title {
                    font-size: 20px;
                    font-weight: 600;
                }
                .subscription-settings-description {
                    font-size: 14px;
                }
            }
        }
    }

    @media (max-width: 960px) {
        .subscription-settings-container {
        }
    }
</style>

<script>
import SubscriptionStatus from '../../Settings/Modules/Subscription/SubscriptionStatus';
import SubscriptionUsage from '../../Settings/Modules/Subscription/SubscriptionUsage';
import SubscriptionFeatures from '../../Settings/Modules/Subscription/SubscriptionFeatures';
import SubscriptionBilling from '../../Settings/Modules/Subscription/SubscriptionBilling';
import InvoiceListing from '../../Settings/Modules/Subscription/InvoiceListing';
import SubscriptionBillingTab from '../../Settings/Modules/Subscription/SubscriptionBillingTab';

export default {
    name: 'SubscriptionSettings',
    components: {
        SubscriptionStatus,
        SubscriptionUsage,
        SubscriptionFeatures,
        SubscriptionBilling,
        InvoiceListing,
        SubscriptionBillingTab,
    },
    data: () => ({
        loading: false,
        subscriptionData: null,
        section: 'plan',
    }),
    mounted() {
        this.getSubscriptionData();
    },
    methods: {
        getSubscriptionData() {
            this.loading = true;
            this.axios.get('billing/subscription-data')
                .then(response => {
                    this.loading = false;
                    if (response.data.success) {
                        this.subscriptionData = response.data.details;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                })
        },
    },
};
</script>