<template>
    <transition name="fade">
        <div
            class="base-select"
            v-if='show'
            :style='{top, left, width}'
            :class='arrowPosition'
        >
            <div
                class="option helvetica-regular cursor-pointer"
                v-for="option in options"
                :key='option.id'
            >
            <div class="d-flex justify-content-between" @click="$emit('selected', option.id)">
                <div class="label">{{ option.label }}</div>
                <BaseIcons v-if="selectedOption(option.id)" icon='checkMark' class="pl-2" />
            </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less">
    .base-select {
        background: #4b5655c7;
        color: rgba(255, 255, 255, 0.685);
        width: max-content;
        border-radius: 5px;
        padding: 10px 0;
        position: absolute;
        .option {
            width: 100%;
            padding: 5px 15px;
            &:hover {
                background: #1E3941;
            }
        }
    }
    .center::before {
        content: "";
        width: 0;
        height: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: -8px;
        z-index: 4;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #4b5655c7;
    }
    .left::before {
        content: "";
        width: 0;
        height: 0;
        left: 1%;
        margin: auto;
        top: -8px;
        z-index: 4;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #4b5655c7;
    }

    .right::before {
        content: "";
        width: 0;
        height: 0;
        right: 1%;
        margin: auto;
        top: -8px;
        z-index: 4;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #4b5655c7;
    }

    @media(max-width: 960px) {
        .base-select {
            left: 0;
            right: 0;
            margin: auto;
            width: 90vw;
            .option {
                font-size: 12px;
            }
        }
    }
</style>

<script>
export default {
    name: 'BaseSelect',
    props: ['selected', 'options', 'show', 'left', 'top', 'width', 'arrowPosition'],
    methods: {
        selectedOption(id) {
            const selected = this.$props.selected;
            if (selected) {
                const IsSelected = id === selected || selected.includes(id);
                return IsSelected;
            }
        }
    }
}
</script>