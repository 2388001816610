<template>
    <transition name='fade'>
        <div class="email-confirm-modal" v-if='show'>
            <div class="email-confirm-content">
                <div class="email-cofirm-container">
                    <div class="email-confirm-header pt-3 pb-3 d-flex justify-content-between">
                        <div></div>
                        <div class="email-modal-title helvetica-medium">CHANGE EMAIL</div>
                        <BaseIcons icon='modalCross' @clicked='$emit("close")' class="pr-3" />
                    </div>
                    <div class="email-confirm-body">
                        <div class="flex-column form-input">
                            <label class="base-label" for="first_name">Email Address</label>
                            <div class="email-input position-relative">
                                <BaseInput
                                    :inputValue='email'
                                    placeholder='Enter Email Address'
                                    inputId='email'
                                    @value='email = $event'
                                    :error='error'
                                    keyTrigger='Enter'
                                    @keyPressed='sendEmail'
                                />
                            </div>
                        </div>
                    </div>
                    <div class="email-confirm-footer pt-3 pb-4 d-flex justify-content-center">
                        <div class="button-container">
                            <BaseButton
                                title='Cancel'
                                background='#707070'
                                color='#fff'
                                height='36px'
                                width='147px'
                                class="mr-5"
                                @clicked='$emit("close")'
                                :loading='loading'
                            />
                        </div>
                        <div class="button-container">
                            <BaseButton
                                title='Change'
                                background='#0dabd8'
                                color='#fff'
                                height='36px'
                                width='147px'
                                @clicked='sendEmail'
                                :loading='loading'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <messages-box
                v-if='response.show'
                :messages='response.message'
                @closeMessages='closeMessage'
                background='#0DABD8'
            />
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .email-confirm-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        .email-confirm-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .email-cofirm-container {
                background: #fff;
                width: 520.64px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                position: relative;
                font-size: 14px;
                .email-confirm-header {
                    border-bottom: 1px solid #0DABD8;
                    font-size: 15px;
                }
                .email-confirm-body {
                    padding: 30px 0;
                    .form-input {
                        padding: 0 50px;
                    }
                }
            }
        }
    }
</style>

<script>
import validator from 'validator';
import MessagesBox from '../MessagesBox';

export default {
    name: 'EmailConfirmModal',
    props: ['show', 'userId'],
    data: () => ({
        email: '',
        loading: false,
        error: '',
        response: {
            show: false,
            message: '',
        }
    }),
    components: {
        MessagesBox,
    },
    methods: {
        sendEmail() {
            const email = this.email;
            const userId = this.$props.userId;

            if (!email || !validator.isEmail(email)) {
                this.error = 'Please enter a correct email';
                return false;
            }
            this.error = '';
            this.loading = true;
            this.axios.post('setting/change-email', {
                user_id: userId,
                email
            }).then(response => {
                this.loading = false;
                const responseData = response.data;
                const success = responseData.result && responseData.result === 'success';
                if (success) {
                    this.response.show = true;
                    this.response.message = 'Please follow instruction sent to your current email to change.';
                    this.showHint = true;
                }
            }).catch(error => {
                console.log(error);
                this.response.show = true;
                this.response.message = 'Failed to send email, please try again';
                this.loading = false;
            });
        },
        closeMessage() {
            this.response.show = false;
            this.response.message = '';
            this.$emit('close');
        }
    }
}
</script>