<template>
    <div class="embed-link" v-if='show'>
        <div class="d-flex justify-content-between">
            <div class="link d-flex">
                <BaseIcons icon='link' class="link-icon" />
                <div class="url pl-2">
                    {{ url }}
                </div>
            </div>
            <BaseButton
                title='Copy'
                background='#862D83'
                color='#fff'
                height='39px'
                borderRadius='20px'
                width='100px'
                @clicked='copyToClipboard'
            />
        </div>
        <div class="d-flex justify-content-between pt-3">
            <BaseRadio
                label='Adaptive Format'
                :selected='type === "adaptive"'
                @clicked='type = "adaptive"'
            />
            <BaseRadio
                label='Vertical Format'
                :selected='type === "vertical"'
                @clicked='type = "vertical"'
            />
            <BaseRadio
                label='Card Fromat'
                :selected='type === "card"'
                @clicked='type = "card"'
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .embed-link {
        width: 100%;
        .link {
            background: #ebf9fa;
            width: 270px;
            height: 30px;
            padding: 5px;
            position: relative;
            top: 7px;
            border-radius: 10px;
            .link-icon {
                position: relative;
                bottom: 2px;
            }
            .url {
                overflow:hidden; 
                white-space:nowrap; 
                text-overflow: ellipsis;
            }
        }
    }
</style>

<script>
export default {
    name: 'EmbedLink',
    props: ['show', 'url'],
    data: () => ({
        type: 'adaptive'
    }),
    methods: {
        copyToClipboard() {
            let url = this.$props.url;
            if (url) {
                let el = document.createElement('textarea');
                el.value = url; 
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$emit('copySuccess');
            }
        },

    }
}
</script>