<template>
    <div class="search-bar" @mouseover="toggle" @mouseleave="toggle(this, true)">
        <div
            class="d-flex search-bar-content align-items-center"
            :style="{ borderColor }">
            <BaseIcons :icon='icon ? icon : "magnify"' class="icon"/>
            <BaseInput
                :placeholder='placeholder || "Search Videos"'
                borderStyle='none none none none !important'
                border='none'
                background='transparent'
                :opacity='opacity'
                :width='width'
                :padding='padding'
                :inputValue='query'
                class='search-input'
                keyTrigger='Enter'
                @value='query = $event'
                @keyPressed='searchData'
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .search-bar {
        .search-bar-content {
            border: 1px solid #1E3941;
            border-radius: 20px;
            transition: 0.3s;
            .icon {
                position: relative;
                left: 10px;
                top: 3px;
                width: 36px;
                height: 36px;
                display: block;
            }
        }
    }
</style>

<script>
export default {
    name: 'SearchBar',
    props: ['searchQuery', 'expanded', 'borderColor', 'icon', 'placeholder'],
    data: () => ({
        width: '0px',
        opacity: 0,
        padding: '1px',
        query: '',
    }),
    mounted() {
        if (this.$props.expanded) this.toggle();
    },
    methods: {
        toggle(e, hide = false) {
            if (!this.expanded) {
                if (!hide) {
                    return this.expand();
                } else {
                    return this.collapse();
                }
            }
            return this.expand();
        },
        expand() {
            this.opacity = 1;
            this.width = '200px';   
            this.padding = 'auto';
        },
        collapse() {
            this.width = '0px';
            this.opacity = 0;
            this.padding = '1px';
        },
        searchData() {
            const query = this.query;
            this.$emit('query', query);
        }
    },
    watch: {
        query(val) {
            this.$emit('value', val);
        }
    }
}
</script>