<template>
    <div class="subscription-container">
        <auth-header :user='user' />
        <div class="background desktop-bg">
            <BaseIcons icon='subPageBg' />
        </div>
        <div class="background mobile-bg">
            <BaseIcons icon='subPageMobile' />
        </div>
    </div>
</template>

<style lang="less">

</style>

<script>
import AuthHeader from '../components/Common/AuthHeader';
export default {
    name: 'Subscription',
    components: {
        AuthHeader,
    },
    data: () => ({
        user: {
            id: '1',
            name: 'Test',
            profile: '',
        }
    })
}
</script>