<template>
	<div class="lead-generation-container">
		<div
			class="response-header-section d-flex justify-content-between poppins-medium"
		>
			<div class="title">Configure lead generation</div>
			<div
				class="back-btn base-links cursor-pointer"
				@click="$emit('section', '')"
			>
				Change Interaction
			</div>
		</div>
		<div class="lead-generation mt-3">
			<label class="">Headline</label>
			<BaseInput
				border="1px solid #ccc"
				borderRadius="8px"
				class="headline-inpt mb-3"
				:inputValue="headline"
				keyTrigger="Enter"
				@value="headline = $event"
			/>
			<label class="">Sub-headline</label>
			<BaseInput
				border="1px solid #ccc"
				borderRadius="8px"
				class="sub-headline-inpt"
				:inputValue="sub_headline"
				keyTrigger="Enter"
				@value="sub_headline = $event"
			/>
			<div class="response-triggers mt-4">
				<switch-with-label
					v-for="(element, index) in togglesElements"
					:key="index"
					:toggle="toggles[element.toggle]"
					:label="element.label"
					@clicked="handleToggles(element.toggle)"
					class="my-2"
				/>
			</div>
			<div class="choose-options mt-3">
				<div
					class="form-group"
					v-for="(input, k) in inputOptions"
					:key="k"
				>
					<div class="d-flex">
						<input
							type="text"
							class="form-control"
							placeholder="Input value"
							v-model="input.title"
						/>
						<BaseIcons
							:icon="'remove'"
							class="device-icon remove-icon"
							@clicked="remove(k)"
							v-show="k || (!k && inputOptions.length > 0)"
						/>
						<BaseIcons
							:icon="'directionArrows'"
							class="device-icon arrow-icon"
							:class="{ event: input.event }"
							@clicked="addToPreview(k)"
						/>
					</div>
				</div>
				<div class="add-more-optn mt-4">
					<BaseIcons
						:icon="'iconsPlus'"
						class="device-icon"
						v-if="inputOptions.length < 3"
						@clicked="add()"
					/>
					<span v-if="inputOptions.length < 3">Add new input</span>
				</div>
			</div>
			<div class="submit-btn">
				<label class="">Submit button text</label>
				<BaseInput
					border="1px solid #ccc"
					borderRadius="8px"
					class="sub-btn-inpt mb-3"
					:inputValue="submit_btn_text"
					keyTrigger="Enter"
					@value="submit_btn_text = $event"
				/>
			</div>
			<BaseInput
				placeholder="On submission, go to this URL"
				border="1px solid #ccc"
				borderRadius="8px"
				class="url-search mt-5"
				:inputValue="submit_btn_url"
				keyTrigger="Enter"
				@value="submit_btn_url = $event"
			/>
			<p class="mt-3">
				This link setting is optional and if added, ends any conditional
				logic, connected to this step and redirects viewer to 3rd party
				link.
			</p>
		</div>
		<div class="response-settings">
			<input-with-label
				v-for="(element, index) in inputElements"
				:key="index"
				:headLabel="element.headLabel"
				:subLabel="element.subLabel"
				:class="element.class"
				:inputValue="getDataVar(element.input)"
				@value="handleInputs(element.input, $event)"
				:inputType="element.type"
			/>
		</div>
	</div>
</template>

<style lang="less">
.lead-generation-container {
	.lead-generation {
		.response-triggers {
			width: 250px;
			.switch-label-trigger {
				margin-top: 10px !important;
				margin-bottom: 10px !important;
				.switch-label-trigger-text {
					font-size: 18px;
				}
			}
		}
		.choose-options {
			.form-group {
				position: relative;
				.form-control {
					border-radius: 5px;
				}
			}
			.remove-icon {
				position: absolute;
				top: 5px;
				right: 35px;
			}
			.arrow-icon {
				top: 5px;
				left: 5px;
			}
			.event {
				pointer-events: none;
			}
			.add-more-optn {
				color: #1e39419a;
				font-size: 15px;
				font-weight: 600;
				.device-icon {
					display: inline-flex;
					margin-right: 15px;
				}
			}
		}
		p {
			font-size: 15px;
			color: #1e39419a;
		}
	}
}
</style>

<script>
import SwitchWithLabel from "../../../Common/SwitchWithLabel";
import InputWithLabel from "../InputWithLabel";

function chooseOptn(title) {
	this.title = title || "";
}

export default {
	name: "LeadGeneration",
	props: ["interactionsData"],
	components: {
		SwitchWithLabel,
		InputWithLabel,
	},
	data: () => ({
		headline: "",
		sub_headline: "",
		toggles: {
			first_name: false,
			last_name: false,
			email: false,
			phone: false,
			city_country: false,
			postal_code: false,
		},
		togglesElements: [
			{ toggle: "first_name", label: "First name" },
			{ toggle: "last_name", label: "Last name" },
			{ toggle: "email", label: "Email address" },
			{ toggle: "phone", label: "Phone number" },
			{ toggle: "city_country", label: "City/Country" },
			{ toggle: "postal_code", label: "Postal Code" },
		],
		inputOptions: [new chooseOptn("")],
		inputTitle: [],
		inputOptnTitle: "",
		submit_btn_text: "",
		submit_btn_url: "",
		delay_interaction_by: 15,
		inputElements: [
			{
				input: "delay_interaction_by",
				headLabel: "Delay interaction by",
				subLabel: "seconds",
				class: "mb-4",
				type: "number",
			},
		],
	}),
	mounted() {
		this.populateInteractionsData();
	},
	methods: {
		handleToggles(toggle) {
			this.toggles[toggle] = !this.toggles[toggle];
		},
		add() {
			this.inputOptions.push(new chooseOptn(""));
		},
		remove(index) {
			this.inputOptions.splice(index, 1);
			this.inputTitle.splice(index, 1);
		},
		addToPreview(index) {
			let inputOption = this.inputOptions[index];
			inputOption.event = !inputOption.event;
			this.$set(this.inputOptions, index, inputOption);
			this.inputTitle.push(this.inputOptions[index].title);
		},
		handleInputs(toggle, value) {
			if (value > 3) {
				value = 3;
			}
			this[toggle] = parseInt(value);
		},
		getDataVar(variable) {
			return this[variable];
		},
		populateInteractionsData() {
			const interactionsData = this.$props.interactionsData;

			if (interactionsData) {
				const toggles = this.toggles;
				toggles.first_name = interactionsData.first_name;
				toggles.last_name = interactionsData.last_name;
				toggles.email = interactionsData.email;
				toggles.phone = interactionsData.phone;
				toggles.city_country = interactionsData.city_country;
				toggles.postal_code = interactionsData.postal_code;
				this.toggles = toggles;

				this.delay_interaction_by =
					interactionsData.delay_interaction_by;
				this.inputOptnTitle = interactionsData.inputTitle;
				this.headline = interactionsData.headline;
				this.sub_headline = interactionsData.sub_headline;
				this.submit_btn_text = interactionsData.submit_btn_text;
				this.submit_btn_url = interactionsData.submit_btn_url;
			}
		},
	},
	watch: {
		$data: {
			handler(data) {
				let {
					toggles,
					delay_interaction_by,
					headline,
					sub_headline,
					submit_btn_text,
					submit_btn_url,
					inputTitle,
				} = data;
				const {
					first_name,
					last_name,
					email,
					phone,
					city_country,
					postal_code,
				} = toggles;
				this.$emit("data", {
					first_name,
					last_name,
					email,
					phone,
					city_country,
					postal_code,
					delay_interaction_by,
					headline,
					sub_headline,
					submit_btn_text,
					submit_btn_url,
					inputTitle,
				});
			},
			deep: true,
		},
		interactionsData: {
			handler() {
				this.populateInteractionsData();
			},
			deep: true,
		},
	},
};
</script>
