<template>
    <transition name="fade">
        <div class="confirm-modal" v-if='show'>
            <div class="confirm-content">
                <div class="confirm-container" :style='{borderColor: modalColors.bg}'>
                    <div class="confirm-head text-center pt-3 pb-3 helvetica-medium" :style='{borderColor: modalColors.bg}'>
                        {{ title || "CONFIRM" }}
                    </div>
                    <div class="confirm-body pt-3 pb-3 flex-column text-center">
                        <div class="body-text pt-2 pb-2 helvetica-medium">
                            {{ text || "Are you sure you want to take this action?" }}
                        </div>
                        <div class="body-text pt-2 pb-2 helvetica-medium" v-if="subText">
                            {{ subText }}
                        </div>
                    </div>
                    <div class="confirm-foot pt-3 pb-4 d-flex justify-content-center">
                        <div class="button-container" @mouseover="selected = 'no'" @mouseleave="selected = ''">
                            <BaseButton
                                title='No'
                                background='#707070'
                                :opacity='selected != "no" ? "0.7" : "1"'
                                color='#fff'
                                height='36px'
                                width='147px'
                                class="mr-5"
                                @clicked='$emit("close")'
                              
                            />
                        </div>
                        <div class="button-container" @mouseover="selected = 'yes'" @mouseleave="selected = ''">
                            <BaseButton
                                title='Yes'
                                :background='modalColors.bg'
                                :opacity='selected != "yes" ? "0.7" : "1"'
                                :color='modalColors.color'
                                height='36px'
                                width='147px'
                                @clicked='$emit("confirmed")'
                                :loading="loading"
                            />
                        </div>
                           <BaseLoader :showLoader="loading" :centered="true" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .confirm-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        .confirm-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .confirm-container {
                background: #fff;
                width: 520.64px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                position: relative;
                font-size: 14px;
                .confirm-head {
                    border-bottom: 1px solid #0DABD8;
                    font-size: 20px;
                }
                .confirm-body {
                    .body-text {
                        font-size: 15px;
                    }
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'ConfirmModal',
    data: () => ({
        selected: 'yes',
        modalColors: {
                bg: '',
            color: '',
            loading:false,
        }
    }),
    props: [
        'show',
        'title',
        'text',
        'subText',
        'type',
    ],
    mounted() {
        this.getTypeColor();
    },
    methods: {
        confirmYes(){
         this.loading=true;
        },
        getTypeColor() {
            const type = this.$props.type;
            const button = {
                bg: '',
                color: '#fff',
            };
            switch (type) {
                case 'delete':
                    button.bg = '#C1420A';
                break;
                default:
                    button.bg = '#07A2D0';
                break;
            }
            this.modalColors = button;
        }
    }
}
</script>