<template>
    <div class="subscription-billing">
        <div class="subscription-billing-content">
            <div class="subscription-billing-header mb-5">
                <div class="header-details p-5 helvetica-medium base-divider">
                    <div class="billing-header">Billing Information</div>
                    <div class="billing-description small-text">
                        View and update your billing contact address and payment method.
                    </div>
                </div>
                <div class="header-customer-details p-5">
                    <div class="billing-customer-name helvetica-medium">
                        {{ subscriptionData.name }}
                    </div>
                    <div class="customer-extra-detail">
                        {{ subscriptionData.email }}
                    </div>
                    <div
                        class="customer-extra-detail"
                        v-if="subscriptionData && subscriptionData.settings">
                        <span v-if='subscriptionData.settings.city'>
                            {{ subscriptionData.settings.city }}
                        </span>
                        <span v-if='subscriptionData.settings.state'>
                            , {{ subscriptionData.settings.state }}
                        </span>
                        <span v-if='subscriptionData.settings.country'>
                            , {{ subscriptionData.settings.country }}
                        </span>
                    </div>
                    <div class="base-links mt-5 cursor-pointer helvetica-medium" @click="showBillingInfoModal = true">Edit Billing Contact Info</div>
                </div>
            </div>
            <div class="subscription-billing-body">
                <payment-info-modal
                    :show='showBillingInfoModal'
                    :subscriptionData='subscriptionData'
                    @close='showBillingInfoModal = false'
                />
            </div>
        </div>
    </div>    
</template>

<style lang="less" scoped>
    .subscription-billing {
        background: #fff;
        border-radius: 7px;
        .subscription-billing-content {
            .subscription-billing-header {
                .header-details {
                    .billing-header {
                        font-size: 20px;
                    }
                }
                .header-customer-details {
                    font-size: 16px;
                }
            }
        }
    }
</style>

<script>
import PaymentInfoModal from './Modals/PaymentInfoModal';
export default {
    name: 'SubscriptionBilling',
    props: ['subscriptionData'],
    data: () => ({
        showBillingInfoModal: false,
    }),
    components: {
        PaymentInfoModal,
    },
}
</script>