<template>
    <div class="video_container">
    </div>
</template>

<script>
export default {
created: function () {
    document.body.style.backgroundColor = "#1E3941";
  },
  destroyed: function () {
    document.body.style.backgroundColor = null;
  },

}
</script>
    
<style scoped>
html.body {
  background: #21455E !important;
}
.video_container {
    width: 947px;
    height: 699px;
    background: white;
    margin: auto;
    margin-top: 1.5%;
    border-radius: 20px;
}
</style>