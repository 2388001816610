<template>
    <div class="interactions-menu-container">
        <interaction-menu-item
            v-for='(item, index) in menuItems'
            :key='index'
            :header='item.header'
            :subHeader='item.subHeader'
            :icon='item.icon'
            :name='item.name'
            class='mb-3'
            @section='$emit("section", item.name)'
        />
    </div>
</template>

<style lang="less" scoped>
    .interactions-menu-container {
        width: 410px;
    }
    @media (max-width: 960px) {
        .interactions-menu-container {
            width: 100%;
        }
    }
</style>

<script>
import InteractionMenuItem from './InteractionMenuItem';
import { InteractionMenuItems } from '../../../assets/js/videoInteractions';

export default {
    name: 'InteractionsMenu',
    data: () => ({
        menuItems: InteractionMenuItems,
    }),
    components: {
        InteractionMenuItem,
    },
}
</script>