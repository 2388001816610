<template>
    <div class="viewer-response-container">
        <div
            class="response-header-section d-flex justify-content-between poppins-medium"
        >
            <div class="title">Configure viewer’s response</div>
            <div
                class="back-btn base-links cursor-pointer"
                @click="$emit('section', '')"
            >
                Change Interaction
            </div>
        </div>
        <div class="response-triggers mt-4">
            <switch-with-label
                v-for="(element, index) in togglesElements"
                :key="index"
                :toggle="toggles[element.toggle]"
                :label="element.label"
                @clicked="handleToggles(element.toggle)"
                class="my-2"
            />
        </div>
        <div class="response-settings">
            <input-with-label
                v-for="(element, index) in inputElements"
                :key="index"
                :headLabel="element.headLabel"
                :subLabel="element.subLabel"
                :class="element.class"
                :inputValue="getDataVar(element.input)"
                @value="handleInputs(element.input, $event)"
                :inputType="element.type"
            />
            <switch-with-label
                class="collect-data-toggle"
                :toggle="toggles.collect_data"
                label="Collect data on submission"
                @clicked="handleToggles('collect_data')"
            />
        </div>
    </div>
</template>

<style lang="less">
.viewer-response-container {
    .response-triggers {
        width: 140px;
        .switch-label-trigger-text {
            font-size: 18px;
        }
    }
    .response-settings {
        .collect-data-toggle {
            width: 224px;
            .switch-label-trigger-text {
                padding-right: 0px !important;
                font-size: 12px;
            }
        }
    }
}
</style>

<script>
import SwitchWithLabel from "../../../Common/SwitchWithLabel";
import InputWithLabel from "../InputWithLabel";

export default {
    name: "ViewerResponse",
    props: ["interactionsData"],
    components: {
        SwitchWithLabel,
        InputWithLabel,
    },
    mounted() {
        this.populateInteractionsData();
    },
    data: () => ({
        toggles: {
            video: false,
            audio: false,
            text: false,
            collect_data: false,
        },
        max_audio_video_length: 3,
        delay_interaction_by: 15,
        togglesElements: [
            { toggle: "video", label: "Video" },
            { toggle: "audio", label: "Audio" },
            { toggle: "text", label: "Text" },
        ],
        inputElements: [
            {
                input: "max_audio_video_length",
                headLabel: "Max video/audio length",
                subLabel: "minutes",
                class: "mt-4 mb-3",
                type: "number",
            },
            {
                input: "delay_interaction_by",
                headLabel: "Delay interaction by",
                subLabel: "seconds",
                class: "mb-4",
                type: "number",
            },
        ],
    }),
    methods: {
        handleToggles(toggle) {
            this.toggles[toggle] = !this.toggles[toggle];
        },
        handleInputs(toggle, value) {
            if (value > 3) {
                value = 3;
            }
            this[toggle] = parseInt(value);
        },
        getDataVar(variable) {
            return this[variable];
        },
        populateInteractionsData() {
            const interactionsData = this.$props.interactionsData;

            if (interactionsData) {
                const toggles = this.toggles;
                toggles.video = interactionsData.video;
                toggles.audio = interactionsData.audio;
                toggles.text = interactionsData.text;
                toggles.collect_data = interactionsData.collect_data;
                this.toggles = toggles;

                this.max_audio_video_length =
                    interactionsData.max_audio_video_length;
                this.delay_interaction_by =
                    interactionsData.delay_interaction_by;
            }
        },
    },
    watch: {
        $data: {
            handler(data) {
                let {
                    toggles,
                    max_audio_video_length,
                    delay_interaction_by,
                } = data;
                const { audio, video, text, collect_data } = toggles;
                max_audio_video_length =
                    max_audio_video_length > 3 ? 3 : max_audio_video_length;
                this.$emit("data", {
                    audio,
                    video,
                    text,
                    collect_data,
                    max_audio_video_length,
                    delay_interaction_by,
                });
            },
            deep: true,
        },
        interactionsData: {
            handler() {
                this.populateInteractionsData();
            },
            deep: true,
        },
    },
};
</script>
