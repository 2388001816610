<template>
    <transition name='fade'>
        <div class="video-stream">
            <video ref='stream' v-if='stream'></video>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .video-stream {
        video {
            position: absolute;
            left: 0;
            top: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            // object-fit: cover;
            background: black;
        }
    }
</style>

<script>
export default {
    name: 'VideoStream',
    props: ['stream'],
    mounted() {
        this.showStream();
    },
    methods: {
        showStream(stream = null) {
            stream = stream || this.$props.stream;
            const video = this.$refs.stream;
            if (video && stream) {
                video.srcObject = stream;
                video.muted = true;
                video.play();
                // this.getScreenshot();
            }
        },
        // getScreenshot() {
        //     const video = document.querySelector('video');
        //     const canvas = document.createElement('canvas');
        //     console.log({video, canvas});
        //     if (!video || !canvas) return false;

        //     const ctx = canvas.getContext('2d');
        //     const width = window.innerWidth;
        //     const height = window.innerHeight;
        //     ctx.drawImage(video, 0, 0, width, height);
            
        //     const imageBlob = canvas.toDataURL('image/jpeg');
        //     console.log(imageBlob);
        //     this.$emit('image', imageBlob);
        // }
    },
};
</script>