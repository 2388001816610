<template>
    <div class="color-picker d-flex justify-content-between">
        <input
            class="color-picker-input"
            ref='colorPickerInput'
            type="color"
            @change="updateColorBySelector"
            :value="background"
            @input="updateColorBySelector"
        />
        <div
            class="color-preview"
            :style='{ background }'>
        </div>
        <input
            type="text"
            v-model="background"
            class="color-code-input"
            @input='updateColor'
            @click='openSelector'
        />
    </div>
</template>

<style lang="less">
    .color-picker {
        .color-picker-input {
            position: absolute;
            visibility: hidden;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .color-code-input {
            width: 50%;
            border: none;
            text-align: center;
            outline: none;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .color-preview {
            width: 50%;
            height: 100%;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }
</style>

<script>
export default {
    name: 'ColorPicker',
    props: ['color'],
    data: () => ({
        background: '',
    }),
    mounted() {
        this.getColor();
    },
    methods: {
        getColor() {
            const color = this.$props.color;
            if(color){
                const colorPicker = this.$refs.colorPickerInput;
                colorPicker.value = color;
                this.background = color;
                colorPicker.addEventListener('input', this.updateColorBySelector)
            }
        },
        updateColor() {
            this.$emit('color', this.background);
        },
        updateColorBySelector(event) {
            const color = event.target.value;
            this.background = color;
            this.updateColor();
        },
        openSelector() {
            const colorPicker = this.$refs.colorPickerInput;
            colorPicker.click();
        }
    },
    watch: {
        color: {
            handler(){
                this.getColor();
            },
            deep: true,
        }
    }
};
</script>