var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timer-container poppins-bold",class:_vm.mobileElement ? 'mobile-timer' : 'desktop-timer',style:({
        width: _vm.width,
        height: _vm.height,
        fontSize: _vm.fontSize,
        fontFamily: _vm.fontFamily,
        padding: _vm.padding,
        borderRadius: _vm.borderRadius,
    })},[_c('div',{staticClass:"digits d-flex"},[(_vm.hours)?_c('div',{staticClass:"hours d-flex"},[_c('div',{staticClass:"digit"},[_vm._v(" "+_vm._s(_vm.leadingZero(_vm.clock.hours) || '00')+" ")]),_c('div',{staticClass:"divider"},[_vm._v(" : ")])]):_vm._e(),_c('div',{staticClass:"minutes d-flex"},[_c('div',{staticClass:"digit"},[_vm._v(" "+_vm._s(_vm.leadingZero(_vm.clock.minutes) || '00')+" ")]),_c('div',{staticClass:"divider"},[_vm._v(" : ")])]),_c('div',{staticClass:"seconds"},[_vm._v(" "+_vm._s(_vm.leadingZero(_vm.clock.seconds) || '00')+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }