<template>
    <div class="profile-personal-details">
        <div class="outer-header mb-4">PROFILE INFORMATION</div>
        <div class="profile-personal-inputs">
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="first_name">First Name</label>
                    <BaseInput
                        v-if='userData'
                        :inputValue='userData.first_name'
                        inputId='first_name'
                        placeholder='Enter First Name'
                        :error='errors && errors.first_name ? errors.first_name : false'
                        @value='updateInput("first_name", $event)'
                    />
                </div>
                <div class="flex-column form-input">
                    <label class="base-label" for="last_name">Last Name</label>
                    <BaseInput
                        v-if='userData'
                        :inputValue='userData.last_name'
                        placeholder='Enter Last Name'
                        inputId='last_name'
                        :error='errors && errors.last_name ? errors.last_name : false'
                        @value='updateInput("last_name", $event)'
                    />
                </div>
            </div>
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="first_name">Email Address</label>
                    <div class="email-input position-relative">
                        <BaseInput
                            v-if='userData'
                            :inputValue='userData.email'
                            placeholder='Enter Email Address'
                            inputId='email'
                            :disabled='true'
                            @value='updateInput("email", $event)'
                        />
                        <div class="change-email" @click='showEmailConfirmModal = true'>Change</div>
                    </div>
                </div>
                <div class="flex-column form-input d-flex justify-content-end login-details" v-if='user'>
                    <div class="d-flex justify-content-between">
                        <div>Last Login:</div>
                        <div>{{ user.last_login }}</div>
                    </div>
                    <div class="d-flex justify-content-between pt-2">
                        <div>Member Since:</div>
                        <div>{{ user.created_at }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modals">
            <email-confirm-modal
                :show='showEmailConfirmModal'
                :userId='user.id'
                @close='showEmailConfirmModal = false'
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .profile-personal-details {
        .outer-header {
            font-weight: 700;
            font-size: 13px;
        }
        .profile-personal-inputs {
            .form-group {
                .login-details {
                    font-size: 13px;
                    font-weight: 501;
                }
                .form-input {
                    width: 45%;
                    position: relative;
                    .email-input {
                        .change-email {
                            display: none;
                            position: absolute;
                            right: 10px;
                            top: 5px;
                            margin: auto;
                            cursor: pointer;
                            display: none;
                            color: #26b2ee;
                        }
                        &:hover {
                            .change-email {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 960px) {
        .profile-personal-details {
            .profile-personal-inputs {
                .form-group {
                    flex-direction: column;
                    .form-input {
                        width: 100%;
                    }
                    .login-details {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
</style>

<script>
import EmailConfirmModal from '../../Common/Modals/EmailConfirmModal';

export default {
    name: 'ProfilePersonal',
    props: ['user', 'errors'],
    components: {
        EmailConfirmModal,
    },
    data: () => ({
        userData: null,
        showEmailConfirmModal: false,
    }),
    mounted() {
        this.getUserDetails();
    },
    methods: {
        getUserDetails() {
            this.userData = this.$props.user;
        },
        updateInput(column, value) {
            const userData = this.userData;
            if (userData) {
                userData[column] = value;
            }
            this.$emit('user', userData);
            this.userData = userData;
        }
    }
}
</script>