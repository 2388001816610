<template>
    <transition name="fade">
        <div
            @mouseleave="$emit('close')"
            class="select-dropdown"
            ref='selectDropdown'
            :style='{ top, left, border, width, height, borderRadius }'
            v-show='show'
        >
            <div class="options-container p-3">
                <div
                    class="option"
                    v-for="option in options"
                    :key='customId ? option[customId] : option.id'
                    @click='$emit("selected", option)'
                    :style='{ color: option.color ? option.color : "auto" }'
                >
                    <span v-if='customLabel'>
                        {{ option[customLabel] }}
                    </span>
                    <span v-else>
                        {{ option.label }}
                    </span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'SelectDropdown',
    props: [
        'show',
        'options',
        'top',
        'left',
        'border',
        'width',
        'height',
        'borderRadius',
        'customId',
        'customLabel',
        'placement',
    ],
    methods: {
        adjustPlacement() {
            const placement = this.$props.placement;
            const dropDown = this.$refs.selectDropdown;
            const attribute = placement === 'top' ? 'bottom' : 'top';
            const { top, left } = this.$props;
            if (dropDown && !top && !left) {
                dropDown.style[attribute] = '39px';
            }
        },
    },
    watch: {
        show(show) {
            if (show) {
                this.adjustPlacement();
            }
        }
    }
};
</script>

<style lang="less" scoped>
    .select-dropdown {
        position: absolute;
        width: 100%;
        background: #fff;
        border: 1px solid #00ACDC;
        border-radius: 10px;
        z-index: 4;
        max-height: 400px;
        overflow: auto;
        .options-container {
            .option {
                padding: 10px 0;
                cursor: pointer;
                &:hover {
                    color: #00ACDC;
                }
            }
        }
    }
</style>