<template>
    <aside class="settings-container">
        <div class="sections d-flex">
            <div class="section side-bar-section desktop-element">
                <side-bar @section='switchSection'/>
            </div>
            <div class="section body-section pl-5 pt-5">
                <mobile-settings-navbar
                    class="mobile-navbar pb-4"
                    @section='switchSection'
                />
                <span v-if='section'>
                    <account-settings v-if='section === "account"' />
                    <integration-settings v-if='section === "integrations"' />
                    <subscription-settings v-if='section === "subscription"' @updateSection='section = $event'/>
                    <invoice v-if='section === "invoice"' @section='section = "subscription"'/>
                </span>
            </div>
        </div>
    </aside>
</template>

<style lang="less">
    .settings-container {
        background: #F5F8FA;
        .sections {
            .side-bar-section {
                width: max-content;
                height: 100vw;
            }
            .body-section {
                width: 70%;
                .mobile-navbar {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 960px) {
        .settings-container {
            padding-top: 40px;
            .sections {
                .body-section {
                    width: 95%;
                    padding: 0px !important;
                    position: relative;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                .mobile-navbar {
                    display: flex !important;
                    justify-content: flex-end;
                }
            }
        }
    }
</style>

<script>
import SideBar from '../components/Settings/SideBar';
import AccountSettings from '../components/Settings/Sections/AccountSettings';
import MobileSettingsNavbar from '../components/Settings/Modules/MobileSettingsNavbar';
import IntegrationSettings from '../components/Settings/Sections/IntegrationSettings';
import SubscriptionSettings from '../components/Settings/Sections/SubscriptionSettings';
import Invoice from '../components/Settings/Sections/Invoice';

export default {
    name: 'Settings',
    components: {
        SideBar,
        AccountSettings,
        MobileSettingsNavbar,
        IntegrationSettings,
        SubscriptionSettings,
        Invoice,
    },
    data: () => ({
        section: null,
    }),
    mounted() {
        this.setSection();
    },
    methods: {
        switchSection(section) {
            this.$router.push({
                path: `/settings/${section}`
            })
            this.section = section;
        },
        setSection() {
            const route = this.$route;
            if (route) {
                if (route.params) {
                    if (route.params.section) {
                        this.section = route.params.section;
                    } else {
                        this.section = 'account';
                    }
                }
            }
        }
    }
};
</script>