<template>
    <div class="dropdown-container">
        <div class="menu-icon flex-column nav-link" v-if='!showDropdown' @click="showDropdown = true">
            <div class="line mt-2"></div>
            <div class="line mt-2"></div>
            <div class="line mt-2"></div>
        </div>
        <div v-else class="menu-icon" @click="showDropdown = false">
            <BaseIcons icon='crossWhite' />
        </div>
        <div class="links flex-column dropdown" v-if='showDropdown'>
            <div
                class="link-container"
                v-for="(item, index) in items"
                :key='index'
                @click="showDropdown = false">
                <a :href="item.path" class='link' v-if='item.direct_link' target="_blank">
                    {{ item.name }}
                </a>
                <router-link v-else :to='item.path' class='link'>
                    {{ item.name }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .dropdown-container {
        position: relative;
        width: 100%;
        .menu-icon {
            cursor: pointer;
            transition: 0.3;
            .line {
                width: 20px;
                border: 1px solid #fff;
            }
        }
        .dropdown {
            position: absolute;
            padding: 20px 30px;
            background: #011a22;
            width: 100vw;
            opacity: 0.98;
            left: -15px;
            top: 55px;
            transition: 0.3s;
            animation-name: appear;
            animation-iteration-count: 1;
            animation-duration: 0.3s;
            z-index: 5;
            .link-container {
                padding: 15px 0;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                &:hover {
                    border-bottom: 2px solid #0BACDB;
                }
                .link {
                    color: #c7c9c9;
                    text-decoration: none;
                    transition: 0.3s;
                    &:hover {
                        color: #f9fbfb;
                    }
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'LinksDropdown',
    props: ['items'],
    data: () => ({
        showDropdown: false,
    })
}
</script>