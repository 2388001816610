<template>
    <div class="resource-usage">
        <div class="resource-title helvetica-bold mb-3">{{ title }}</div>
        <div class="d-flex resource-data position-relative">
            <div class="resource-descriptions">
                <div
                    class="resource-description helvetica-medium mb-3"
                    v-for="(description, index) in descriptions"
                    :key='index'
                >
                    {{ description }}
                </div>
            </div>
            <div class="align-self-end resource-value helvetica-medium position-absolute m-auto pb-3">
                {{ convertDataType(values.used) }} / {{ convertDataType(values.available) }}
            </div>
        </div>
        <progress-meter :loading='loading' :percentage='percentage' class="mt-2"/>
    </div>
</template>

<style lang="less" scoped>
    .resource-usage {
        font-size: 13px;
        .resource-value {
            min-width: max-content;
            right: 0;
        }
    }

    @media (max-width: 960px) {
        .resource-usage {
            .resource-data {
                flex-direction: column;
                .resource-value {
                    position: relative !important;
                }
            }
        }
    }
</style>

<script>
import ProgressMeter from '../../../Common/Widgets/ProgressMeter';

export default {
    name: 'ResourceUsage',
    props: [
        'loading',
        'title',
        'descriptions',
        'values',
        'type',
    ],
    data: () => ({
        percentage: 0,
    }),
    components: {
        ProgressMeter,
    },
    mounted() {
        this.getPercentage();
    },
    methods: {
        convertDataType(value) {
            const type = this.$props.type;
            if (type) {
                if (type === 'bytes') {
                    return this.convertBytes(value);
                }
                return value;
            }
            return value;
        },
        convertBytes(bytes) {
            if (typeof bytes !== 'number') {
                return bytes;
            }
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        getPercentage(data = null) {
            if (!data) {
                data = this.$props.values;
            }
            if (data && data.used && data.available) {
                if (typeof data.available === 'number') {
                    this.percentage = (100 * data.used) / data.available;
                } else {
                    this.percentage = 0.1;
                }
            }
        }
    },
    watch: {
        values: {
            handler(data) {
                this.getPercentage(data);
            },
            deep: true,
        }
    }
}
</script>