<template>
    <div class="video-url-player">
        <BaseInput
            placeholder="Enter video url"
            border="1px solid transparent"
            borderRadius="20px"
            class="url-search"
            :inputValue="url"
            keyTrigger="Enter"
            @value="url = $event"
            @keyPressed="handleVideoUrl"
        />

        <div class="description-texts pt-3">
            <div class="description-text poppins-light" v-if="type != 'mp4'">
                Only Adilo, Youtube and Vimeo videos are accept*
            </div>
            <div class="description-text poppins-light">
                Video must not exceed 5 minutes in length*
            </div>
        </div>
        <div
            class="url-video-container mt-4 position-relative"
            @click="playPause"
        >
            <BaseLoader :showLoader="loading" :centered="true" />
            <div class="dark-video" v-if="controls.darkVideo"></div>
            <video
                class="smooth-shadow"
                :src="videoUrl"
                ref="videoUrlPlayer"
                :style="{ objectFit: controls.mode }"
            />
            <div class="url-video-play-btn" v-if="!playing && videoUrl">
                <BaseIcons icon="playBigBW" />
            </div>
        </div>

        <!-- Messages -->
        <span v-if="message">
            <messages-box :messages="message" @closeMessages="message = ''" />
        </span>
    </div>
</template>

<style lang="less">
.video-url-player {
    .url-search {
        .form-control {
            color: #862d83 !important;
            font-family: "Poppins";
            padding-left: 2em;
            &::placeholder {
                color: #862d83;
            }
        }
    }
    .description-texts {
        .description-text {
            font-style: italic;
            font-size: 11px;
        }
    }
    .url-video-container {
        width: 100%;
        video {
            width: 100%;
            height: 345px;
            border-radius: 5px;
            background: black;
        }
        .url-video-play-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 83px;
            height: 83px;
            z-index: 20;
        }
        .dark-video {
            position: absolute;
            z-index: 19;
            background: #1e3941;
            left: 0;
            margin: auto;
            top: 0;
            bottom: 0;
            opacity: 40%;
            border-radius: 6px;
            width: 100%;
        }
    }
}
</style>

<script>
import MessagesBox from "../../components/Common/MessagesBox";
export default {
    name: "VideoUrlPlayer",
    components: {
        MessagesBox,
    },
    props: ["options", "type"],
    data: () => ({
        url: "",
        videoUrl: "",
        loading: false,
        playing: false,
        controls: {
            mode: "",
            darkVideo: false,
        },
        message: "",
    }),
    mounted() {
        this.setEndEvent();
    },
    methods: {
        async handleVideoUrl() {
            const campaignId = this.$route.params.id;
            const url = this.url;
            this.loading = true;

            this.axios
                .post("videos/downloadVideos", {
                    url: url,
                    campaign_id: campaignId,
                })
                .then((response) => {
                    console.log(response.data);
                    this.message = response.data.message;
                    this.videoUrl = response.data.fileName;
                    // TODO: Request to get video by url here
                    const video = {
                        id: 1,
                        path: this.videoUrl,
                    };

                    setTimeout(() => {
                        this.playPause();
                        this.$emit("selectedVideo", video);
                    }, 1000);
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        setEndEvent() {
            const player = this.$refs.videoUrlPlayer;
            player.onended = () => {
                this.playing = false;
            };
        },
        playPause() {
            if (this.videoUrl) {
                const player = this.$refs.videoUrlPlayer;
                if (player && this.videoUrl) {
                    if (player.paused) {
                        player.play();
                        this.playing = true;
                    } else {
                        player.pause();
                        this.playing = false;
                    }
                }
            }
        },
        handleOptions() {
            const options = this.$props.options;
            if (options) {
                const { switches } = options;
                this.controls.mode = switches.fitVideo ? "cover" : "contain";
                this.controls.darkVideo = switches.darkenVideo;
            }
        },
    },
    watch: {
        options: {
            handler() {
                this.handleOptions();
            },
            deep: true,
        },
    },
};
</script>
