<template>
    <transition name="fade">
        <div class="projects-create-modal" v-if='show'>
            <div class="projects-create-content">
                <div class="projects-create-container">
                    <div class="projects-create-head d-flex justify-content-between">
                        <div></div>
                        <div class="create-head-title">NEW PROJECT</div>
                        <div class="project-head-close" @click="$emit('close')">
                            <BaseIcons icon='modalCross' />
                        </div>
                    </div>
                    <div class="projects-create-body">
                        <div class="create-project-form">
                            <div class="form-group">
                                <label for="title" class="base-label">Title</label>
                                <BaseInput
                                    inputId='title'
                                    :inputValue='title'
                                    placeholder='Add a title'
                                    @value='title = $event'
                                    :error='errors.title'
                                    keyTrigger='Enter'
                                    @keyPressed='createProject'
                                />
                            </div>
                            <!-- <div class="form-group flex-column">
                                <label for="description" class="base-label">Description</label>
                                <div class="text-area-container">
                                    <textarea class="base-input" id="description" cols="30" rows="10" placeholder="Add a description"></textarea>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="projects-create-footer d-flex justify-content-center pt-3 pb-3">
                        <BaseButton
                            title='Cancel'
                            background='#707070'
                            color='#fff'
                            borderRadius='20px'
                            width='100px'
                            class="mr-2"
                            keyTrigger='Enter'
                            @clicked='$emit("close")'
                        />
                        <BaseButton
                            title='Create'
                            background='rgb(11, 172, 219) none repeat scroll 0% 0%'
                            color='#fff'
                            borderRadius='20px'
                            width='100px'
                            @clicked='createProject'
                            :loading='loading'
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .projects-create-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        font-family: "Helvetica Neue-Medium";
        z-index: 5;
        .projects-create-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .projects-create-container {
                width: 530px;
                height: max-content;
                background: #fff;
                border-radius: 10px;
                .projects-create-head {
                    padding: 15px;
                    border-bottom: 1px solid #ebf9fa;
                    .project-head-close {
                        cursor: pointer;
                    }
                }
                .projects-create-body {
                    border-bottom: 1px solid #ebf9fa;
                    .create-project-form {
                        padding: 30px 60px;
                        .form-group {
                            .text-area-container {
                                transition: 0.3s;
                                border-radius: 5px;
                                border: 1.5px solid transparent;
                                textarea {
                                    border: 1px solid #e2e5ed;
                                    width: 100%;
                                    height: 100%;
                                    height: 81px;
                                    padding: 15px;
                                    border-radius: 5px;
                                    resize: vertical;
                                    outline: none;
                                    &:hover {
                                        border: 1.5px solid #00ACDC;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 960px) {
        .projects-create-modal {
            .projects-create-content {
                .projects-create-container {
                    width: 95%;
                }
            }
        }
    }
</style>

<script>
import validator from 'validator';
export default {
    name: 'CreateProjectModal',
    props: ['show'],
    data: () => ({
        title: '',
        description: '',
        errors: {
            title: '',
            description: '',
        },
        loading: false,
    }),
    methods: {
        createProject() {
            const validated = this.validate();
            if (validated) {
                this.loading = true;
                // console.log(this.title);
                // Todo: save project
                // console.log("sghdfs");
                this.axios.post('/projects/create-project', {
                    title:this.title
                }).then(response => {
                    // console.log(response.data.error);
                    if(response.data.status == false){
                        this.errors.title = response.data.error;
                    }else{    
                        this.$emit('reload');
                        this.$emit('close');
                        this.title = '';
                    }
                    this.loading = false;
                }).catch(error => {
                    console.log(error);
                    this.loading = false;
                })
            }
        },
        validate() {
            const { title } = this;
            const errors = {};
            if (validator.isEmpty(title)) {
                errors.title = 'Please enter a title';
            }
            // console.log(errors);
            this.errors = errors;
            return !errors.title;
        }
    }
};
</script>