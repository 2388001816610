<template>
    <transition name="fade">
        <div class="register-container">
            <div class="register-content">
                <div class="register-body d-flex justify-content-between">
                    <div class="base-loader-line">
                        <div
                            v-if="loading"
                            class="base-loading-progress-line"
                        ></div>
                    </div>
                    <div class="register-form flex-column">
                        <big-command-logo :text="$companyName" />
                        <div class="description-text">
                            Create your {{ $companyName }} Account
                        </div>

                        <!-- updated code-->

                        <div
                            v-if="error"
                            class="alert alert-danger alert-dismissible fade show"
                        >
                            <!-- <button
                                type="button"
                                class="close"
                                data-dismiss="alert"
                            >
                                &times;
                            </button> -->
                            {{ error }}
                        </div>

                        <!-- end updated code -->

                        <div class="register-form-inputs">
                            <div class="form-group">
                                <div class="left-input w-50 pr-2">
                                    <label class="base-label" for="firstName"
                                        >First Name</label
                                    >
                                    <BaseInput
                                        placeholder="Enter First Name"
                                        className="firstName"
                                        type="text"
                                        :inputValue="form.first_name"
                                        inputId="firstName"
                                        @value="form.first_name = $event"
                                        :error="errors.first_name"
                                        keyTrigger="Enter"
                                        @keyPressed="register"
                                    />
                                </div>
                                <div class="right-input w-50 pl-2">
                                    <label class="base-label" for="lastName"
                                        >Last Name</label
                                    >
                                    <BaseInput
                                        placeholder="Enter Last Name"
                                        className="lastName"
                                        type="text"
                                        :inputValue="form.last_name"
                                        inputId="lastName"
                                        @value="form.last_name = $event"
                                        :error="errors.last_name"
                                        keyTrigger="Enter"
                                        @keyPressed="register"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="left-input w-100">
                                    <label class="base-label" for="email"
                                        >Email Address</label
                                    >
                                    <BaseInput
                                        placeholder="Enter Email"
                                        className="email"
                                        type="email"
                                        :inputValue="form.email"
                                        inputId="email"
                                        @value="form.email = $event"
                                        :error="errors.email"
                                        keyTrigger="Enter"
                                        @keyPressed="register"
                                    />
                                </div>
                            </div>
                            <div class="form-group mb-2">
                                <div class="left-input w-50 pr-2">
                                    <label class="base-label" for="password"
                                        >Password</label
                                    >
                                    <BaseInput
                                        placeholder="Enter Password"
                                        className="password"
                                        :type="
                                            passwordHidden ? 'password' : 'text'
                                        "
                                        :inputValue="form.password"
                                        inputId="password"
                                        :icon="
                                            passwordHidden ? 'eye' : 'eyeNoLine'
                                        "
                                        :mobileOnlyIcon="true"
                                        @iconClicked="
                                            passwordHidden = !passwordHidden
                                        "
                                        @value="form.password = $event"
                                        :error="errors.password"
                                        keyTrigger="Enter"
                                        @keyPressed="register"
                                    />
                                </div>
                                <div class="right-input w-50 pl-2">
                                    <label
                                        class="base-label"
                                        for="confirmPassword"
                                        >Confirm</label
                                    >
                                    <BaseInput
                                        placeholder="Confirm Password"
                                        className="password"
                                        :type="
                                            passwordHidden ? 'password' : 'text'
                                        "
                                        :inputValue="form.confirm_password"
                                        inputId="confirmPassword"
                                        @value="form.confirm_password = $event"
                                        :error="errors.confirm_password"
                                        keyTrigger="Enter"
                                        @keyPressed="register"
                                    />
                                </div>
                                <div class="eye-icon">
                                    <BaseIcons
                                        :icon="
                                            passwordHidden ? 'eye' : 'eyeNoLine'
                                        "
                                        @clicked="
                                            passwordHidden = !passwordHidden
                                        "
                                    />
                                </div>
                            </div>
                            <div class="password-description helvetica-medium">
                                Use 8 characters or more with a mix of letters,
                                numbers & symbols.
                            </div>
                        </div>
                        <div
                            class="register-buttons d-flex justify-content-between"
                        >
                            <router-link
                                to="/"
                                class="base-links login-page-link"
                            >
                                Sign in instead
                            </router-link>
                            <BaseButton
                                :loading="loading"
                                color="#fff"
                                background="#0DABD8"
                                title="Get Started"
                                borderRadius="20px"
                                width="145px"
                                height="36px"
                                padding="5px"
                                @clicked="register"
                            />
                        </div>
                    </div>
                    <div class="right-section">
                        <BaseIcons icon="registerUsers" />
                        <div class="page-description">
                            One account. All of {{ $companyName }} working for
                            you.
                        </div>
                    </div>
                </div>
                <div class="bottom-text">
                    <company-rights-text />
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
.register-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #1d2331;
    font-family: "Helvetica Neue";
    .register-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .register-body {
            background: #fff;
            width: 882.64px;
            border: 1px solid #c9edf8;
            border-radius: 10px;
            padding: 41px 48.5px 39.9px;
            position: relative;
            font-size: 14px;
            .register-form {
                width: 424px;
                .description-text {
                    font-family: "Helvetica Neue-Bold";
                    font-size: 24px;
                    text-align: left;
                    margin-top: 29px;
                }
                .register-form-inputs {
                    margin-top: 34px;
                    .form-group {
                        display: flex;
                        width: 100%;
                        margin: 34px 0;
                        justify-content: space-between;
                        position: relative;
                        .eye-icon {
                            position: absolute;
                            right: -40px;
                            top: 35px;
                            margin: auto;
                        }
                    }
                }
                .register-buttons {
                    margin-top: 35px;
                    .login-page-link {
                        font-size: 16px;
                        display: flex;
                        align-self: center;
                    }
                }
            }
            .right-section {
                position: relative;
                top: 0;
                bottom: 0;
                margin: auto;
                height: max-content;
                left: 0;
                right: 0;
                .page-description {
                    width: 230px;
                    margin-top: 15px;
                    font-size: 16px;
                    letter-spacing: 0;
                    font-family: "Helvetica Neue-Medium";
                    text-align: center;
                }
            }
        }
        .bottom-text {
            position: absolute;
            bottom: 15px;
            margin: auto;
        }
    }
}
@media (max-width: 960px) {
    .register-container {
        height: max-content;
        padding: 50px 10px;
        .register-content {
            .register-body {
                .register-form {
                    width: 100%;
                    .description-text {
                        font-size: 12px;
                    }
                    .register-form-inputs {
                        .form-group {
                            flex-direction: column;
                            margin: 0px 0px !important;
                            .eye-icon {
                                display: none;
                            }
                            .left-input,
                            .right-input {
                                width: 100% !important;
                                padding: 0px !important;
                                margin: 20px 0 !important;
                            }
                        }
                    }
                }
                .right-section {
                    display: none;
                }
            }
        }
    }
}
</style>

<script>
import BigCommandLogo from "../../components/Common/BigCommandLogo.vue";
import CompanyRightsText from "../../components/Common/CompanyRightsText.vue";
import validator from "validator";

export default {
    name: "Register",
    components: {
        BigCommandLogo,
        CompanyRightsText
    },
    data: () => ({
        loading: false,
        passwordHidden: true,
        onInputValidate: false,
        form: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirm_password: "",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        errors: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirm_password: ""
        },
        error: ""
    }),
    methods: {
        register() {
            const validated = this.validate();
            // console.log(validated);
            if (validated) {
                const form = this.form;
                // console.log(form);
                this.loading = true;
                this.axios
                    .post("auth/user/register", form)
                    .then(response => {
                        // console.log("hiii");
                        // console.log(response);
                        this.handleRegisterResponse(response.data);
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error.response.data.errors.email[0]);
                        // this.errors.email = error.response.data.errors.email[0];
                        // this.errors = error.response.data.errors.email[0];
                        this.loading = false;
                        this.error = error.response.data.errors.email[0];
                    });
            }
        },
        validate() {
            const { form } = this;
            const errors = {
                first_name: "",
                last_name: "",
                email: "",
                password: ""
            };

            this.errors = errors;

            // Email
            if (validator.isEmpty(form.email)) {
                errors.email = "Email is required";
            } else if (!validator.isEmail(form.email)) {
                errors.email = "Please enter a correct email";
            }

            // Name
            if (validator.isEmpty(form.first_name))
                errors.first_name = "First name is required";
            if (validator.isEmpty(form.last_name))
                errors.last_name = "Last name is required";

            // Password
            if (validator.isEmpty(form.password))
                errors.password = "Password is required";
            else if (!this.validatePassword()) {
                this.errors.password =
                    "Use 8 characters or more with a mix of letters, numbers & symbols.";
            }

            if (validator.isEmpty(form.confirm_password))
                errors.confirm_password = "Confirm password required";
            else if (!validator.equals(form.password, form.confirm_password))
                errors.password = "Passwords do not match";

            this.clearValidate();
            this.errors = errors;
            this.onInputValidate = true;
            return Object.values(errors).filter(str => str).length < 1;
        },
        validatePassword() {
            /* eslint-disable no-useless-escape */
            let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
            return re.test(String(this.form.password));
        },
        clearValidate() {
            this.errors.passwordInvalid = false;
        },
        handleRegisterResponse(data) {
            // console.log(data);
            if (data.success) {
                window.localStorage.setItem("token", data.access_token);
                window.localStorage.setItem(
                    "auth-user",
                    JSON.stringify(data.user_id)
                );
                this.$router.go("/projects");
                this.loading = false;
            }
        }
    },
    watch: {
        form: {
            handler() {
                if (this.onInputValidate) {
                    this.validate();
                }
            },
            deep: true
        }
    }
};
</script>
