<template>
    <div class="custom-selector">
        <div class="selectorButton d-flex cursor-pointer" @click="showDropDown = !showDropDown">
            <div class="pl-3">{{ selected || 'Select' }}</div>
            <BaseIcons icon='downArrow' class="pr-3" />
        </div>
        <select-dropdown
            :options='list'
            :show='showDropDown'
            :customId='listId'
            :customLabel='listLabel'
            @selected='selectedOption'
            border='1px solid #ced4da'
            placement='top'
            @close='showDropDown = false'
        />
    </div>
</template>

<style lang="less" scoped>
    .custom-selector {
        select {
            width: 100%;
            option {
                width: 100%;
                opacity: 0.4;
            }
        }
        .selectorButton {
            height: 39px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #ced4da;
            border-radius: 5px;
        }
    }
</style>

<script>

import SelectDropdown from '../Common/SelectDropdown';

export default {
    name: 'CustomSelector',
    props: [
        'selected',
        'list',
        'listId',
        'listLabel',
    ],
    data: () => ({
        showDropDown: false,
    }),
    components: {
        SelectDropdown,
    },
    mounted() {
        this.emitSelectedOption();
    },
    methods: {
        selectedOption(option) {
            if (option) {
                this.$emit('option', option);
            }
            this.showDropDown = false;
        },
        emitSelectedOption() {
            const list = this.$props.list;
            if (list) {
                const id = this.$props.listId;
                const selectedOption = this.$props.selected;
                const selectedListData = list.filter(elem => elem[id] === selectedOption);
                if (selectedListData.length) {
                    this.selectedOption(selectedListData[0]);
                }

            }
        }
    }
}
</script>