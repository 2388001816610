<template>
	<div class="ecommerce-card-container">
		<div
			class="response-header-section d-flex justify-content-between poppins-medium"
		>
			<div class="title">Configure eCommerce card</div>
			<div
				class="back-btn base-links cursor-pointer"
				@click="$emit('section', '')"
			>
				Change Interaction
			</div>
		</div>
		<div class="ecommerce-card mt-3">
			<label class="">Headline</label>
			<BaseInput
				border="1px solid #ccc"
				borderRadius="8px"
				class="headline-inpt mb-3"
				:inputValue="e_comm_headline"
				keyTrigger="Enter"
				@value="e_comm_headline = $event"
			/>
			<label class="">Sub-headline</label>
			<BaseInput
				border="1px solid #ccc"
				borderRadius="8px"
				class="sub-headline-inpt"
				:inputValue="e_comm_sub_headline"
				keyTrigger="Enter"
				@value="e_comm_sub_headline = $event"
			/>
			<div class="card-div mt-4">
				<div
					class="card-form"
					v-for="(cardOptn, index) in cardOptions"
					:key="index"
				>
					<h4>Card {{ index + 1 }}</h4>
					<div class="form-group d-flex">
						<label class="col-md-4">Product name</label>
						<BaseInput
							border="1px solid #ccc"
							borderRadius="8px"
							class="card-input col-md-8"
							type="text"
							:inputValue="cardOptn.product_name"
							keyTrigger="Enter"
							@value="cardOptn.product_name = $event"
						/>
					</div>
					<div class="form-group d-flex">
						<label class="col-md-4">Regular price</label>
						<BaseInput
							border="1px solid #ccc"
							borderRadius="8px"
							class="card-input col-md-8"
							type="text"
							:inputValue="cardOptn.regular_price"
							keyTrigger="Enter"
							@value="cardOptn.regular_price = $event"
						/>
					</div>
					<div class="form-group d-flex">
						<label class="col-md-4">Discounted price</label>
						<BaseInput
							border="1px solid #ccc"
							borderRadius="8px"
							class="card-input col-md-8"
							type="text"
							:inputValue="cardOptn.discounted_price"
							keyTrigger="Enter"
							@value="cardOptn.discounted_price = $event"
						/>
					</div>
					<div class="form-group d-flex">
						<label class="col-md-4">Coupon code</label>
						<BaseInput
							border="1px solid #ccc"
							borderRadius="8px"
							class="card-input col-md-8"
							type="text"
							:inputValue="cardOptn.coupon_code"
							keyTrigger="Enter"
							@value="cardOptn.coupon_code = $event"
						/>
					</div>
					<div class="form-group d-flex">
						<label class="col-md-4">Button text</label>
						<BaseInput
							border="1px solid #ccc"
							borderRadius="8px"
							class="card-input col-md-8"
							type="text"
							:inputValue="cardOptn.btn_text"
							keyTrigger="Enter"
							@value="cardOptn.btn_text = $event"
						/>
					</div>
					<div class="form-group d-flex">
						<label class="col-md-4">Button URL</label>
						<BaseInput
							border="1px solid #ccc"
							borderRadius="8px"
							class="card-input col-md-8"
							type="text"
							:inputValue="cardOptn.btn_url"
							keyTrigger="Enter"
							@value="cardOptn.btn_url = $event"
						/>
					</div>
					<div class="form-group d-flex mt-4">
						<input
							type="file"
							:id="index"
							hidden
							@change="onFileChange($event, cardOptn)"
						/>
						<label :for="index" class="col-md-5 upload-btn"
							>Upload card image
						</label>
						<p class="col-md-7 upload-txt">
							(Best is 250*250px, jpg or png image)
						</p>
					</div>
				</div>
				<div class="add-more-optn mt-4">
					<BaseIcons
						:icon="'iconsPlusGrey'"
						class="device-icon"
						v-if="cardOptions.length < 6"
						@clicked="add()"
					/>
					<span v-if="cardOptions.length < 6">Add new card</span>
				</div>
			</div>
			<div class="response-settings">
				<input-with-label
					v-for="(element, index) in inputElements"
					:key="index"
					:headLabel="element.headLabel"
					:subLabel="element.subLabel"
					:class="element.class"
					:inputValue="getDataVar(element.input)"
					@value="handleInputs(element.input, $event)"
					:inputType="element.type"
				/>
				<switch-with-label
					class="collect-data-toggle"
					:toggle="toggles.collect_data"
					label="Collect data on submission"
					@clicked="handleToggles('collect_data')"
				/>
			</div>
		</div>

		<!-- Messages -->
		<span v-if="message">
			<messages-box :messages="message" @closeMessages="message = ''" />
		</span>
	</div>
</template>

<style lang="less">
.ecommerce-card-container {
	.ecommerce-card {
		.card-div {
			.card-form {
				h4 {
					font-family: Poppins;
					font-size: 14px;
					font-weight: 600;
					text-transform: uppercase;
				}
				label {
					align-self: center;
					font-family: Poppins;
					font-size: 14px;
				}
				.card-input {
					padding-left: 0px;
					padding-right: 0px;
				}
				.upload-btn {
					background-color: #862d83;
					color: #fff;
					padding: 12px 15px;
					font-family: Poppins Medium;
					border-radius: 8px;
					cursor: pointer;
					text-align: center;
					font-size: 16px;
				}
				.upload-txt {
					align-self: center;
					margin-bottom: 6px;
				}
			}
			.add-more-optn {
				color: #1e39419a;
				font-size: 15px;
				font-weight: 600;
				.device-icon {
					display: inline-flex;
					margin-right: 15px;
				}
			}
		}
		.response-settings {
			.collect-data-toggle {
				width: 224px;
				.switch-label-trigger-text {
					padding-right: 0px !important;
					font-size: 12px;
				}
			}
		}
	}
}
</style>

<script>
import SwitchWithLabel from "../../../Common/SwitchWithLabel";
import InputWithLabel from "../InputWithLabel";
import MessagesBox from "../../../../components/Common/MessagesBox";

export default {
	name: "EcommerceCard",
	props: ["interactionsData"],
	components: {
		SwitchWithLabel,
		InputWithLabel,
		MessagesBox,
	},
	data: () => ({
		e_comm_headline: "",
		e_comm_sub_headline: "",
		cardOptions: [
			{
				product_name: "",
				regular_price: "",
				discounted_price: "",
				coupon_code: "",
				btn_text: "",
				btn_url: "",
				file: "",
				image: null,
			},
		],
		cardInputOptions: "",
		delay_interaction_by: 15,
		toggles: {
			collect_data: false,
		},
		inputElements: [
			{
				input: "delay_interaction_by",
				headLabel: "Delay interaction by",
				subLabel: "seconds",
				class: "mb-4",
				type: "number",
			},
		],
		message: "",
	}),
	mounted() {
		this.populateInteractionsData();
	},
	methods: {
		handleToggles(toggle) {
			this.toggles[toggle] = !this.toggles[toggle];
		},
		handleInputs(toggle, value) {
			if (value > 3) {
				value = 3;
			}
			this[toggle] = parseInt(value);
		},
		getDataVar(variable) {
			return this[variable];
		},
		add() {
			this.cardOptions.push({
				product_name: "",
				regular_price: "",
				discounted_price: "",
				coupon_code: "",
				btn_text: "",
				btn_url: "",
				file: "",
				image: null,
			});
		},
		onFileChange(event, cardOptn) {
			var file = event.target.files[0];

			if (
				file["type"] === "image/jpg" ||
				file["type"] === "image/png" ||
				file["type"] === "image/jpeg"
			) {
				let reader = new FileReader();

				reader.readAsDataURL(event.target.files[0]);
				reader.onload = (evt) => {
					let img = new Image();
					img.onload = () => {
						if (img.width === 250 && img.height === 250) {
							cardOptn.image = URL.createObjectURL(file);
							cardOptn.file = event.target.files[0];
							const randomId = this.makeImageId(5);
							const extension = ".png";
							const fileName = `${randomId}${extension}`;
							cardOptn.file = new File(
								[cardOptn.file],
								fileName,
								{
									type: file["type"],
								}
							);
						} else {
							this.message =
								"Please upload only 250*250px of image in width and height";
						}
					};
					img.src = evt.target.result;
				};
			} else {
				this.message = "Only upload jpg or png images";
			}
		},
		populateInteractionsData() {
			const interactionsData = this.$props.interactionsData;

			if (interactionsData) {
				const toggles = this.toggles;
				toggles.collect_data = interactionsData.collect_data;
				this.toggles = toggles;
				this.cardInputOptions = interactionsData.cardOptions;
				this.e_comm_headline = interactionsData.e_comm_headline;
				this.e_comm_sub_headline = interactionsData.e_comm_sub_headline;
				this.delay_interaction_by =
					interactionsData.delay_interaction_by;
			}
		},
		makeImageId(length) {
			var result = "";
			var characters =
				"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			var charactersLength = characters.length;
			for (var i = 0; i < length; i++) {
				result += characters.charAt(
					Math.floor(Math.random() * charactersLength)
				);
			}
			return result;
		},
	},
	watch: {
		$data: {
			handler(data) {
				let {
					e_comm_headline,
					e_comm_sub_headline,
					cardOptions,
					toggles,
					delay_interaction_by,
				} = data;
				const { collect_data } = toggles;
				this.$emit("data", {
					e_comm_headline,
					e_comm_sub_headline,
					cardOptions,
					collect_data,
					delay_interaction_by,
				});
			},
			deep: true,
		},
		interactionsData: {
			handler() {
				this.populateInteractionsData();
			},
			deep: true,
		},
	},
};
</script>
