<template>
    <div class="subscription-status pb-4">
        <div class="status-header d-flex align-items-center">
            <div class="header-title pl-5 pt-2 pb-2 helvetica-bold">
                STATUS
            </div>
        </div>
        
        <div class="status-body pl-5 pt-4 pb-2">
            <BaseLoader :showLoader='loading' />
            <transition name='slide-fade'>
                <div class="status-body-data d-flex justify-content-between" v-if='!loading'>
                    <div class="subscription-messages d-flex">
                        <BaseIcons v-if='roundedIcon' :icon='roundedIcon' />
                        <div class="pl-4 status-messages d-flex align-items-center helvetica-medium">
                            <div class="status-message default-yellow-text" v-if='!active && !expired'>
                                Your Account requires immediate attention.
                            </div>
                            <div class=" default-green-text" v-if='active && isTrial'>
                                <div class="default-green-text status-message">
                                    Your subscription is Active
                                </div>
                                <div class="status-message">
                                    Your 7-day Free Trial will expire in {{ remainingDays() }} days.
                                </div>
                            </div>
                            <div v-if='active && !isTrial'>
                                <div class="default-green-text status-message">
                                    Your subscription is Active
                                </div>
                                <div class="status-message" v-if='!freePlan'>
                                    Your subscription was charged on <b>{{ lastChargeDate }}</b>.
                                </div>
                                <div class="status-message" v-if='nextChargeDate && !freePlan'>
                                    You will be billed on <b>{{ nextChargeDate }}</b>.
                                </div>
                            </div>
                            <div class="default-red-text status-message" v-if='expired'>
                                Your subscription is expired.
                            </div>
                        </div>
                    </div>
                    <BaseButton
                        title='See Details'
                        class="d-flex align-self-end mr-5"
                        border="1px solid #0dabd8 !important"
                        color="#0dabd8"
                        hoverBg="#f5f6f9"
                        fontSize="16px"
                        width='150px'
                        borderRadius='20px'
                        fontFamily="Helvetica Neue-Medium"
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .subscription-status {
        .status-header {
            background: #0bacdb07;
            height: 48px;
            .header-title {
                font-size: 13px;
            }
        }
        .status-body {
            .status-body-data {
                .subscription-messages {
                    .status-messages {
                        font-size: 14px;
                        .status-message {
                            line-height: 2.5em;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 960px) {
        .subscription-status {
            .status-body-data {
                flex-direction: column;
            }
        }
    }
</style>

<script>
import moment from 'moment';
export default {
    name: 'SubscriptionStatus',
    props: ['loading', 'subscriptionData'],
    data: () => ({
        active: true,
        lastChargeDate: null,
        nextChargeDate: null,
        isTrial: false,
        expired: false,
        roundedIcon: null,
        freePlan: false,
    }),
    methods: {
        setStatus(data) {
            const status = data.billing_status;
            if (status) {
                this.active     = status === 'Active' || status === 'Trial';
                this.isTrial    = status === 'Trial';
                this.expired    = status === 'Expired';
            }
        },
        setDates(data) {
            this.lastChargeDate = data.last_charge_date;
            this.nextChargeDate = data.next_charge_date;
        },
        setIcon() {
            const { active }    = this;
            this.roundedIcon    = active ? 'roundedGreenCheck' : 'roundedRedWarning';
        },
        remainingDays() {
            const { nextChargeDate } = this;
            if (nextChargeDate) {
                return moment().diff(nextChargeDate, 'days');
            }
            return '0';
        },
        ifFreePlan(data) {
            this.freePlan = data.plan_data ? data.plan_data.free_plan : false;
        }
    },
    watch: {
        subscriptionData: {
            handler(data) {
                this.setStatus(data);
                this.setDates(data);
                this.ifFreePlan(data);
                this.setIcon();
            },
            deep: true,
        }
    }
}
</script>