<template>
    <button
        class="square-btn default-purple btn d-flex justify-content-center flex-column"
        :style="{ background, color }"
    >
        <span class="square-btn-icon d-flex justify-content-center" v-if="icon">
            <BaseIcons :icon='icon' />
        </span>
        <span class="square-btn-text text-center poppins" v-if="text">
            {{ text }}
        </span>
    </button>
</template>

<style lang="less" scoped>
    .square-btn {
        width: 83px;
        height: 74px;
        color: #fff;
        border-radius: 10px;
        font-size: 10px;
        font-weight: 600;
        transition: 0.3s;
        &:hover {
            color: #fff;
        }
    }
</style>

<script>
export default {
    name: 'SquareButton',
    props: [
        'icon',
        'text',
        'background',
        'color',
    ],
}
</script>