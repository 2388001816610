<template>
    <div class="auth-header d-flex justify-content-between">
        <div class="left-section">
            <div class="auth-header-logo pl-3">
                <big-command-logo text='Account' :smallIcon='true' />
            </div>
        </div>
        <div class="right-section flex-column">
            <nav-user-control />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .auth-header {
        font-size: 16px;
        background: #fff !important;
        widows: 100%;
        height: 63px;
        .left-section {
            .auth-header-logo {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .right-section {
            position: relative;
        }
    }
</style>

<script>
import BigCommandLogo from '../Common/BigCommandLogo';
import NavUserControl from '../Common/NavUserControl';

export default {
    name: 'AuthHeader',
    props: ['user'],
    components: {
        BigCommandLogo,
        NavUserControl,
    },
}
</script>