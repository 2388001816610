<template>
    <div class="integration-settings">
        <BaseLoader :showLoader="loading" :fullScreen="true" color="#fff" />
        <div class="integration-header d-flex justify-content-between w-100">
            <div class="settings-header-title">
                Integrations
                {{
                    activeIntegrations && activeIntegrations.connected
                        ? activeIntegrations.connected.length
                        : 0
                }}
            </div>
            <div class="settings-header-control">
                <BaseButton
                    title="New Integration"
                    fontSize="16px"
                    background="#0dabd8"
                    borderRadius="20px"
                    color="#fff"
                    width="max-content"
                    height="39px"
                    @clicked="showModal = true"
                />
            </div>
        </div>
        <div class="integration-body mt-5" v-if="!activeIntegrations">
            <div
                class="empty-integrations-list d-flex flex-column align-items-center"
                v-if="!integrations.length"
            >
                <BaseIcons icon="noIntegration" />
                <div class="empty-list-title mt-4">
                    You Don’t Have Any Active Integration
                </div>
                <div class="empty-list-description">
                    Link Adilo to tens of apps using direct API connection.
                </div>
                <div class="empty-list-description">
                    Connect to hundreds more using Zapier-powered integration.
                </div>
            </div>
        </div>
        <div v-else class="mt-4">
            <integration-card
                v-if="activeIntegrations.email"
                type="Email"
                :integrations="activeIntegrations.email"
                class="mb-4"
                @action="handleCardActions"
            />
            <integration-card
                v-if="activeIntegrations.webinar"
                type="Webinar"
                :integrations="activeIntegrations.webinar"
                class="mb-4"
                @action="handleCardActions"
            />
            <integration-card
                v-if="activeIntegrations.other"
                type="Other"
                :integrations="activeIntegrations.other"
                class="mb-4"
                @action="handleCardActions"
            />
            <integration-card
                v-if="activeIntegrations.payment_gateway"
                type="Payment Gateway"
                :integrations="activeIntegrations.payment_gateway"
                class="mb-4"
                @action="handleCardActions"
            />
        </div>
        <div class="modal-container">
            <integration-modal
                :show="showModal"
                @close="showModal = false"
                @list="getActiveIntegrations"
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
.integration-settings {
    .integration-header {
        .settings-header-title {
            font-size: 20px;
            font-weight: 700;
        }
    }
    .integration-body {
        padding: 50px;
        background: #fff;
        border: 1px dotted #21455e;
        border-radius: 12px;
        .empty-integrations-list {
            padding: 50px 0;
            font-weight: 502;
            .empty-list-title {
                font-size: 20px;
            }
            .empty-list-description {
                font-size: 14px;
            }
        }
    }
}
</style>

<script>
import IntegrationModal from "../Modules/Integration/IntegrationModal";
import IntegrationCard from "../Modules/Integration/IntegrationCard";
import { ACCOUNT_INTEGRATIONS_SAMPLE } from "../../../assets/js/constants";

export default {
    name: "IntegrationSettings",
    data: () => ({
        integrations: [],
        showModal: false,
        activeIntegrations: ACCOUNT_INTEGRATIONS_SAMPLE, // Set to null when connected with api
        loading: false,
    }),
    mounted() {
        // this.getActiveIntegrations();
    },
    methods: {
        getActiveIntegrations() {
            this.axios
                .post("integrations")
                .then((response) => {
                    this.activeIntegrations = response;
                })
                .catch((error) => console.log(error));
        },
        handleCardActions(data) {
            const type = data.type;
            const integration = data.integration;
            const authDetails = data.authDetails;
            switch (type) {
                case "add_app":
                    this.openIntegrationUrl(authDetails);
                    break;
                case "disconnect":
                    this.disconnectIntegration(integration);
                    break;
            }
        },
        openIntegrationUrl(integration) {
            if (integration) {
                if (integration.url) {
                    window.open(integration.url, "_blank");
                }
            }
        },
        disconnectIntegration(integration) {
            const serviceId = integration.id;
            if (serviceId) {
                this.loading = true;
                this.axios
                    .delete(`integrations/${serviceId}`)
                    .then((response) => {
                        this.loading = false;
                        if (response.data) {
                            if (response.data.result === "success") {
                                this.getActiveIntegrations();
                            }
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.error(error);
                    });
            }
        },
    },
    components: {
        IntegrationModal,
        IntegrationCard,
    },
};
</script>
