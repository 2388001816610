<template>
    <transition name='slide-fade'>
        <div class="message-popup-container" @click="close" v-if='show'>
            <div class="message-popup-content">
                <div class="message-popup-body smooth-shadow">
                    <div
                        class="message-tag"
                        v-for='(message, index) in messagesArray'
                        :key='index'
                    >
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .message-popup-container {
        width: 100%;
        height: max-content;
        position: fixed;
        top: 20px;
        left: 0;
        z-index: 18;
        .message-popup-content {
            width: 100%;
            height: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            .message-popup-body {
                background: rgb(230, 229, 229);
                width: max-content;
                border-radius: 3px;
                position: relative;
                font-size: 14px;
                padding: 5px 15px;
            }
        }
    }
</style>

<script>
export default {
    name: 'MessagesPopup',
    props: ['messages', 'duration', 'show'],
    data: () => ({
        messagesArray: [],
    }),
    mounted() {
        this.showMessage();
    },
    methods: {
        showMessage() {
            const messages = this.$props.messages;
            const type = typeof messages;
            if (messages) {
                if (type === 'string') {
                    this.messagesArray.push(messages);
                } else if (type === 'object') {
                    this.messagesArray = messages;
                }
            }
            const duration = parseInt(this.$props.duration);
            setTimeout(() => {

            }, duration || 3000);
        },
        close() {
            this.$emit('closeMessages');
        }
    },
    watch: {
        messages() {
            this.showMessage();
        }
    }
}
</script>