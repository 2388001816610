<template>
    <div class="record-container">
        <BaseLoader
            :showLoader="mainRequest"
            :centered="true"
            color="#862D83"
        />

        <countdown-overlay v-if="showTimer" @ended="startRecording" />
        <countdown-overlay
            v-if="loading && uploadProgress"
            :static="true"
            :staticNumber="uploadProgress"
        />

        <div class="record-content">
            <div
                class="cross-icon flex-column position-absolute cursor-pointer"
                @click="showWarning = true"
                :class="{ 'cross-icon-purple': showPermissionCheck }"
            >
                <BaseIcons
                    :icon="!showPermissionCheck ? 'crossWhite' : 'cross'"
                />
                <div class="icon-text poppins-medium">ESC</div>
            </div>
            <record-permission
                :show="showPermissionCheck"
                @connect="connect"
                @cancelRequest="showWarning = true"
                @goBack="goBack"
            />

            <!-- Header Controls -->
            <video-top-controls
                v-if="canShowControls"
                :devices="devicesToggle"
                @toggle="toggleDevices"
                :selected="selectedDevicesIds"
                :options="connectedDevices"
                @switchDevice="switchDevice"
            />

            <!-- Preview Section -->
            <div v-if="stream && !blob">
                <video-stream :stream="stream" />
            </div>
            <div v-if="blob">
                <video-editor
                    :srcUrl="blob"
                    :autoplay="videoControls.playing"
                    :loading="loading"
                />
            </div>

            <!-- Footer Controls -->
            <div
                class="lower-controls"
                v-if="!videoControls.playing || videoControls.recording"
            >
                <video-lower-controls
                    :show="!showTimer"
                    :recording="videoControls.recording"
                    @close="showWarning = true"
                    @startRecording="triggerCountdown"
                    @stopRecording="stopRecording"
                    @totalDuration="duration = $event"
                />
            </div>

            <div class="pages-controls">
                <BaseButton
                    title="Back"
                    background="#862d8380"
                    hoverBg="#862D83"
                    color="#fff"
                    height="36px"
                    width="147px"
                    fontFamily="Poppins-Medium"
                    @clicked="goBack"
                    :loading="loading"
                    class="align-self-end back-btn"
                />

                <BaseButton
                    v-if="blob"
                    title="Continue"
                    background="#862d8380"
                    hoverBg="#862D83"
                    color="#fff"
                    :loading="loading"
                    height="36px"
                    width="147px"
                    fontFamily="Poppins-Medium"
                    @clicked="saveVideo"
                    class="align-self-end continue-btn ml-4"
                />
            </div>
            <!-- Messages -->
            <span v-if="message">
                <messages-box
                    :messages="message"
                    @closeMessages="message = ''"
                />
            </span>

            <campaign-warning @clicked="cancel" />
        </div>
    </div>
</template>

<style lang="less" scoped>
.record-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #f5f8fa;
    z-index: 14;
    background: black;
    .record-content {
        .lower-controls {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 40px;
            width: 100%;
        }
        .pages-controls {
            display: flex;
            justify-content: flex-end;
            align-content: flex-end;
            position: absolute;
            width: 100%;
            bottom: 20px;
            right: 20px;
            z-index: 19;
        }
    }
}
@media (max-width: 960px) {
    .record-container {
        .record-content {
            .pages-controls {
                justify-content: center;
                right: 0px;
            }
        }
    }
}
</style>

<script>
import MessagesBox from "../../components/Common/MessagesBox";
import RecordPermission from "../../components/Videos/RecordPermission";
import VideoLowerControls from "../../components/Videos/VideoLowerControls";
import VideoStream from "../../components/Videos/VideoStream";
import VideoEditor from "../../components/Videos/VideoEditor";
import VideoTopControls from "../../components/Videos/VideoTopControls";
import CampaignWarning from "../../components/Projects/CampaignWarning";
import CountdownOverlay from "../../components/Common/Widgets/CountdownOverlay";

export default {
    name: "Record",
    components: {
        RecordPermission,
        MessagesBox,
        VideoStream,
        VideoTopControls,
        VideoLowerControls,
        CampaignWarning,
        VideoEditor,
        CountdownOverlay
    },
    mounted() {
        this.getCampaignData();
        this.setEscapeKeyEvent();
        this.checkPermissions();
    },
    data: () => ({
        permissions: {
            video: false,
            audio: false,
            screen: false
        },
        devicesToggle: {
            video: true,
            audio: true,
            screen: false
        },
        showPermissionCheck: false,
        videoDimensions: {
            width: "",
            height: ""
        },
        loading: false,
        mainRequest: false,
        message: "",
        stream: null,
        audioStream: null,
        connectedDevices: {},
        selectedDevicesIds: [],
        selectedDevices: {
            video: null,
            audio: null
        },
        showWarning: false,
        videoControls: {
            recording: false,
            playing: false
        },
        projectId: null,
        recordedChunks: [],
        recorder: null,
        blob: null,
        showTimer: false,
        canShowControls: true,
        videoFile: null,
        uploadProgress: 0,
        duration: 0,
        base64Image: ""
    }),
    methods: {
        getCampaignData() {
            const campaignId = this.$route.params.id;
            const url = `/campaigns/${campaignId}`;
            this.mainRequest = true;
            this.axios
                .get(url)
                .then(response => {
                    this.mainRequest = false;
                    this.handleMainRequestResponse(response.data);
                })
                .catch(error => {
                    this.mainRequest = false;
                    this.$router.push({path: '/projects'});
                    console.log(error);
                });
        },
        handleMainRequestResponse(response) {
            const projectId = response.project_id;
            this.projectId = projectId;
            this.projectHashId = response.project_hash_id;
        },
        async checkPermissions() {
            let hasPermission = false;
            await navigator.mediaDevices.enumerateDevices().then(devices => {
                devices.forEach(device => {
                    if (device.label) {
                        hasPermission = true;
                    }
                });
            });
            if (hasPermission) this.connect();
            else this.showPermissionCheck = true;
        },
        async connect(micOnly = false) {
            let { video, audio } = this.devicesToggle;
            const selectedVideoId = this.selectedDevices.video;
            const selectedAudioId = this.selectedDevices.audio;
            if (selectedVideoId) {
                video = this.getSelectedDeviceConstraints(selectedVideoId);
            }
            if (selectedAudioId) {
                audio = this.getSelectedDeviceConstraints(selectedAudioId);
            }
            if (micOnly) {
                video = false;
            }

            await navigator.mediaDevices
                .getUserMedia({
                    video,
                    audio
                })
                .then(streamHandler => {
                    this.showStream(streamHandler, !video);
                    this.getSelectedDevices(streamHandler);
                    this.getConnectedDevices();
                    if (video) {
                        this.devicesToggle.screen = false;
                    }
                    if (micOnly) {
                        this.stream.addTrack(streamHandler.getAudioTracks()[0]);
                    }
                })
                .catch(errorHandler => {
                    console.log(errorHandler);
                    this.showPermissionCheck = true;
                    this.message = `Permission Denied, cannot continue, please refresh the page and try again.`;
                });
        },
        getSelectedDeviceConstraints(sourceId) {
            return {
                deviceId: { exact: sourceId }
            };
        },
        switchDevice(obj) {
            this.stopAudioTracks();
            const type = Object.keys(obj)[0];
            this.selectedDevices[type] = obj[type];
            if (type != "audio") {
                this.stopTracks();
                this.stream = null;
            }
            this.retriggerConnections();
        },
        retriggerConnections() {
            const { audio, video, screen } = this.devicesToggle;
            const $this = this;
            if (video) {
                this.connect();
            } else if (audio) {
                if (screen) {
                    $this.connect(true);
                }
            }
        },
        stopTracks() {
            if (!this.stream) return false;
            this.stream.getTracks().forEach(function(track) {
                track.stop();
            });
        },
        stopAudioTracks() {
            if (!this.audioStream) return false;
            this.audioStream.getTracks().forEach(function(track) {
                track.stop();
            });
        },
        async showScreen() {
            this.stopCam();
            this.devicesToggle.screen = true;
            const $this = this;
            await navigator.mediaDevices
                .getDisplayMedia({ frameRate: 25, audio: true, video: true })
                .then(streamHandler => {
                    $this.showStream(streamHandler);
                    streamHandler.getVideoTracks()[0].onended = function () {
                        $this.stopRecording();
                    };
                    $this.devicesToggle.screen = true;
                })
                .catch(errorHandler => {
                    console.log(errorHandler);
                    $this.devicesToggle.screen = false;
                    if (!this.devicesToggle.screen) {
                        this.showPermissionCheck = true;
                    }
                    this.message =
                        "Permission Denied, cannot continue, please refresh the page and try again.";
                });
            return $this.devicesToggle.screen;
        },
        showStream(stream, audioOnly = false) {
            this.showPermissionCheck = false;
            if (!audioOnly) {
                this.stream = stream;
            } else {
                this.audioStream = stream;
            }
        },
        getSelectedDevices(stream) {
            const tracks = stream.getTracks();
            let hasAudio = false;
            let hasVideo = false;

            this.selectedDevicesIds = tracks.map(track => {
                if (track.kind === "audio") hasAudio = true;
                if (track.kind === "video") hasVideo = true;
                return track.getSettings().deviceId;
            });
            this.devicesToggle.audio = hasAudio;
            this.devicesToggle.video = hasVideo;
        },
        async getConnectedDevices() {
            await navigator.mediaDevices
                .enumerateDevices()
                .then(devices => {
                    this.addConnectedDevices(devices);
                })
                .catch(error => console.log(error));
        },
        addConnectedDevices(devices) {
            const data = {
                audio: [],
                video: []
            };
            devices.forEach(device => {
                const type = device.kind.replace("input", "");
                const id = device.deviceId;
                const label = device.label;
                if (data[type]) {
                    data[type].push({ id, label });
                }
            });
            this.connectedDevices = data;
        },
        toggleDevices(device) {
            if (!this.canBeSwitched(device)) return false;

            this.devicesToggle[device] = !this.devicesToggle[device];

            const $this = this;
            this.stopAudioTracks();
            if (device === "screen") {
                if (this.devicesToggle.screen) {
                    this.devicesToggle.video = false;
                    this.stopTracks();
                    this.stream = null;
                    this.showScreen().then(connected => {
                        if (connected && this.devicesToggle.audio) {
                            $this.connect(true);
                        }
                    });
                }
            } else if (device === "video") {
                if (this.devicesToggle.video) {
                    this.stopTracks();
                    this.stream = null;
                    this.devicesToggle.screen = false;
                    this.connect();
                }
            } else if (device === "audio") {
                if (!this.devicesToggle.screen && this.devicesToggle.audio) {
                    this.connect(true);
                } else {
                    this.connect();
                }
            }
        },
        canBeSwitched(device) {
            let canBeSwitched = true;
            if (device === "video") {
                canBeSwitched = !this.devicesToggle.video;
            } else if (device === "screen") {
                canBeSwitched = !this.devicesToggle.screen;
            }
            return canBeSwitched;
        },
        cancel(response) {
            const cancel = response === "yes";
            this.showWarning = false;
            if (cancel) {
                this.redirectToProject();
            }
        },
        setEscapeKeyEvent() {
            const $this = this;
            window.addEventListener("keyup", function(event) {
                if (event.key === "Escape") {
                    $this.showWarning = true;
                }
            });
        },
        redirectToProject() {
            this.showWarning = false;
            const projectId = this.projectHashId;
            const path = projectId ? `/projects/${projectId}` : "/projects";
            this.$router.push({ path });
        },
        goBack() {
            const campaignId = this.$route.params.id;
            const path = `/campaigns/${campaignId}`;
            this.$router.push({ path });
        },
        stopCam() {
            this.devicesToggle.video = false;
        },
        startRecording() {
            const $this = this;
            $this.recordedChunks = [];
            const stream = this.stream;

            $this.showTimer = false;
            $this.recorder = new MediaRecorder(stream, {
                mimeType: "video/webm"
            });

            // Add chunks
            $this.recorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    $this.recordedChunks.push(event.data);
                }
            };

            // Create Blob
            $this.recorder.onstop = () => {
                $this.createBlob();
                $this.stopRecording();
            };

            stream.oninactive = () => {
                $this.createBlob();
                $this.stopRecording();
            };

            $this.videoControls.recording = true;
            $this.videoControls.playing = true;

            $this.recorder.start();
        },
        stopRecording(stopRecorder = true) {
            if (stopRecorder) {
                const state = this.recorder.state;
                if (state != "inactive") {
                    this.recorder.stop();
                }
            }
            const video = document.querySelector("video");
            const canvas = document.createElement("canvas");
            // console.log({video, canvas});
            const ctx = canvas.getContext("2d");
            ctx.canvas.width = video.videoWidth;
            ctx.canvas.height = video.videoHeight;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageBlob = canvas.toDataURL("image/jpeg");
            this.base64Image = imageBlob;
            // console.log(this.base64Image);
            this.videoControls.recording = false;
            this.videoControls.playing = true;
        },
        createBlob() {
            const videoFile = new Blob(this.recordedChunks, {
                type: "video/mp4"
            });
            this.videoFile = videoFile;
            const url = URL.createObjectURL(videoFile);
            this.blob = url;
            this.stream = null;
        },
        triggerCountdown() {
            this.showTimer = true;
            this.canShowControls = false;
        },
        saveVideo() {
            const data = new FormData();
            const randomId = this.makeVideoId(5);
            const extension = ".mp4";
            const fileName = `${randomId}${extension}`;
            const videoFile = new File([this.videoFile], fileName, {
                type: "video/mp4"
            });

            const campaignId = this.$route.params.id;
            const step = this.$route.params.current_step;
            data.append("video", videoFile);
            data.append("duration", this.duration);
            data.append("campaign_id", campaignId);
            data.append('frame_code', Math.random(0, this.duration));
            this.handleVideoUploadRequest(data, campaignId, step);
        },
        handleVideoUploadRequest(data, campaignId, step) {
            const url = "/videos/upload";
            this.loading = true;
            this.axios
                .post(url, data, {
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress: function(event) {
                        this.showProgress(event);
                    }.bind(this)
                })
                .then(response => {
                    console.log(response);
                    this.loading = false;
                    this.uploadProgress = 100;
                    if (response.data.success) {
                        this.$router.push({
                            path: `/campaigns/${campaignId}/${step}/upload`
                        });
                    }
                })
                .catch(error => {
                    this.uploadProgress = 0;
                    this.loading = false;
                    console.log(error);
                    this.message = "Failed to upload";
                });
        },
        showProgress(event) {
            const total = parseInt(event.total);
            const loaded = parseInt(event.loaded);
            let percent = (loaded * 100) / total;
            percent = percent > 90 ? 90 : percent;
            this.uploadProgress = percent;
        },
        makeVideoId(length) {
            var result = "";
            var characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            return result;
        }
    }
};
</script>
