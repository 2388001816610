<template>
    <div class="main-library-content">
        <BaseInput
            placeholder='Search your videos'
            border='1px solid transparent'
            borderRadius='20px'
            icon='magnifyPurple'
            class='search-bar'
            keyTrigger='Enter'
            :inputValue='query'
            @value='query = $event'
            @keyPressed='searchRequest'
        />
        <BaseLoader :showLoader='loading' class="mt-5" />
        <div class="video-grids w-100 pt-3">
            <div
                v-for="video in videos"
                :key='video.id'
                class="video-thumbnail-container"
            >
                <div class="video-thumbnail" @click="$emit('selectedVideo', video)">
                    <img :src="video.thumbnail" :class="video.video_id == activeVideoId ? classActive : ''">
                </div>
                <BaseIcons icon='playBW' class="play-icon" />
            </div>
        </div>
    </div>
</template>

<style lang="less">
    .main-library-content {
        .search-bar {
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            .form-control {
                padding-left: 4em !important;
                color: #862D83 !important;
                font-family: "Poppins";
                &::placeholder {
                    color: #862D83;
                }
            }
            .input-icon {
                left: 2em !important;
                right: auto !important;
                width: max-content;
            }
        }
        .video-grids {
            display: grid;
            grid-template-columns: repeat(3, minmax(100px, 1fr));
            row-gap: 30px;
            max-height: 510px;
            overflow: auto;
            justify-items: left;
            column-gap: 5rem;
            scroll-behavior: smooth;
            .video-thumbnail-container {
                position: relative;
                width: 150px;
                height: 150px;
                .video-thumbnail {
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    img {
                        width: 150px;
                        height: 150px;
                        border-radius: 10px;
                        border: 2px solid transparent;
                        &:hover {
                            border: 2px solid #862D83;
                        }
                    }
                    .active {
                        border: 2px solid #862D83; 
                    }
                }
                .play-icon {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 45px;
                    height: max-content;
                    margin: auto;
                }
            }
        }
    }
    @media (max-width: 480px) {
        .main-library-content {
            .video-grids {
                grid-template-columns: repeat(2, minmax(100px, 1fr)) !important;
                .video-thumbnail {
                    img {
                        width: 150px !important;
                        height: 150px !important;
                    }
                }
                .play-icon {
                    bottom: 45px !important;
                }
            }
        }
    }
    @media (max-width: 1500px) {
        .main-library-content {
            .video-grids {
                grid-template-columns: repeat(2, minmax(100px, 1fr));
                .video-thumbnail-container {
                    width: 100% !important;
                    margin-bottom: 40px;
                    .video-thumbnail {
                        img {
                            width: 100%;
                            height: 200px;
                        }
                    }
                    .play-icon {
                        bottom: 30px;
                    }
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'VideoGrids',
    props: ['videos', 'loading','classActive','activeVideoId'],
    data: () => ({
        query: '',
    }),
    mounted(){
        console.log(this.$props);
    },
    methods: {
        searchRequest() {
            this.$emit('query', this.query);
        }
    }
};
</script>