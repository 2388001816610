<template>
    <div class="dropdown-button">
        <div
            class="button-content d-flex justify-content-between"
            @click="$emit('clicked')"
            :style='{
                background,
                borderRadius,
                fontSize,
                fontFamily,
                color,
                width,
                height,
            }'
        >
            <div class="left-icon" v-if='leftIcon'>
                <BaseIcons :icon='leftIcon' />
            </div>
            <div class="mid-text pl-3 pr-3 proxima-nova-regular" v-if='midText'>
                {{ midText }}
            </div>
            <div class="right-icon" v-if="rightIcon">
                <BaseIcons :icon='rightIcon' />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropDownButton',
    props: [
        'leftIcon',
        'rightIcon',
        'midText',
        'background',
        'borderRadius',
        'fontSize',
        'fontFamily',
        'color',
        'width',
        'height',
    ]
};
</script>

<style lang="less">
    .dropdown-button {
        width: max-content;
        .button-content {
            width: 100%;
            border-radius: 20px;
            padding: 2px 20px;
            color: #778D9D;
            cursor: pointer;
            position: relative;
            background: #F5F6F9;
            align-items: center;
            &:active {
                top: 1px;
            }
            .left-icon {
                position: relative;
                bottom: 2px;
            }
            .right-icon {
                position: relative;
                bottom: 2px;
            }
        }
    }
</style>