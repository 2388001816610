<template>
    <transition name="fade">
        <div class="projects-filter" v-if='show' :style="{top, left}">
            <div class="projects-filter-content">
                <div class="options">
                    <div class="flex-column option pb-3">
                        <checkbox-labels :icon='checked("Date")' label='Date' @clicked='selectOption'/>
                        <date-filter
                            :show='checked("Date", true)'
                            :selectOptions='dateFilterOptions'
                            @comparisonObject='addFilterData($event, "Date")'
                        />
                    </div>
                    <div class="flex-column option pb-3">
                        <checkbox-labels :icon='checked("Title")' label='Title' @clicked='selectOption'/>
                        <string-filter
                            :show='checked("Title", true)'
                            @comparisonObject='addFilterData($event, "Title")'
                        />
                    </div>
                    <div class="flex-column option pb-3">
                        <checkbox-labels :icon='checked("Views")' label='Views' @clicked='selectOption' />
                        <number-filter
                            :show='checked("Views", true)'
                            @comparisonObject='addFilterData($event, "Views")'
                        />
                    </div>
                    <div class="flex-column option pb-3">
                        <checkbox-labels :icon='checked("Clicks")' label='Clicks' @clicked='selectOption' />
                        <number-filter
                            :show='checked("Clicks", true)'
                            @comparisonObject='addFilterData($event, "Clicks")'
                        />
                    </div>
                </div>
                <div class="filter-footer d-flex justify-content-center mb-2">
                    <BaseButton
                        title='Cancel'
                        background='#707070'
                        color='#fff'
                        borderRadius='20px'
                        width='100px'
                        class="mr-2"
                        @clicked='$emit("close")'
                    />
                    <BaseButton
                        title='Done'
                        background='rgb(11, 172, 219) none repeat scroll 0% 0%'
                        color='#fff'
                        borderRadius='20px'
                        width='100px'
                        @clicked='sendFilterObject'
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .projects-filter {
        position: absolute;
        width: max-content;
        min-width: 278px;
        max-width: max-content;
        background: #fff;
        border: 1px solid #00ACDC;
        border-radius: 10px;
        z-index: 5;
        .projects-filter-content {
            .options {
                padding: 25px 25px 0px 25px;
            }
        }
    }
    @media (max-width: 960px) {
        .projects-filter {
            right: 0;
            margin: auto;
        }
    }
</style>

<script>
import CheckboxLabels from '../Common/CheckboxLabels';
import DateFilter from '../Common/Filters/DateFilter';
import NumberFilter from '../Common/Filters/NumberFilter';
import StringFilter from '../Common/Filters/StringFilter';

export default {
    name: 'ProjectsFilter',
    props: ['show', 'top', 'left'],
    components: {
        CheckboxLabels,
        DateFilter,
        NumberFilter,
        StringFilter,
    },
    data: () => ({
        checkedOptions: [],
        dateFilterOptions: [
            { label: 'Created in the last', id: 'created_in_last' },
            { label: 'Updated in the last', id: 'updated_in_last' },
            { label: 'Created before', id: 'created_before' },
            { label: 'Updated before', id: 'updated_before' },
            { label: 'Created after', id: 'created_after' },
            { label: 'Updated after', id: 'updated_after' },
        ],
        filterOptions: {}
    }),
    methods: {
        checked(option, booleanReturn = false) {
            const isChecked = this.checkedOptions.includes(option);
            if (booleanReturn) {
                return isChecked;
            }
            return isChecked ? 'checked' : 'unchecked';
        },
        selectOption(option) {
            let checkedOptions = this.checkedOptions;
            if (!checkedOptions.includes(option)) {
                checkedOptions.push(option);
            } else {
                checkedOptions = checkedOptions.filter(cO => cO != option);
            }
            this.checkedOptions = checkedOptions;
        },
        addFilterData(data, type) {
            const selectedTypes = this.checkedOptions;
            const filterObject = this.filterOptions;
            const optionId = type.toLowerCase();
            if (selectedTypes.includes(type)) {
                filterObject[optionId] = data;
            } else {
                filterObject[optionId] = null;
            }
            this.filterOptions = filterObject;
        },
        sendFilterObject() {
            this.$emit("filter", this.filterOptions);
        }
    },
}
</script>