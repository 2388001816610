var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-button"},[_c('div',{staticClass:"button-content d-flex justify-content-between",style:({
            background: _vm.background,
            borderRadius: _vm.borderRadius,
            fontSize: _vm.fontSize,
            fontFamily: _vm.fontFamily,
            color: _vm.color,
            width: _vm.width,
            height: _vm.height,
        }),on:{"click":function($event){return _vm.$emit('clicked')}}},[(_vm.leftIcon)?_c('div',{staticClass:"left-icon"},[_c('BaseIcons',{attrs:{"icon":_vm.leftIcon}})],1):_vm._e(),(_vm.midText)?_c('div',{staticClass:"mid-text pl-3 pr-3 proxima-nova-regular"},[_vm._v(" "+_vm._s(_vm.midText)+" ")]):_vm._e(),(_vm.rightIcon)?_c('div',{staticClass:"right-icon"},[_c('BaseIcons',{attrs:{"icon":_vm.rightIcon}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }