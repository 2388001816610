import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./routes";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import VueTelInput from "vue-tel-input";
import VEvent from "./utils/VEvent";
import VueCalendly from "vue-calendly";
import "vue-tel-input/dist/vue-tel-input.css";

// Css files
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/main.less";
// Css files

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueTelInput);
Vue.use(VueCalendly);

// Axios Prefix
const accessToken = window.localStorage.getItem("token");
axios.interceptors.request.use(
	(config) => {
		const baseUrl =
			config.url && config.url[0] === "/"
				? `${process.env.VUE_APP_BACKEND_URL}api`
				: `${process.env.VUE_APP_BACKEND_URL}api/`;
		config.url = `${baseUrl}${config.url}`;
		config.headers.Authorization = `Bearer ${accessToken}`;
		config.headers.common["X-Requested-With"] = "XMLHttpRequest";
		const showLoading = config.headers.showLoading;
		if (showLoading) {
			toggleLoader();
		}
		return config;
	},
	(error) => {
		toggleLoader(true);
		showError(error);
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => {
		toggleLoader(true);
		return response;
	},
	(error) => {
		toggleLoader(true);
		const responseError = error.response;
		let message = null;
		if (responseError) {
			message = responseError.data.message;
		}
		showError(message ? message : error);
		return Promise.reject(error);
	}
);

function showError(error) {
	const messageContainer = document.querySelector(
		".message-container-global"
	);
	if (messageContainer) {
		const messageDiv = document.querySelector(".message-global");
		if (messageDiv) {
			messageDiv.innerHTML = error;
			messageContainer.style.display = "block";
			setTimeout(() => {
				messageDiv.innerHTML = "";
				messageContainer.style.display = "none";
			}, 3000);
		}
	}
}

function toggleLoader(hide = false) {
	const loader = document.querySelector(".axios-loader-container");
	if (loader) {
		if (hide) {
			loader.style.display = "none";
		} else {
			loader.style.display = "block";
		}
	}
}

// Axios Prefix

// Base Componenets
const requireComponent = require.context(
	"./components",
	true,
	/Base[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach((fileName) => {
	const componentConfig = requireComponent(fileName);
	const componentName = upperFirst(
		camelCase(
			fileName
				.split("/")
				.pop()
				.replace(/\.\w+$/, "")
		)
	);
	Vue.component(componentName, componentConfig.default || componentConfig);
});
// Base Componenets

/**
 * Vue Event Bus.
 * @type {VEvent}
 */
window.vEvent = new VEvent();

// Global Variables
Vue.prototype.$appName = process.env.VUE_APP_NAME;
Vue.prototype.$companyName = process.env.VUE_APP_COMPANY_NAME;
// Global Variables


window.vEvent = new VEvent();

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
