<template>
    <div class="main-navbar" v-if='canBeShown'>
        <div class="sections d-flex justify-content-between">
            <div class="left-section">
                <div class="mobile-menu">
                    <links-dropdown :items='navLinks' />
                </div>
                <div class="logo d-flex">
                    <BaseIcons icon='productLogo' />
                    <div class="logo-title pl-3 helvetica-bold">{{ $appName }}</div>
                </div>
            </div>
            <div class="middle-section d-flex">
                <div class="nav-buttons">
                    <span
                        v-for='(item, index) in navLinks'
                        :key='index'
                    >
                        <a :href="item.path" class='nav-button helvetica-medium' v-if='item.direct_link' target="_blank">
                            {{ item.name }}
                        </a>
                        <router-link v-else :to='item.path' class='nav-button helvetica-medium'>
                            {{ item.name }}
                        </router-link>
                    </span>
                    <BaseIcons icon='magnify' class='nav-button'/>
                </div>
            </div>
            <div class="right-section nav-link">
                <nav-user-control topMargin='64px'/>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .main-navbar {
        position: fixed;
        top: 0;
        margin: auto;
        height: 63px;
        width: 100%;
        background: #FFFFFF;
        border-bottom: 1px solid #A3BAC6;
        z-index: 5;
        .sections {
            padding: 10px 30px;
            .left-section {
                .logo {
                    .logo-title {
                        font-size: 28px;
                    }
                }
                .mobile-menu {
                    display: none;
                }
            }
            .middle-section {
                .nav-buttons {
                    position: relative;
                    top: 10px;
                    .selected {
                        border-bottom: 2px solid #0BACDB;
                    }
                    .nav-button {
                        color: #21455E;
                        text-decoration: none;
                        display: inline-block;
                        height: 43px;
                        border-bottom: 2px solid transparent;
                        margin: 0 30px;
                        cursor: pointer;
                        &:hover {
                            color: #0BACDB;
                            border-bottom: 2px solid #0BACDB;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1172px) {
        .main-navbar {
            background: #011a22 !important;
            .sections {
                padding: 8px 15px;
                .left-section {
                    .mobile-menu {
                        display: inline-block;
                    }
                    .logo {
                        display: none !important;
                    }
                }
                .middle-section {
                    .nav-buttons {
                        display: none !important;
                    }
                }
            }
        }
    }
</style>

<script>
import NavUserControl from './Common/NavUserControl';
import LinksDropdown from './Common/LinksDropdown';

export default {
    name: 'Navbar',
    props: ['canBeShown'],
    data: () => ({
        navLinks: [
            {path: '/projects', name: 'Projects'},
            {path: '/inbox', name: 'Inbox'},
            {path: '/settings/account', name: 'Settings'},
            {path: process.env.VUE_APP_HELP_URL, name: 'Help', direct_link: true},
        ],
    }),
    components: {
        NavUserControl,
        LinksDropdown,
    },
};
</script>