<template>
    <div
        @click="clicked"
        class="button-container btn d-flex align-items-center"
        @mouseover="showHoverBg = true"
        @mouseleave="showHoverBg = false"
        :style="{
            background: hoverBg && showHoverBg ? hoverBg : background,
            color: hoverColor && showHoverBg ? hoverColor : color,
            borderRadius,
            width,
            height,
            padding,
            border,
            fontFamily,
            fontSize,
            position: position ? position : 'relative',
            opacity,
            top,
            right,
            left,
            bottom,
            zIndex,
        }">
        <div class="text text-center" v-if='!loading && title'>
            {{ title }}
        </div>
        <div v-if='!loading && icon'>
            <BaseIcons :icon='icon' />
        </div>
        <div v-else>
            <BaseLoader
                :showLoader='loading'
                :centered='true'
                color='#fff'
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .button-container {
        .text {
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
</style>

<script>
export default {
    name: 'BaseButton',
    props: [
        'background',
        'color',
        'title',
        'loading',
        'borderRadius',
        'width',
        'height',
        'padding',
        'border',
        'fontFamily',
        'fontSize',
        'opacity',
        'position',
        'top',
        'bottom',
        'left',
        'right',
        'hoverBg',
        'icon',
        'hoverColor',
        'zIndex',
    ],
    data: () => ({
        showHoverBg: false,
    }),
    methods: {
        clicked() {
            if (this.$props.loading) return false;
            this.$emit('clicked');
        }
    }
}
</script>