<template>
    <transition name='slide-fade'>
        <div class="countdown-overlay smooth-shadow d-flex justify-content-center align-content-center" v-if='show'>
            <div class="poppins-medium number">
                {{ number }}
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .countdown-overlay {
        position: fixed;
        z-index: 18;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        color: #21455E;
        width: 200px;
        height: 200px;
        background: #fff;
        padding: 50px;
        border-radius: 50%;
        .number {
            font-size: 100px;
            position: absolute;
            top: 25px;
            bottom: 0;
            margin: auto;
            height: max-content;
            left: 0;
            right: 0;
            width: max-content;
        }
    }
</style>

<script>
export default {
    name: 'CountdownOverlay',
    props: ['startFrom', 'static', 'staticNumber'],
    data: () => ({
        number: 3,
        show: false,
        intervalId: null,
    }),
    mounted() {
        this.start();
    },
    methods: {
        start() {
            if (this.$props.static) {
                this.number = this.$props.staticNumber;
                return this.show = true;
            }
            const startFrom = this.$props.startFrom;
            let number = this.number;
            if (startFrom) {
                number = startFrom;
            }
            this.number = number;
            this.startInterval();
        },
        startInterval() {
            this.show = true;
            this.intervalId = setInterval(() => {
                this.number = this.number - 1;
                if (this.number === 0) {
                    clearInterval(this.intervalId);
                    this.show = false
                    this.$emit('ended');
                }
            }, 1000)
        }
    }
}
</script>