<template>
    <transition name='slide-fade'>
        <div class="subscription-usage pb-4">
            <div class="subscription-usage-header d-flex align-items-center">
                <div class="header-title pl-5 pt-2 pb-2 helvetica-bold">
                    USAGE
                </div>
            </div>
            <div class="subscription-usage-body pl-5 pr-5 pt-4 pb-2">
                <div class="resources">
                    <resource-usage
                        title='Bandwidth'
                        :descriptions='descriptions.bandwidth'
                        :values='consumedData.bandwidth'
                        class="pb-5"
                        type="bytes"
                    />
                    <resource-usage
                        title='Videos'
                        :descriptions='descriptions.videos'
                        :values='consumedData.videos'
                        class="pb-5"
                    />
                    <resource-usage
                        title='Storage'
                        :descriptions='descriptions.storage'
                        :values='consumedData.storage'
                        class="pb-5"
                        type="bytes"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .subscription-usage {
        .subscription-usage-header {
            background: #0bacdb07;
            height: 48px;
            .header-title {
                font-size: 13px;
            }
        }
    }
</style>

<script>
import ResourceUsage from './ResourceUsage';
import moment from 'moment';

export default {
    name: 'SubscriptionUsage',
    components: {
        ResourceUsage,
    },
    props: ['subscriptionData'],
    data: () => ({
        descriptions: {
            bandwidth: ["See how much bandwidth you've consumed"],
            videos: ["See how many videos you've stored on Adilo", "Number of videos"],
            storage: ["Storage size"]
        },
        consumedData: {
            bandwidth: {available: 0, used: 0},
            videos: {available: 0, used: 0},
            storage: {available: 0, used: 0},
        },
        lifeTimePlan: false,
        resetDays: 0,
        nextChargeDate: null,
    }),
    mounted() {
        this.getNextChargeDate();
        this.getPlanType();
        this.getResourcesData();
    },
    methods: {
        getNextChargeDate() {
            const subscriptionData = this.$props.subscriptionData;
            if (subscriptionData) {
                const nextChargeDate = subscriptionData.next_charge_date;
                if (nextChargeDate) {
                    this.nextChargeDate = nextChargeDate;
                    this.resetDays = moment().diff(nextChargeDate, 'days');
                }
            }
        },
        getResourcesData() {
            const subscriptionData = this.$props.subscriptionData;
            if (subscriptionData) {
                this.getBandwidthData(subscriptionData);
                this.getVideosData(subscriptionData);
                this.getStorageData(subscriptionData);
            }
        },
        getPlanType() {
            const subscriptionData = this.$props.subscriptionData;
            this.lifeTimePlan = subscriptionData && subscriptionData.plan_data ? subscriptionData.plan_data.lifetime_plan : false;
            this.freePlan = subscriptionData && subscriptionData.plan_data ? subscriptionData.plan_data.free_plan : false;
        },
        getBandwidthData(data) {
            const resetDays = this.resetDays >= 0 ? this.resetDays : 0;
            this.descriptions.bandwidth = [];
            if (!this.lifeTimePlan && !this.freePlan) {
                this.descriptions.bandwidth.push(`See how much bandwidth you've consumed in this current billing cycle (resets every 30 days). Bandwidth usage outside of the allotted is billed at $0.08.`);
                this.descriptions.bandwidth.push(`This month's bandwidth (Resets in ${resetDays} days).`);
            } else {
                this.descriptions.bandwidth.push(`See how much bandwidth you've consumed.`);
            }

            if (data) {
                const availableBandwidth = data.available_bandwidth;
                const usedBandwidth = data.used_bandwidth;
                this.consumedData.bandwidth.available = Math.round(availableBandwidth);
                this.consumedData.bandwidth.used = Math.round(usedBandwidth);
            }
        },
        getVideosData(data) {
            const availableVideos = data.available_videos;
            const storedVideos    = data.videos_count;
            this.consumedData.videos.available = availableVideos;
            this.consumedData.videos.used = storedVideos;
        },
        getStorageData(data) {
            const availableStorage = data.available_storage;
            const usedStorage      = data.storage_usage;
            this.consumedData.storage.available = availableStorage;
            this.consumedData.storage.used = usedStorage;
        }
    }
}
</script>