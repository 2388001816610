<template>
  <transition name="fade">
<div>
<div class="container">    
<div class="layout-container">
    <div class="d-flex bd-highlight mb-3 mt-4">
    <div class="edit-topLeft mr-auto p-2 bd-highlight"> 
     <h3 v-on:click="goToProject">{{project.title}} > </h3>
     <h3 class="edit-topLeft-campaign">{{campaign.title}}</h3>
     <a class="edit-topLeft-link"> https://askpigeon.bigcommand.com/video/7ueUR9</a>    
    </div>
    <div class="d-flex justify-content-end top-right-buttons">
    <CampaignOptionsDropdown
    :campaignData='campaign'
     />
    <BaseButton
        title='Save'
        background='#862D83' 
        color='#fff'
        height='36px'
        width='147px'
       :loading='loading'
       @clicked="saveStepData"
       hoverBg="#ae6cbe"
         />
     <BaseLoader :showLoader="loading" :centered="true" />
    </div>     
    <div>
    </div>
</div>
<div class="image-container d-flex flex-column justify-content-center align-items-center" >
    <div  v-for="(video,index) in thumbnails" :key="video.id">
        <div class="step " >
            <div class="step-counter ">
            <div class="step-rectangle d-flex flex-column justify-content-center align-items-center">
                <span>Step</span>
                <div class="step-circle">
                <span>{{stepCount(index)}}</span>
                </div>
            </div>
            </div>              
        <img class="video-img" :src='video.thumbnail' />
         <StepDropdown 
            :index="index == thumbnails.length -1 ? 'End' : index"
            :length="thumbnails.length"
            :dropdownDisable="index == thumbnails.length -2"
            :video_id="video.id"
            @stepSelected="handleStepChange($event)"
            :selectedStep="video.jumpStep && video.jumpStep.length > 0 ? video.jumpStep[video.jumpStep.length - 1].jump_to   : false "            
            :choiceStep="video.multiChoice && video.multiChoice.length > 0 ? video.multiChoice : false"
        />        
        </div>
 
    <div style="position:relative">
        <div class="line"></div>
          <button type="button" class="step-button" v-on:click="goToVideoCreation(index)" >     
          </button>
        </div>
    </div>
<div class="ending-btn justify-content-center">
    <div>
     <button type="button" class="add-button" v-on:click="goToVideoCreation(index)">               
         </button>
     <p>Add another step</p>
    </div>
</div>
</div>

</div>  
</div>
</div>
</transition>
</template>

<script>
import StepDropdown from '../../components/Common/StepDropdown';
import CampaignOptionsDropdown from '../../components/Common/CampaignOptionsDropdown'

export default {
components: {
    StepDropdown,
    CampaignOptionsDropdown
},
data: () => ({
        campaigns: [],
        thumbnails:[],
        project: {},
        campaign: {},
        reload : false,
        loading:false,
        CampaignOptionLoad:false,
        stepCountData:[],
        data:{}
         }),
           
methods:{
    getThumbnails() {
            const Id = this.$route.params.id;
            const url = `campaigns/${Id}`;
            this.loading = true;
            this.axios
                .get(url)
                .then(response => {
                    this.loading = false;
                        if (response.data.success) {
                        this.thumbnails = response.data.campaign.thumbnailVidoes;  
                        console.log("data",response.data.campaign); 
                        //].jumpStep.jump_to);                       
                        }                   
                    })
                .catch(error => {
                    this.loading = false;
                    this.$router.push({path: '/projects'});
                    console.log(error);
                });
        },
        handleStepChange(data) {
            this.stepCountData.push(data);
            console.log(this.stepCountData);
        },
        saveStepData(){
            this.loading=true;
            this.data.data=this.stepCountData;
              const id = this.$route.params.id;
              const url = `/campaigns/${id}`;
                this.loading = true;
                this.axios.put(url, this.data).then(response => {
                    this.loading = false;
                    if(response.data.success) {
                        this.goToProject();
                    }
                }).catch(error => {
                    this.loading = false;
                    console.log("ERRRR:: ",error.response.data);
                    console.log(error);
                })  

        },
        getNames() {
            const projectId = this.$route.params.project_id;
            const url = `projects/${projectId}/campaigns`;
            this.loading = true;
            this.axios
                .get(url)
                .then(response => {
                    this.loading = false;
                    if (response.data.success) {
                     response.data.campaigns.forEach(camp => {
                            if(camp.campaign_id == this.$route.params.id){
                                this.campaign = camp;
                            }
                        });
                        this.campaignsAll = response.data.campaigns;
                        this.project = response.data.project;
                        
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$router.push({path: '/projects'});
                    console.log(error);
                });
        },  
        goToVideoCreation(index) {
            if(index == undefined){
                index= this.thumbnails.length;
            }
            const id = this.$route.params.id;
            this.$router.push({
            path: `/campaigns/${id}/${index+1}/create`
            });
        },
        goToProject(){
        const projectId= this.$route.params.project_id;
        this.$router.push(`/projects/${projectId}`)
        },
        stepCount(index) {
        return index+1
        }
},mounted() {
this.getThumbnails();
this.getNames();
},
    
};
</script>


<style lang="less" scoped>
.edit-edit-topLeft{
   position:relative;
 }
 .edit-topLeft-campaign {
   position: relative;
   left: 72px;  
   bottom: 42px;
   margin-left: 5px;
   color: black;
   text-decoration: bold;
 }
 .edit-topLeft-link{
  position: relative;
  left: 0px;
  bottom: 40px;  
 }
 .top-right-buttons {
  margin-top: 35px;
 }

p {
  text-align: center;
  line-height: 100px;
}
.step {
 position: relative;
 width: 178px;
 height: 178px;
 align-items: center;
}
.step-button {
 width: 36px;
 background-color: #1E3941;
 border-radius: 50%;
 height: 36px;
 display: block;
 border: none;
 display: flex;
 justify-content: center;
 align-items: center;
 position: absolute;
 display:inline-block;
 background:
    linear-gradient(#fff,#fff),
    linear-gradient(#fff,#fff),
    #000;
 background-position:center;
 background-size: 50% 2px,2px 50%; 
 background-repeat:no-repeat;
 top: 50%;
 left: 50%;
 transform: translateX(-55%) translateY(-50%);
}
.step-rectangle {
 width: 81px;
 height: 28px;
 border-radius: 15px;
 background-color: #FFFFFF; 
 position:absolute;
 top: 11px;
 left: 4px;
 padding-right: 26px;
}

.step-circle {
 width: 28px;
 height: 28px;
 border-radius: 60%; 
 color: #FFFFFF;  
 background-color: purple;
 left: 31px;
 position:absolute;
 padding: 2px;
 padding-right: 4px;
 padding-left: 6px;
 left: 54px;
 text-align: center;
}

.video-img {
 width: 100%;
 height: 100%;
 display: block;
 margin:auto;
 object-fit: cover;
 border-radius: 10px;
 }
.layout-container {
 display: flex;
 flex-direction: column;
}

.layout-container .image-container {
 flex: 0 0 75vh;
 margin-top: 100px;
 position: relative;
}

.line {
 border-left: 3px solid #1E3941;
 height: 100px;
 display: block;
 margin:auto;
 bottom: -100px;
 left: 50%;
 transform: translateX(48%);
}
.add-button {
 width: 36px;
 background-color: #1E3941;
 border-radius: 50%;
 height: 36px;
 display: block;
 border: none;
 display: flex;
 justify-content: center;
 align-items: center;
 position: absolute;
 background:
    linear-gradient(#fff,#fff),
    linear-gradient(#fff,#fff),
    #000;
 background-position:center;
 background-size: 50% 2px,2px 50%; 
 background-repeat:no-repeat;
 top: 76%;
 left: 49.5%;
 transform:translateX(-52%) translateY(-192%);
}

.ending-btn {   
 width: 180px;
 height: 180px;
 object-fit: cover;
 border-radius: 10px;
 border: 1px solid;
 position: relative;
 }
</style>

