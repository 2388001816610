<template>
    <div class="permission-container" v-if='show'>
        <div class="permission-content">
            <div class="permission-body">
                <div class="header-text poppins-medium text-center mt-4">
                    Permission Required to Continue
                </div>
                <div class="lower-section d-flex flex-column align-items-center mt-5 pt-5">
                    <span @click="$emit('connect')">
                        <BaseIcons icon='videoCam' />
                    </span>
                    <div class="warning-text poppins-light">
                        <span>
                            Allow Your Cam + Mic
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <BaseButton
            title='Back'
            background='#BF96C1'
            hoverBg='#862D83'
            color='#fff'
            height='36px'
            width='147px'
            position='absolute'
            bottom='50px'
            right='50px'
            :loading='loading'
            @clicked='$emit("goBack")'
        /> -->
    </div>
</template>

<style lang="less" scoped>
    .permission-container {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #F5F8FA;
        z-index: 17;
        .permission-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .permission-body {
                background: #fff;
                width: 721.97px;
                height: 412.08px;
                border: 1px solid #c9edf8;
                border-radius: 10px;
                padding: 41px 48.5px 39.9px;
                position: relative;
                font-size: 14px;
                .header-text {
                    font-size: 17px;
                }
                .lower-section {
                    .warning-text {
                        font-size: 17px;
                    }
                }
            }
        }
    }

    @media (max-width: 960px) {
        .permission-container {
            .permission-content {
                .permission-body {
                    width: 90%;
                    height: max-content;
                    .lower-section {
                        margin-top: 0px !important;
                        padding-top: 0px !important;
                    }
                }
            }
        }
    }
</style>

<script>

export default {
    name: 'RecordPermission',
    props: ['show'],
    data: () => ({
        loading: false,
    }),
    mounted() {
        this.setEscapeKeyEvent();
    },
    methods: {
        setEscapeKeyEvent() {
            const $this = this;
            window.addEventListener('keyup', function (event) {
                if (event.key === 'Escape') {
                    $this.$emit('cancelRequest');
                }
            })
        },
    }
};
</script>