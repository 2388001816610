<template>
    <div class="upload-library">
        <!-- Library Header -->
        <div class="library-header d-flex justify-content-between">
            <div
                class="libary-header-title poppins-medium d-flex align-self-end general-text-size"
            >
                Library
            </div>
            <div class="import-options-container">
                <div
                    class="import-options-header d-flex justify-content-between"
                >
                    <BaseIcons icon="curvedLine" class="desktop-element" />
                    <BaseIcons icon="curvedLineMobile" class="mobile-element" />
                    <div
                        class="import-title general-text-size poppins-medium pl-1 pr-1"
                    >
                        IMPORT
                    </div>
                    <BaseIcons
                        icon="curvedLine"
                        class="desktop-element mirrored"
                    />
                    <BaseIcons
                        icon="curvedLineMobile"
                        class="mobile-element mirrored"
                    />
                </div>
                <div
                    class="import-options-titles d-flex justify-content-between general-text-size"
                >
                    <div
                        class="poppins-regular cursor-pointer"
                        @click="importSelect('adilo-youtube')"
                        :class="{
                            'poppins-medium': importOption === 'adilo-youtube',
                        }"
                    >
                        Adilo/Youtube
                    </div>
                    <div
                        class="poppins-regular cursor-pointer"
                        @click="importSelect('stock')"
                        :class="{ 'poppins-medium': importOption === 'stock' }"
                    >
                        Stock Videos
                    </div>
                    <div
                        class="poppins-regular cursor-pointer"
                        @click="importSelect('gif')"
                        :class="{ 'poppins-medium': importOption === 'gif' }"
                    >
                        Gifs
                    </div>
                    <div
                        class="poppins-regular cursor-pointer"
                        @click="importSelect('mp4')"
                        :class="{ 'poppins-medium': importOption === 'mp4' }"
                    >
                        MP4
                    </div>
                </div>
            </div>
        </div>
        <!-- Library Header -->

        <!-- Library Body -->
        <div class="library-body pt-5">
            <videos-grids
                v-if="
                    importOption === 'stock' ||
                        importOption === 'gif' ||
                        !importOption
                "
                :videos="videos"
                :loading="loading"
                @selectedVideo="$emit('selectedVideo', $event)"
                :classActive="activeClass"
                :activeVideoId="videoId"
                @query="searchVideos"
            />
            <video-url-player
                v-if="
                    importOption === 'adilo-youtube' || importOption === 'mp4'
                "
                :type="importOption"
                :options="{ switches }"
                @selectedVideo="$emit('selectedVideo', $event)"
            />
        </div>

        <uploader
            class="mt-4"
            v-if="importOption === 'stock' || !importOption"
            maxSize="209715200"
            @refresh="getVideos"
        />
        <!-- Library Body -->

        <!-- Library Footer Controls -->
        <div class="library-footer mt-5">
            <div class="footer-control">
                <div class="fit-video-actions d-flex">
                    <div class="action-text poppins pr-3">Fit video</div>
                    <switch-toggle
                        :active="switches.fitVideo"
                        @clicked="toggleSwitch('fitVideo')"
                        class="d-flex align-self-center"
                    />
                </div>
            </div>

            <div class="footer-control pt-5">
                <div class="action-text poppins">Overlay text</div>
                <textarea class="mt-3" v-model="overlay.text"> </textarea>
            </div>
            <div class="misc-options mt-2 flex-column">
                <div class="misc-option d-flex pb-2">
                    <div class="action-text poppins-medium pr-4">Font size</div>
                    <div class="action-trigger position-relative">
                        <drop-down-button
                            :midText="overlay.fontLabel"
                            rightIcon="downArrow"
                            background="#fff"
                            border-radius="5px"
                            color="#9E9E9E"
                            fontSize="15px"
                            fontFamily="Poppins-Medium"
                            width="159px"
                            height="45px"
                            @clicked="sizeDropdown = !sizeDropdown"
                        />
                        <select-dropdown
                            :show="sizeDropdown"
                            :options="fontSizes"
                            borderRadius="0px"
                            border="1px solid transparent"
                            width="100%"
                            top="60px"
                            left="0"
                            @selected="setOverlay"
                        />
                    </div>
                </div>
                <div class="misc-option d-flex pb-2">
                    <div class="action-text poppins-medium pr-4">
                        Darken video
                    </div>
                    <div class="action-trigger">
                        <switch-toggle
                            :active="switches.darkenVideo"
                            @clicked="toggleSwitch('darkenVideo')"
                            class="d-flex align-self-center mr-2"
                        />
                    </div>
                </div>
                <div class="misc-option d-flex pb-2">
                    <div class="action-text poppins-medium pr-4">
                        Font color
                    </div>
                    <div class="action-trigger">
                        <color-picker
                            :color="overlay.color"
                            @color="overlay.color = $event"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- Library Footer Controls -->
    </div>
</template>

<style lang="less">
.upload-library {
    .general-text-size {
        font-size: 17px;
    }
    .small-text {
        font-size: 11px;
        font-style: italic;
    }
    .library-header {
        .import-options-container {
            .import-options-header {
                .import-title {
                    position: relative;
                    bottom: 12px;
                }
            }
        }
    }

    .library-footer {
        .footer-control {
            .action-text {
                color: #6e8186;
                font-size: 18px;
            }
            textarea {
                width: 100%;
                height: 426px;
                resize: none;
                border-radius: 10px;
                border: 1px solid transparent;
                padding: 15px;
                outline: none;
            }
        }
        .misc-options {
            width: 100%;
            display: flex;
            align-items: flex-end;
            .misc-option {
                .action-text {
                    display: flex;
                    align-self: center;
                    font-size: 12px;
                    color: #6e8186;
                }
                .action-trigger {
                    width: 159px;
                    height: 45px;
                    background: #fff;
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .upload-library {
        .general-text-size {
            font-size: 12px;
        }
    }
}
</style>

<script>
import { FONT_SIZES } from "../../../assets/js/constants";
import SwitchToggle from "../../Common/Widgets/SwitchToggle";
import DropDownButton from "../../Common/DropDownButton";
import VideoUrlPlayer from "../VideoUrlPlayer";
import VideosGrids from "../VideosGrids";
import Uploader from "../Upload/Uploader";
import SelectDropdown from "../../Common/SelectDropdown";
import ColorPicker from "../../Common/Widgets/ColorPicker";

export default {
    name: "UploadLibrary",
    props: ["videos", "activeClass", "videoId", "videoCampaign"],
    data: () => ({
        switches: {
            fitVideo: false,
            darkenVideo: false,
        },
        importOption: null,
        loading: false,
        overlay: {
            text: "",
            color: "#00ACDC",
            fontSize: "3rem",
            fontLabel: "Regular",
        },
        sizeDropdown: false,
        fontSizes: FONT_SIZES,
        query: "",
    }),
    components: {
        SwitchToggle,
        DropDownButton,
        VideoUrlPlayer,
        VideosGrids,
        Uploader,
        SelectDropdown,
        ColorPicker,
    },
    mounted() {
        this.videoCampaigns();
        this.emitOptions();
        this.getVideos();
    },
    methods: {
        toggleSwitch(type) {
            this.switches[type] = !this.switches[type];
        },
        emitOptions() {
            const { switches } = this;
            this.$emit("options", {
                switches,
            });
        },
        importSelect(option) {
            const importOption = this.importOption;
            if (importOption === option) {
                this.importOption = "";
                this.getVideos();
                this.loading = false;
            } else {
                this.importOption = option;
                this.getData();
            }
        },
        getStockVideos(endpoint = "stock") {
            this.loading = true;
            const { query } = this;
            const keywords = query || "";
            this.$emit("videos", []);
            this.axios
                .get(`videos/${endpoint}?keywords=${keywords}`)
                .then((response) => {
                    this.loading = false;
                    const videos = response.data.videos;
                    if (videos && videos.length) {
                        this.$emit("videos", response.data.videos);
                    }
                });
        },
        getVideos() {
            this.$emit("dbVideos");
        },
        getData() {
            const importOption = this.importOption;
            switch (importOption) {
                case "stock":
                    this.getStockVideos();
                    break;
                case "gif":
                    this.getStockVideos("gif");
                    break;
                default:
                    this.getVideos();
                    break;
            }
        },
        setOverlay(overlay) {
            this.overlay.fontSize = overlay.id;
            this.overlay.fontLabel = overlay.label;
        },
        searchVideos(query) {
            this.query = query;
            this.getData();
        },
        videoCampaigns() {
            if (this.$props.videoCampaign) {
                this.overlay.text = this.$props.videoCampaign.overlay_text;
                this.switches.darkenVideo = this.$props.videoCampaign.darken_video;
                this.switches.fitVideo = this.$props.videoCampaign.fit_video;

                if (this.$props.videoCampaign.font_color != null) {
                    this.overlay.color = this.$props.videoCampaign.font_color;
                } else {
                    this.overlay.color = "#00ACDC";
                }

                if (this.$props.videoCampaign.font_size != null) {
                    this.overlay.fontSize = this.$props.videoCampaign.font_size;
                    const fontSize = this.fontSizes;

                    for (let i = 0; i < fontSize.length; i++) {
                        if (
                            fontSize[i].id ===
                            this.$props.videoCampaign.font_size
                        ) {
                            this.overlay.fontLabel = fontSize[i].label;
                        }
                    }
                } else {
                    this.overlay.fontSize = "3rem";
                    this.overlay.fontLabel = "Regular";
                }
            }
        },
    },
    watch: {
        switches() {
            this.emitOptions();
        },
        overlay: {
            handler(overlay) {
                this.$emit("overlay", overlay);
            },
            deep: true,
        },
        query() {
            this.getData();
        },
        videoCampaign: {
            handler() {
                this.videoCampaigns();
            },
            deep: true,
        },
    },
};
</script>
