<template>
    <div class="base-input-container" :class="notValidClass">
        <input
            :type="type"
            :placeholder="placeholder"
            :name='name'
            class="form-control base-input"
            :class='className'
            v-model="value"
            :id='inputId'
            @input='sendValue'
            @keypress='ifKeyPressed'
            :disabled='disabled'
            :style='{
                borderRadius,
                border,
                borderStyle,
                background,
                width,
                opacity,
                padding,
                color,
                textAlign,
            }'>
        <div class="input-icon" @click="iconClicked" :class="{'mobile-show': mobileOnlyIcon}">
            <base-icons :icon='icon'/>
        </div>
        <span class="error-text" v-if='error'>
            <div v-if='typeof error === "string"'>
                <errors
                    :errors='[error]'
                    :show='error'
                    color='#EE5951'
                />
            </div>
            <div v-else-if="typeof error === 'boolean'">
                <div class="default-red-text">This field is required</div>
            </div>
        </span>
    </div>
</template>

<style lang="less">
    .base-input-container {
        position: relative;
        .input-icon {
            position: absolute;
            right: 15px;
            top: 5px;
            margin: auto;
        }
        .mobile-show {
            display: none;
        }
        .error-text {
            color: #EE5951;
        }
    }
    @media (max-width: 960px) {
        .mobile-show {
            display: block !important;
        }
    }
</style>

<script>
import Errors from '../Errors';

export default {
    name: 'BaseInput',
    props: [
        'type',
        'placeholder',
        'icon',
        'inputId',
        'className',
        'name',
        'keyTrigger',
        'mobileOnlyIcon',
        'inputValue',
        'error',
        'color',
        'border',
        'borderRadius',
        'borderStyle',
        'background',
        'width',
        'opacity',
        'padding',
        'disabled',
        'textAlign'
    ],
    components: {
        Errors,
    },
    data: () => ({
        value: '',
        notValidClass: '',
    }),
    mounted() {
        this.getValue();
    },
    methods: {
        getValue() {
            const value = this.$props.inputValue;
            this.value = value;
        },
        sendValue() {
            this.$emit('value', this.value);
        },
        ifKeyPressed(event) {
            const key = event.key;
            const trigger = this.$props.keyTrigger;
            if (key === trigger) {
                this.$emit('keyPressed');
            }
        },
        iconClicked() {
            this.$emit('iconClicked');
        }
    },
    watch: {
        error(error) {
            this.notValidClass = error ? 'red-border' : '';
        }
    }
};
</script>