<template>
    <transition name="slide-fade">
        <div class="integration-modal" v-if="show">
            <div class="integration-modal-container">
                <div class="integration-modal-content">
                    <div
                        class="integration-modal-head d-flex justify-content-between p-3"
                    >
                        <div class="back-icon">
                            <BaseIcons
                                icon="downArrow"
                                v-if="showForm"
                                @clicked="showForm = false"
                            />
                        </div>
                        <div class="modal-title">
                            NEW INTEGRATION
                        </div>
                        <BaseIcons
                            icon="modalCross"
                            @clicked="$emit('close')"
                        />
                    </div>
                    <integration-listing
                        v-if="!showForm"
                        :integrationsList="integrationsList"
                        :navbarItems="navbarItems"
                        :selected="selected"
                        @query="search"
                        @categories="categories"
                        @selected="selected = $event"
                    />
                    <integration-form
                        :displayName="displayName"
                        :selected="selected"
                        @name="displayName = $event"
                        @credentials="credentialData = $event"
                        v-else
                    />
                    <div
                        class="integration-modal-foot d-flex justify-content-center"
                    >
                        <BaseButton
                            title="Cancel"
                            background="#21455E"
                            color="#fff"
                            class="mr-3"
                            borderRadius="20px"
                            width="100px"
                            height="40px"
                            @clicked="$emit('close')"
                            :loading="loading"
                        />
                        <BaseButton
                            title="Continue"
                            background="#00ACDC"
                            color="#fff"
                            borderRadius="20px"
                            width="100px"
                            height="40px"
                            @clicked="continueProcess"
                            :loading="loading"
                        />
                    </div>
                </div>
            </div>
            <messages-box
                :messages="error"
                background="#0DABD8"
                @closeMessages="error = []"
            />
        </div>
    </transition>
</template>

<style lang="less">
.integration-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 15;
    overflow: auto;
    .integration-modal-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .integration-modal-content {
            background: #fff;
            width: 736px;
            border: 1px solid #0dabd8;
            border-radius: 10px;
            position: relative;
            font-size: 14px;
            padding-bottom: 50px;
            .selected {
                background: #eef1f6;
            }
            .integration-modal-head {
                border-bottom: 1px solid #e4e8f1;
                font-size: 20px;
                .back-icon {
                    transform: rotate(90deg);
                }
            }
            .integration-modal-body {
                padding-bottom: 50px;
                .integration-menu {
                    .navbar-item {
                        font-size: 14px;
                        font-weight: 501;
                        padding: 10px 5px;
                        width: 140px;
                        cursor: pointer;
                        &:hover {
                            background: #eef1f6;
                        }
                    }
                }
                .integrations-list {
                    width: 80%;
                    padding-right: 20px;
                    .integrations-list-head {
                        border-left: 1px solid #e4e8f1;
                    }
                    .integrations-list-body {
                        border: 1px solid #e4e8f1;
                        width: 100%;
                        height: 370px;
                        overflow: auto;
                        .list-item {
                            padding: 30px 0;
                            transition: 0.3s;
                            font-weight: 501;
                            cursor: pointer;
                            .integration-name {
                                text-align: left;
                            }
                            .integration-icon {
                                width: 40px;
                                height: 40px;
                            }
                            .integration-description {
                                width: 200px;
                            }
                            &:hover {
                                background: #e4e8f1;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .integration-modal {
        .integration-modal-container {
            width: 95%;
            left: 0;
            right: 0;
            margin: auto;
            .integration-modal-content {
                .integration-modal-body {
                    flex-direction: column;
                    .integration-menu {
                        display: flex;
                        padding: 10px !important;
                        margin-top: 0px !important;
                        justify-content: center;
                        .navbar-item {
                            padding: 5px 10px !important;
                            text-align: center;
                            margin-bottom: 0px !important;
                            width: max-content;
                        }
                    }
                    .integrations-list {
                        padding-right: 0px !important;
                        width: 100%;
                        padding-top: 15px !important;
                    }
                }
            }
        }
    }
}
</style>

<script>
import {
    INTEGRATIONS_NAVBAR,
    INTEGRATIONS,
} from "../../../../assets/js/constants";
import MessagesBox from "../../../Common/MessagesBox";
import IntegrationListing from "./IntegrationListing";
import IntegrationForm from "./IntegrationForm";

export default {
    name: "IntegrationModal",
    props: ["show"],
    data: () => ({
        navbarItems: INTEGRATIONS_NAVBAR,
        integrationsList: INTEGRATIONS,
        selected: null,
        showForm: false,
        error: "",
        displayName: "",
        credentialData: {},
        loading: false,
    }),
    components: {
        MessagesBox,
        IntegrationListing,
        IntegrationForm,
    },
    methods: {
        categories(category) {
            const list = INTEGRATIONS;
            if (category === "all") {
                this.integrationsList = list;
            } else {
                this.integrationsList = list.filter(
                    (el) => el.parent === category
                );
            }
        },
        search(query) {
            const list = INTEGRATIONS;
            query = query.toLowerCase();
            this.integrationsList = list.filter((el) => {
                const name = el.name.toLowerCase();
                const description = el.description.toLowerCase();
                return name.includes(query) || description.includes(query);
            });
        },
        continueProcess() {
            const selected = this.selected;
            if (!selected) {
                return (this.error = "Please select a integration to continue");
            }
            if (!this.showForm) {
                this.showForm = true;
            } else {
                if (this.displayName) {
                    this.triggerAuthentication();
                }
            }
        },
        triggerAuthentication() {
            const name = this.displayName;
            const selected = this.selected;
            if (typeof selected.auth === "string") {
                const url = `${process.env.VUE_APP_BACKEND_URL}${selected.auth}?display_name=${name}`;
                const loginWindow = window.open(
                    url,
                    "_blank",
                    "width=700px,height=700px"
                );
                this.handleOauth(loginWindow);
            } else {
                this.handleApiCredentials(selected);
            }
        },
        handleOauth(loginWindow) {
            let timer = setInterval(() => {
                if (loginWindow.closed) {
                    clearInterval(timer);
                    if (sessionStorage.getItem("integration-active")) {
                        let active_status = sessionStorage.getItem(
                            "integration-active"
                        );
                        if (active_status === "true") {
                            this.error = `${this.name} connected`;
                        } else {
                            this.error = "Failed to connect";
                        }
                    } else {
                        this.error = "Failed to connect";
                    }
                }
            }, 200);
        },
        handleApiCredentials(integration) {
            const data = this.credentialData;
            data.display_name = this.displayName;

            const url = integration.auth.action;
            console.log(url);
            this.loading = true;
            this.axios
                .post(url, data)
                .then((response) => {
                    this.loading = false;
                    if (response.data.result) {
                        const success = response.data.result === "success";
                        if (!success) {
                            this.error =
                                response.data.message || "Failed to connect";
                        } else {
                            this.error = `${data.display_name} Connected`;
                            window.open(
                                response.data.authorizationUr,
                                "_blank"
                            );
                            this.$emit("close");
                            this.$emit("list");
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = "Failed to connect";
                    console.error(error);
                });
        },
    },
};
</script>
