<template>
    <transition name='fade'>
        <div class="video-preview">
            <video
                ref='preview'
                :src='srcUrl'
                @click="pause"
                @timeupdate="updateDuration"
            />
            <canvas class='hidden position-fixed'></canvas>

            <div class="play-button" v-if='!playing && !loading'>
                <transition name="slide-fade">
                    <BaseIcons icon='play'  @clicked="play" />
                </transition>
            </div>

            <div class="editor-lower-controls flex-column justify-content-center">
                <timer
                    :hours='currentTime.hours'
                    :minutes='currentTime.minutes'
                    :seconds='currentTime.seconds'
                    :static='true'
                    class="mobile-element"
                />
                <!-- <video-seeker
                    @position='jumpToDuration'
                    :controls='true'
                    class="editor-seeker"
                /> -->
                <div class="cut-trim-controls d-flex justify-content-center">
                    <timer
                        :hours='currentTime.hours'
                        :minutes='currentTime.minutes'
                        :seconds='currentTime.seconds'
                        :static='true'
                        class="desktop-element"
                    />
                    <!-- <div class="edit-icon-container d-flex">
                        <div class="icon trim-button flex-column">
                            <BaseIcons icon='trim' class="desktop-element" />
                            <BaseIcons icon='trimMobile' class="mobile-element" />
                            <div class="icon-text poppins-medium">TRIM</div>
                        </div>
                        <div class="icon cut-button flex-column">
                            <BaseIcons icon='cut' class="desktop-element" />
                            <BaseIcons icon='cutMobile' class="mobile-element" />
                            <div class="icon-text poppins-medium">CUT</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .video-preview {
        video {
            position: absolute;
            left: 0;
            top: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            z-index: 1;
            // object-fit: cover;
            background: black;
        }
        .play-button {
            position: absolute;
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            z-index: 18;
        }
        .editor-lower-controls {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            bottom: 20px;
            z-index: 18;
            .editor-seeker {
                width: 75%;
                position: relative;
                margin: 70px 0 70px 0;
            }
            .cut-trim-controls {
                .icon {
                    background: #4E6269;
                    width: 118px;
                    height: 94px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.3s;
                    &:hover {
                        background: #1E3941;
                    }
                    .icon-text {
                        font-size: 20px;
                        color: #fff;
                    }
                }
                .edit-icon-container {
                    margin-left: 50px;
                    .trim-button {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }
                    .cut-button {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }
        }
    }

    @media (max-width: 960px) {
        .video-preview {
            .editor-lower-controls {
                bottom: 80px;
                .editor-seeker {
                    width: 75%;
                    position: relative;
                    margin: 35px 0 35px 0;
                }
                .cut-trim-controls {
                    .icon {
                        width: 87px;
                        height: 70px;
                        .icon-text {
                            font-size: 15px !important;
                        }
                    }
                    .edit-icon-container {
                        margin-left: 0px !important;
                    }
                }
            }
        }
    }
</style>

<script>
// import VideoSeeker from './VideoSeeker';
import Timer from '../Common/Widgets/Timer';

export default {
    name: 'VideoEditor',
    props: ['srcUrl', 'autoplay', 'loading'],
    components: {
        // VideoSeeker,
        Timer,
    },
    data: () => ({
        playing: false,
        currentTime: {},
    }),
    mounted() {
        // this.checkAutoplay();
        this.setEndEvent();
    },
    methods: {
        checkAutoplay() {
            if (this.$props.autoplay) {
                const player = this.$refs.preview;
                if (player.paused) {
                    player.muted = false;
                    player.volume = 1;
                    player.play();
                    this.playing = true;
                }
            }
        },
        setEndEvent() {
            const player = this.$refs.preview;
            player.onended = () => {
                this.playing = false;
            };
        },
        pause() {
            const player = this.$refs.preview;
            this.playing = false;
            player.pause();
        },
        play() {
            const player = this.$refs.preview;
            this.playing = true;
            player.play();
            // this.getScreenshot();
        },
        jumpToDuration(percentage) {
            const video = this.$refs.preview;
            const videoTime =  video.duration * percentage;
            if (video.currentTime > 0) video.currentTime = isFinite(videoTime) ? videoTime : 0;
        },
        updateDuration() {
            const video     = this.$refs.preview;
            const currentTime   = Math.round(video.currentTime);

            let seconds = parseInt(currentTime);
            const hours = Math.floor(seconds / 60 / 60);
            const minutes = Math.floor(seconds / 60);

            seconds = Math.floor(seconds % 60);

            this.currentTime = { hours, minutes, seconds };
        },
        // getScreenshot() {
        //     const video = document.querySelector('video');
        //     const canvas = document.createElement('canvas');
        //     console.log({video, canvas});
        //     if (!video || !canvas) return false;

        //     const ctx = canvas.getContext('2d');
        //     const width = window.innerWidth;
        //     const height = window.innerHeight;
        //     ctx.drawImage(video, 0, 0, width, height);
            
        //     const imageBlob = canvas.toDataURL('image/jpeg');
        //     console.log(imageBlob);
        //     this.$emit('image', imageBlob);
        // }
    }
};
</script>