<template>
    <div class="profile-password">
        <div class="outer-header mb-4">PASSWORD</div>
        <div class="profile-personal-inputs">
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="currPassword">Current Password</label>
                    <BaseInput
                        v-if='userData'
                        :inputValue='userData.old_password'
                        inputId='currPassword'
                        type='password'
                        placeholder='Enter Current Password'
                        :error='errors && errors.old_password ? errors.old_password : false'
                        @value='updateInput("old_password", $event)'
                    />
                </div>
                <div class="flex-column form-input"></div>
            </div>
            <div class="d-flex justify-content-between form-group">
                <div class="flex-column form-input">
                    <label class="base-label" for="password">New Password</label>
                    <BaseInput
                        v-if='userData'
                        :inputValue='userData.password'
                        inputId='password'
                        type='password'
                        placeholder='Enter New Password'
                        :error='errors && errors.password ? errors.password : false'
                        @value='updateInput("password", $event)'
                    />
                </div>
                <div class="flex-column form-input">
                    <label class="base-label" for="confirmPassword">Confirm Password</label>
                    <BaseInput
                        v-if='userData'
                        :inputValue='userData.confirm_password'
                        inputId='confirmPassword'
                        type='password'
                        :error='errors && errors.confirm_password ? errors.confirm_password : false'
                        placeholder='Confirm New Password'
                        @value='updateInput("confirm_password", $event)'
                    />
                </div>
            </div>
        </div>
        <div class="profile-password-footer">
            <password-checker
                class="mt-4"
                v-if='userData'
                :password='userData.password'
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .profile-password {
        .outer-header {
            font-weight: 700;
            font-size: 13px;
        }
        .profile-personal-inputs {
            .form-group {
                .form-input {
                    width: 45%;
                    position: relative;
                }
            }
        }
    }
    @media (max-width: 960px) {
        .profile-password {
            .profile-personal-inputs {
                .form-group {
                    flex-direction: column;
                    .form-input {
                        width: 100%;
                    }
                    .login-details {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
</style>

<script>
import PasswordChecker from './PasswordChecker';

export default {
    name: 'ProfilePassword',
    props: ['user', 'errors'],
    components: {
        PasswordChecker,
    },
    data: () => ({
        userData: null,
    }),
    mounted() {
        this.getUserData();
    },
    methods: {
        getUserData() {
            this.userData = this.$props.user;
        },
        updateInput(column, value) {
            const userData = this.userData;
            if (userData) {
                userData[column] = value;
            }
            this.$emit('user', userData);
            this.userData = userData;
        }
    }
}
</script>