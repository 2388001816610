<template>
    <transition name="fade">
        <div class="create-campaign poppins-regular">
            <div class="cross-icon flex-column" @click='showWarning = true'>
                <BaseIcons icon='cross' />
                <div class="icon-text poppins-medium">ESC</div>
            </div>
            <div class="create-campaign-content flex-column">
                <div class="flex-column section">
                    <div class="creation-title pb-5 poppins-regular">
                        Enter campaign name
                    </div>
                    <BaseInput
                        border='1px solid #707070'
                        borderStyle='none none solid none !important'
                        borderRadius='0px !important'
                        :inputValue='campaignName'
                        @value='campaignName = $event'
                        :error='campaignNameError'
                        keyTrigger='Enter'
                        @keyPressed='processRequest'
                    />
                </div>
                <div class="create-campaign-footer d-flex justify-content-between">
                    <div></div>
                    <BaseButton
                        title='Continue'
                        background='#862D83'
                        color='#fff'
                        height='36px'
                        width='147px'
                        :loading='loading'
                        @clicked='processRequest'
                    />
                </div>
            </div>
            <div class="campaign-modals">
                <campaign-warning @clicked='handleWarningConfirm' />
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .create-campaign {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 15;
        background: #F5F8FA;
        overflow: auto;
        .cross-icon {
            position: absolute;
            right: 25px;
            top: 25px;
            color: #862D83;
            font-size: 17px;
            cursor: pointer;
        }
        .create-campaign-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .section {
                width: 70%;
            }
            .create-campaign-footer {
                width: 70%;
                top: 30px;
                position: relative;
            }
        }
    }
    @media (max-width: 960px) {
        .create-campaign {
            .cross-icon {
                .icon-text {
                    display: none;
                }
            }
            .create-campaign-content {
                .create-campaign-footer {
                    width: 70%;
                    position: relative;
                    top: 80px !important;
                }
            }
        }
    }
</style>

<script>
import CampaignWarning from '../../components/Projects/CampaignWarning';

export default {
    name: 'CreateCampaign',
    components: {
        CampaignWarning,
    },
    data: () => ({
        loading: false,
        campaignName: '',
        campaignNameError: '',
        showWarning: false,
    }),
    mounted() {
        this.setEscapeKeyEvent();
    },
    methods: {
        processRequest() {
            const campaign = this.campaignName;
            if (!campaign) {
                this.campaignNameError = 'Please enter a campaign name';
                return false;
            }
            this.campaignNameError = '';
            const projectId = this.$route.params.id;
            const title = campaign;
            this.sendCampaignSaveRequest(projectId, title);
        },
        setEscapeKeyEvent() {
            const $this = this;
            window.addEventListener('keyup', function (event) {
                if (event.key === 'Escape') {
                    $this.showWarning = true;
                }
            })
        },
        sendCampaignSaveRequest(project_id, title) {
            const url = '/campaigns';
            this.loading = true;
            this.axios.post(url, { project_id, title })
                .then(response => {
                    if(response.data.success == false){
                        this.campaignNameError = response.data.error;
                        this.loading = false;
                    }else{  
                        this.handleSaveCampaignResponse(response.data);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                })
            
        },
        handleSaveCampaignResponse(data) {
            console.log(data);
            if (data.success) {
                const id = data.campaign.campaign_id;
                const current_Step=0;
                this.$router.push({
                    path: `/campaigns/${id}/${current_Step}/create`,
                });
            }
        },
        reset() {
            this.campaignName = '';
            this.campaignNameError = '';
            this.videoOptions = false;
        },
        handleWarningConfirm(response) {
            const goBack = response === 'yes';
            this.showWarning = false;
            if (goBack) {
                const projectId = this.$route.params.id;
                this.$router.push({
                    path: `/projects/${projectId}`
                });
            }
        }
    },
    watch: {
        show(showing) {
            if (!showing) {
                this.reset();
            }
        }
    }
};
</script>