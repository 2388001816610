<template>
    <transition name='slide-fade'>
        <div class="payment-info-modal" v-if='show'>
            <div class="payment-info-container">
                <div class="payment-info-content">
                    <div class="payment-info-head d-flex justify-content-between base-divider">
                        <div></div>
                        <div class="head-title p-3 helvetica-medium">
                            PAYMENT INFORMATION
                        </div>
                        <BaseIcons icon='modalCross' class="p-3" @clicked='$emit("close")'/>
                    </div>
                    <div class="payment-info-body base-divider">
                        <div class="payment-info-form">
                            <div class="form-header helvetica-medium">
                                Contact Billing Information
                            </div>
                            <div class="customer-details mt-5 base-divider pb-5">
                                <div class="d-flex">
                                    <div class="flex-column">
                                        <div class="helvetica-medium">{{ subscriptionData.name }}</div>
                                        <div class="pt-2">{{ subscriptionData.email }}</div>
                                    </div>
                                    <div class="flex-column pl-5" v-if='subscriptionData.settings'>
                                        <span>{{ subscriptionData.settings.city }}</span>
                                        <span v-if="subscriptionData.settings.state">, {{ subscriptionData.settings.state }}</span>
                                        <span v-if="subscriptionData.settings.country">, {{ subscriptionData.settings.country }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-fields mt-3">
                                <div class="form-row d-flex justify-content-between">
                                    <div class="form-group">
                                        <label for="phone" class="d-flex justify-content-between">
                                            <div class="base-label">Phone</div>
                                        </label>
                                        <BaseInput
                                            inputId='phone'
                                            @value='setValue($event, "phone")'
                                            :inputValue='subscriptionData.phone'
                                            type='number'
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="billing_address" class="d-flex justify-content-between">
                                            <div class="base-label">Billing Address</div>
                                            <div>required</div>
                                        </label>
                                        <BaseInput
                                            inputId='billing_address'
                                            @value='setValue($event, "billing_address")'
                                            :inputValue='subscriptionData.billing_address'
                                            :error='errors.billing_address'
                                        />
                                    </div>
                                </div>
                                <div class="form-row d-flex justify-content-between">
                                    <div class="form-group">
                                        <label for="billing_address_line_2" class="d-flex justify-content-between">
                                            <div class="base-label">Billing Address 2</div>
                                        </label>
                                        <BaseInput
                                            inputId='billing_address_line_2'
                                            @value='setValue($event, "billing_address_line_2")'
                                            :inputValue='subscriptionData.billing_address_line_2'
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="billing_country" class="d-flex justify-content-between">
                                            <div class="base-label">Billing Country</div>
                                            <div>required</div>
                                        </label>
                                        <BaseInput
                                            inputId='billing_country'
                                            @value='setValue($event, "billing_country")'
                                            :inputValue='subscriptionData.billing_country'
                                            :error='errors.billing_country'
                                        />
                                    </div>
                                </div>
                               <div class="form-row d-flex justify-content-between">
                                    <div class="form-group">
                                        <label for="billing_city" class="d-flex justify-content-between">
                                            <div class="base-label">Billing City</div>
                                            <div>required</div>
                                        </label>
                                        <BaseInput
                                            inputId='billing_city'
                                            @value='setValue($event, "billing_city")'
                                            :inputValue='subscriptionData.billing_city'
                                            :error='errors.billing_city'
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="billing_zip" class="d-flex justify-content-between">
                                            <div class="base-label">Billing Zip</div>
                                            <div>required</div>
                                        </label>
                                        <BaseInput
                                            inputId='billing_zip'
                                            @value='setValue($event, "billing_zip")'
                                            :inputValue='subscriptionData.billing_zip'
                                            type='number'
                                            :error='errors.billing_zip'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="payment-info-foot text-center d-flex justify-content-center p-3">
                        <BaseButton
                            title='Save'
                            background='#0DABD8'
                            color='#fff'
                            borderRadius='18px'
                            class='p-2'
                            width='100px'
                            fontFamily='Helvetica Neue-Medium'
                            :loading='loading'
                            height='40px'
                            @clicked='updateUserDetails'
                        />
                        <BaseButton
                            title='Cancel'
                            background='#707070'
                            color='#fff'
                            class='ml-3 p-2'
                            borderRadius='18px'
                            width='100px'
                            fontFamily='Helvetica Neue-Medium'
                            :loading='loading'
                            height='40px'
                            @clicked='$emit("close")'
                        />
                    </div>
                </div>
            </div>
            <messages-box
                v-if='message'
                :messages='message'
                @closeMessages='message = null'
                background='#0DABD8'
            />
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .payment-info-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        overflow: auto;
        .payment-info-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .payment-info-content {
                background: #fff;
                width: 700px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                position: relative;
                font-size: 14px;
                .payment-info-head {
                    font-size: 16px;
                }
                .payment-info-body {
                    .payment-info-form {
                        padding: 50px 90px;
                        .form-header {
                            font-size: 20px;
                        }
                        .customer-details {
                            font-size: 16px;
                        }
                        .form-fields {
                            .form-group {
                                width: 48%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<script>
import validator from 'validator';
import MessagesBox from '../../../../Common/MessagesBox';

export default {
    name: 'PaymentInfoModal',
    props: ['subscriptionData', 'show'],
    components: {
        MessagesBox,
    },
    data: () => ({
        formData: {},
        loading: false,
        errors: {},
        inputValidate: false,
        userId: null,
        message: '',
    }),
    mounted() {
        this.populateFormData();
    },
    methods: {
        populateFormData() {
            const formData = this.$props.subscriptionData;
            if (formData) {
                this.userId = formData.id;
                this.formData = {
                    phone: formData.phone,
                    billing_address: formData.billing_address,
                    billing_address_line_2: formData.billing_address_line_2,
                    billing_country: formData.billing_country,
                    billing_city: formData.billing_city,
                    billing_zip: formData.billing_zip,
                }
            }
        },
        setValue(value, field) {
            const { formData } = this;
            formData[field] = value;
            this.formData = formData;
        },
        updateUserDetails() {
            const validated = this.validate();
            const postData = this.formData;
            if (validated) {
                const url = `billing/update-billing-information/${this.userId}`;
                this.loading = true;
                this.axios.post(url, postData)
                    .then(response => {
                        this.loading = false;
                        if (!response.data.success) {
                            this.message = 'Failed to update';
                        } else {
                            this.message = 'Details updated';
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.loading = false;
                    })
            }
        },
        validate() {
            const errors = {};
            const formData = this.formData;
            if (validator.isEmpty(formData.billing_address)) errors.billing_address = true;
            if (validator.isEmpty(formData.billing_country)) errors.billing_country = true;
            if (validator.isEmpty(formData.billing_city)) errors.billing_city = true;
            if (validator.isEmpty(formData.billing_zip)) errors.billing_zip = true;
            this.errors = errors;
            this.inputValidate = true;
            return Object.keys(errors).length < 1;
        }
    },
    watch: {
        subscriptionData: {
            handler() {
                this.populateFormData();
            },
            deep: true,
        },
        formData: {
            handler() {
                if (this.inputValidate) {
                    this.validate();
                }
            },
            deep: true,
        }
    }
}
</script>