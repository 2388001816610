<template>
    <div class="profile-timezone">
        <div class="outer-header mb-4">ACCOUNT TIMEZONE</div>
        <div class="d-flex form-group justify-content-between">
            <div class="flex-column form-input">
                <label class="base-label" for="timezone">Timezone</label>
                <custom-selector
                    v-if='userData'
                    listId='code'
                    listLabel='name'
                    :list='timezonesList'
                    :selected='userData.settings.timezone'
                    @option='updateInput("timezone", $event.code)'
                />
            </div>
            <div></div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .profile-timezone {
        .outer-header {
            font-weight: 700;
            font-size: 13px;
        }
        .form-input {
            width: 45%;
            position: relative;
        }
    }
    @media (max-width: 960px) {
        .profile-timezone {
            .form-group {
                flex-direction: column;
                .form-input {
                    width: 100%;
                }
                .login-details {
                    margin-top: 20px;
                }
            }
        }
    }
</style>

<script>
import timezones from '../../../assets/js/timezones';
import CustomSelector from '../../Common/CustomSelector';

export default {
    name: 'ProfileTimezone',
    props: ['user'],
    components: {
        CustomSelector
    },
    data: () => ({
        timezonesList: timezones,
        userData: null,
    }),
    mounted() {
        this.getUserDetails();
    },
    methods: {
        getUserDetails() {
            this.userData = this.$props.user;
        },
        updateInput(column, value) {
            const userData = this.userData;
            if (userData) {
                userData.settings[column] = value;
            }
            this.$emit('user', userData);
            this.userData = userData;
        }
    }
}
</script>