<template>
    <div class="detail-section position-relative">
        <div class="column-title pt-4 pb-4">INTEGRATION</div>
        <div class="integration-name">
            {{ integration.service_name }}: {{ integration.display_name }}
        </div>
        <div class="integration-date">
            Integrated: {{ integration.integrated_at }}
        </div>
        <div
            class="disconnect-button mt-2 poppins"
            @click="showDropdown = true"
        >
            <span class="dots">...</span>
        </div>
        <select-dropdown
            :options="menu"
            :show="showDropdown"
            width="100%"
            @close="showDropdown = false"
            @selected="processOption"
        />
    </div>
</template>

<script>
import SelectDropdown from "../SelectDropdown";

export default {
    name: "IntegrationCardDetails",
    props: ["integration"],
    components: {
        SelectDropdown,
    },
    data: () => ({
        showDropdown: false,
        menu: [
            {
                id: "disconnect",
                label: "Disconnect",
                color: "#a94442",
            },
        ],
    }),
    mounted() {
        this.ifZapier();
    },
    methods: {
        ifZapier() {
            const integration = this.$props.integration;
            if (integration && integration.service_name === "zapier") {
                this.menu.push({
                    id: "add_app",
                    label: "Add App",
                    url: "google.com",
                });
            }
        },
        processOption(option) {
            this.showDropdown = false;
            this.$emit("process", {
                type: option.id,
                integration: this.$props.integration,
            });
        },
    },
};
</script>
