<template>
    <div class='request-message-container'>
        <div class="loading-request">
            <base-loader :showLoader='loading' width='200px' height='200px' />
            <h4 class='text-center'>{{ message }}</h4>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .request-message-container {
        .loading-request {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 200px;
        }
    }
</style>

<script>
export default {
    name: 'EmailUpdate',
    data: () => ({
        message: 'Processing Request...',
        loading: false,
    }),
    mounted() {
        this.getRequest();
    },
    methods: {
        getRequest() {
            this.loading = true;
            const hash = this.$route.params.hash;
            if (!hash) {
                this.$router.push('/');
            } else {
                this.axios.get(`email-update/${hash}`).then(
                    response => {
                        if (response.data.message) {
                            this.message = response.data.message;
                        }
                        if (response.data.success) {
                            setTimeout(() => {
                                this.$router.push('/');
                            }, 5000);
                        } else {
                            setTimeout(() => {
                                this.$router.push('/');
                            }, 5000);
                        }
                    }
                ).catch(
                    error => {
                        console.log(error);
                        this.message = 'Failed to process request, please try again later.';
                        setTimeout(() => {
                            this.$router.push('/');
                        }, 5000);
                    }
                )
            }
        }
    }
}
</script>