var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button-container btn d-flex align-items-center",style:({
        background: _vm.hoverBg && _vm.showHoverBg ? _vm.hoverBg : _vm.background,
        color: _vm.hoverColor && _vm.showHoverBg ? _vm.hoverColor : _vm.color,
        borderRadius: _vm.borderRadius,
        width: _vm.width,
        height: _vm.height,
        padding: _vm.padding,
        border: _vm.border,
        fontFamily: _vm.fontFamily,
        fontSize: _vm.fontSize,
        position: _vm.position ? _vm.position : 'relative',
        opacity: _vm.opacity,
        top: _vm.top,
        right: _vm.right,
        left: _vm.left,
        bottom: _vm.bottom,
        zIndex: _vm.zIndex,
    }),on:{"click":_vm.clicked,"mouseover":function($event){_vm.showHoverBg = true},"mouseleave":function($event){_vm.showHoverBg = false}}},[(!_vm.loading && _vm.title)?_c('div',{staticClass:"text text-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(!_vm.loading && _vm.icon)?_c('div',[_c('BaseIcons',{attrs:{"icon":_vm.icon}})],1):_c('div',[_c('BaseLoader',{attrs:{"showLoader":_vm.loading,"centered":true,"color":"#fff"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }