<template>
    <div class="text-container">
        <div class="text">
            © {{ year }} All Rights Reserved. {{ company }} Inc.
        </div>
    </div>
</template>

<style lang="less" scoped>
    .text-container {
        width: max-content;
        .text {
            color: #A3BAC6;
            font-size: 14px;
            font-family: "Helvetica Neue";
        }
    }
</style>

<script>
import moment from 'moment';
export default {
    name: 'CompanyRightsText',
    data: () => ({
        year: moment().format('Y'),
        company: process.env.VUE_APP_COMPANY_NAME,
    })
}
</script>