<template>
    <div
        class="radio-container d-flex cursor-pointer"
        @click="$emit('clicked')"
    >
        <div class="radio">
            <div class="inner" v-if="selected"></div>
        </div>
        <div class="radio-label helvetica-medium pl-1" v-if="label">
            {{ label }}
        </div>
    </div>
</template>

<style lang="less" scoped>
.radio-container {
    .radio {
        align-self: center;
    }
}
</style>

<script>
export default {
    name: "BaseRadio",
    props: ["selected", "label"],
};
</script>
