<template>
    <transition name="slide-fade">
        <div class="video-lower-controls" v-if='show'>
            <div class="video-lower-content d-flex justify-content-center w-100">
                <div></div>
                <div class="record-btn" :class='buttonClass' @mouseenter="buttonClass = 'faded'" @mouseleave="buttonClass = ''">
                    <div v-if='!recording'>
                        <BaseIcons
                            class='icon'
                            icon="recordingBigBtn"
                            @clicked='$emit("startRecording")'
                        />
                    </div>
                    <div v-else>
                        <timer
                            :minutes='true'
                            :seconds='true'
                            :status='recording'
                            width='115px'
                            @totalDuration='$emit("totalDuration", $event)'
                        />
                        <div v-if='buttonClass === "faded"'>
                            <div class="tooltip-message">
                                Stop Recording
                            </div>
                        </div>
                        <BaseIcons
                            class='icon'
                            icon="stopRecording"
                            @clicked='$emit("stopRecording")'
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .video-lower-controls {
        .faded {
            .icon {
                opacity: 0.5;
            }
        }
        .video-lower-content {
            .record-btn {
                transition: 0.1s;
                position: relative;
                display: flex;
                justify-content: center;
                &:hover {
                    transform: scale(1.04);
                }
                &:active {
                    transform: scale(1);
                }
                .tooltip-message {
                    position: relative;
                    color: #fff;
                    background: #1E3941;
                    color: rgba(255, 255, 255, 0.685);
                    width: max-content;
                    border-radius: 5px;
                    padding: 10px 15px;
                    bottom: 10px;
                    &::before {
                        content: "";
                        width: 0;
                        height: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        bottom: -8px;
                        z-index: 4;
                        position: absolute;
                        transform: rotate(180deg);
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid #1E3941;
                    }
                }
                .timer-container {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media (max-width: 960px) {
        .video-lower-controls {
            .record-btn {
                position: relative;
                bottom: 4.3em;
            }
        }
    }
</style>

<script>
import Timer from '../Common/Widgets/Timer';

export default {
    name: 'VideoLowerControls',
    props: ['show', 'recording', 'props'],
    components: {
        Timer,
    },
    data: () => ({
        buttonClass: '',
    })
};
</script>