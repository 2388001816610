<template>
    <div class="selected-input d-flex justify-content-between align-items-center" v-if="show" @click="clicked" :class="animationClass">
        <div class="icon content">
            <base-icons icon='defaultProfilePicture' />
        </div>
        <div class="text content pl-2 pr-2">{{ text }}</div>
        <div class="icon content">
            <base-icons icon='downArrow' />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .selected-input {
        background: #F6F7F9;
        width: max-content;
        border: 1px solid #E2E5ED;
        border-radius: 20px;
        padding: 0 10px 0 5px;
        height: 37.57px;
        font-size: 14px;
        font-family: "Proxima Nova-Bold";
        cursor: pointer;
    }
    .animate-down {
        position: relative;
        animation-name: down;
        animation-iteration-count: 1;
        animation-duration: 0.2s;
        transition: 0.2s;
    }
    @keyframes down {
        from {
            top: 0px;
        }
        to {
            opacity: 0;
            top: 80px;
        }
    }
</style>

<script>
export default {
    name: 'SelectedInput',
    props: ['text', 'show', 'animation'],
    data: () => ({
        animationClass: '',
    }),
    methods: {
        clicked() {
            this.hasAnimation();
        },
        hasAnimation() {
            const animation = this.$props.animation;
            if (animation) {
                this.animationClass = animation;
                setTimeout(() => {
                    this.animationClass = '';
                    this.$emit('clicked');
                }, 180);
            } else {
                this.$emit('clicked');
            }
        }
    }
}
</script>