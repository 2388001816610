<template>
    <div class="video-interactions-container d-flex justify-content-between">
        <div class="interactions-section">
            <library-header
                subtitle="Video interaction"
                :mobilePreviewButton="true"
            />
            <div class="interaction-section pt-5">
                <interactions-menu @section="toggleSection" v-if="!section" />
                <viewer-response
                    @section="toggleSection"
                    v-if="section === 'viewerResponse'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
                <multi-choice
                    @section="toggleSection"
                    v-if="section === 'multiChoice'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
                <url-redirect
                    @section="toggleSection"
                    v-if="section === 'urlRedirect'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
                <cta-button
                    @section="toggleSection"
                    v-if="section === 'cta'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
                <appointment-booking
                    @section="toggleSection"
                    v-if="section === 'appointments'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
                <lead-generation
                    @section="toggleSection"
                    v-if="section === 'leadGeneration'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
                <ecommerce-card
                    @section="toggleSection"
                    v-if="section === 'eCommerce'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
                <collect-payment
                    @section="toggleSection"
                    v-if="section === 'paymentCollectHand'"
                    @data="mergeData"
                    :interactionsData="interactionsData"
                />
            </div>
        </div>
        <div class="preview-section mt-5 pt-5">
            <div class="preview-title poppins-medium text-center">
                LIVE PREVIEW
            </div>
            <live-preview
                :interactionsData="interactionsData"
                :videoUrl="videoUrl"
                :section="section"
            />
        </div>
        <div class="lower-controls d-flex" v-if="!previewScreen">
            <BaseButton
                title="Back"
                background="#862d8380"
                hoverBg="#862D83"
                color="#fff"
                height="36px"
                width="147px"
                fontFamily="Poppins-Medium"
                class="align-self-end back-btn"
                :loading="loading"
                @clicked="goBack"
            />
            <BaseButton v-if="!done "
                title="Continue"
                background="#862D83"
                hoverBg="#862D83"
                color="#fff"
                height="36px"
                width="147px"
                fontFamily="Poppins-Medium"
                class="align-self-end continue-btn ml-4"
                @clicked="saveData"
                :loading="loading"
            />

            <BaseButton v-if="done"
                title="Done"
                background="#862D83"
                hoverBg="#862D83"
                color="#fff"
                height="36px"
                width="147px"
                fontFamily="Poppins-Medium"
                class="align-self-end continue-btn ml-4"
                @clicked="goHome"
                :loading="loading"
            />
        </div>
        <div v-else class="mobile-text text-center poppins-medium mt-5">
            Only Mobile Preview Available
        </div>
        <BaseLoader :showLoader="loading" :centered="true" />
    </div>
</template>

<style lang="less" scoped>
.video-interactions-container {
    width: 100%;
    height: max-content;
    padding: 15px 70px;
    background: #f5f8fa;
    min-height: 100vh;
    max-height: max-content;
    width: 100%;
    .interactions-section {
        width: 40%;
    }
    .preview-section {
        width: 50%;
        .preview-title {
            color: #6e8186;
            font-size: 25px;
        }
    }
    .lower-controls {
        display: flex;
        width: max-content;
        justify-content: flex-end;
        position: fixed;
        right: 25px;
        bottom: 25px;
    }
    .mobile-text {
        font-size: 17px;
    }
}

@media (max-width: 960px) {
    .video-interactions-container {
        flex-direction: column;
        .interactions-section {
            width: 100%;
        }
        .lower-controls {
            position: relative !important;
            justify-content: center;
            top: 20px;
            right: inherit;
            width: 100%;
        }
    }
}
</style>

<script>
import LibraryHeader from "../components/Videos/Upload/LibraryHeader";
import InteractionsMenu from "../components/Videos/Interactions/InteractionsMenu";
import ViewerResponse from "../components/Videos/Interactions/Sections/ViewerResponse";
import MultiChoice from "../components/Videos/Interactions/Sections/MultiChoice";
import UrlRedirect from "../components/Videos/Interactions/Sections/UrlRedirect";
import CtaButton from "../components/Videos/Interactions/Sections/CtaButton";
import AppointmentBooking from "../components/Videos/Interactions/Sections/AppointmentBooking";
import LeadGeneration from "../components/Videos/Interactions/Sections/LeadGeneration";
import EcommerceCard from "../components/Videos/Interactions/Sections/EcommerceCard";
import CollectPayment from "../components/Videos/Interactions/Sections/CollectPayment";
import LivePreview from "../components/Videos/LivePreview";

export default {
    components: {
        LibraryHeader,
        InteractionsMenu,
        ViewerResponse,
        LivePreview,
        MultiChoice,
        UrlRedirect,
        CtaButton,
        AppointmentBooking,
        LeadGeneration,
        EcommerceCard,
        CollectPayment,
    },
    name: "VideoInteractions",
    data: () => ({
        section: "",
        interactionsData: {},
        videoUrl: null,
        previewScreen: false,
        loading: false,
        done: false
    }),
    mounted() {
        this.getVideoCampaign();
    },
    methods: {
        getVideoCampaign() {
            const videoId = this.$route.params.videoId;
            const campaignId = this.$route.params.id;

            this.axios
                .get(`videos/${videoId}/${campaignId}`, {
                    headers: { showLoading: true },
                })
                .then((response) => this.handleResponse(response.data))
                .catch((error) => {
                    console.log(error);
                    this.$router.push({ path: "/projects" });
                });
        },
        toggleSection(section) {
            this.section = section;

            if (this.section === "") {
                this.interactionsData = { ...this.interactionsData };
                // this.resetInteractionsData(this.interactionsData);
            } else {
                // this.interactionsData = {};
                const videoId = this.$route.params.videoId;
                const campaignId = this.$route.params.id;
                this.axios
                    .get(`videos/${videoId}/${campaignId}`, {
                        headers: { showLoading: true },
                    })
                    .then((response) => this.handleResponse(response.data))
                    .catch((error) => {
                        console.log(error);
                        this.$router.push({ path: "/projects" });
                    });
            }
        },
        resetInteractionsData() {
            // console.log(interactionsData);
        },
        mergeData(data) {
            this.interactionsData = { ...this.interactionsData, ...data };
        },
        handleResponse(data) {
            const campaignVideo = data.video.campaign_video;
            // delete campaignVideo.video_id;
            delete campaignVideo.id;
            // delete campaignVideo.campaign_id;
            delete campaignVideo.created_at;
            delete campaignVideo.updated_at;
            this.interactionsData = campaignVideo;

            this.videoUrl = data.video.path;
        },
        saveData() {
            const { interactionsData, section } = this;
            console.log(interactionsData)
            const data = new FormData();

            data.append("interactionsData", JSON.stringify(interactionsData));

            if (interactionsData.cardOptions) {
                for (
                    let i = 0;
                    i < interactionsData.cardOptions.length;
                    i += 1
                ) {
                    data.append(
                        "files[]",
                        interactionsData.cardOptions[i].file,
                        interactionsData.cardOptions[i].file.name
                    );
                }
            }

            data.append("section", section);

            const url = "/storeVideoInteractions";
            this.loading = true;
            this.axios
                .post(url, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.loading = false;
                    
                    this.done = true
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        goBack() {
            const campaignId = this.$route.params.id;
            const path = `/campaigns/${campaignId}/upload`;
            this.$router.push({ path });
        },
        goHome(){
            this.$router.push({ path: "/projects" });
        }
    },
};
</script>
