<template>
    <div class="feature-tile">
        <div class="feature-container d-flex">
            <div class="feautre-icon d-flex align-items-center p-2 pl-3">
                <BaseIcons :icon='icon' />
            </div>
            <div class="feature-title helvetica-medium small-fonts d-flex align-items-center p-2">{{ title }}</div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .feature-tile {
        .feature-container {
            border: 1px solid #f1f8fd;
        }
    }
</style>

<script>
export default {
    name: 'FeatureTile',
    props: ['title', 'icon'],
}
</script>