<template>
    <div class="integration-form">
        <div class="form-group">
            <label for="name" class="base-label">DISPLAY NAME</label>
            <div class="input-description pb-4">
                The display name is a unique name you’ll use to recognize this integration. 
            </div>
            <BaseInput
                placeholder='e.g. Main Account'
                :inputValue='name'
                @value='name = $event'
            />
            <div class="details mt-4">
                <div class="details-title pb-2 base-label">AUTHENTICATION</div>
                <div class="details-title d-flex">
                    <BaseIcons
                        :icon='selected.icon'
                        imageHeight='60px'
                        imageWidth='80px'
                    />
                    <div class="flex-column align-self-center base-label pl-4">
                        Click the button below to integrate.
                    </div>
                </div>
            </div>
            <div class="api-form pt-4" v-if='inputs'>
                <div
                    v-for='input in inputs'
                    :key='input.key'
                    class="form-group"
                >
                    <label class="base-label" :for="input.key">{{ input.name }}</label>
                    <BaseInput
                        :inputId='input.key'
                        :type='input.type'
                        :placeholder='input.placeholder'
                        @value='addCredential($event, input)'
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .integration-form {
        padding: 50px;
    }
</style>

<script>
export default {
    name: 'IntegrationForm',
    props: ['displayName', 'selected'],
    data: () => ({
        name: '',
        inputs: null,
        credentialData: {},
    }),
    mounted() {
        this.name = this.$props.displayName;
        this.getInputs();
    },
    methods: {
        getInputs() {
            this.inputs = null;
            this.credentialData = {};
            this.$emit('credentials', this.credentialData);
            const item = this.selected;
            if (item.auth && item.auth.inputs) {
                this.inputs = item.auth.inputs;
            }
        },
        addCredential(value, item) {
            const credentialData = this.credentialData;
            credentialData[item.key] = value;
            this.credentialData = credentialData;
            this.$emit('credentials', credentialData);
        }
    },
    watch: {
        name(value) {
            this.$emit('name', value);
        },
    }
}
</script>