<template>
	<div class="subscription-settings-header">
        <div class="subscription-settings-navigation d-flex mb-4">
            <div
                class="section-button cursor-pointer helvetica-bold"
                :class="{'inactive-section': section !== 'plan'}"
                @click='$emit("section", "plan")'
            >
                Subscription Plan
            </div>
            <div
                class="section-button cursor-pointer helvetica-bold pl-5"
                :class="{'inactive-section': section !== 'billingInfo'}"
                @click='$emit("section", "billingInfo")'
            >
                Billing Information
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
	.subscription-settings-navigation {
        font-size: 20px;
    }
	.inactive-section {
        color:#8da0ac;
    }
</style>

<script>
	export default {
		name: 'SubscriptionBillingTab',
		props:['section'],
		data: () => ({
		}),
		mounted() {
			// console.log(this.$props);
		},
		methods: {

		}
	};
</script>