<template>
    <transition name="fade">
        <div class="string-filter" v-if="show">
            <div class="flex-column controls pt-2 pb-2">
                <div class="selected-option d-flex pb-2">
                    <div class="selected-label position-relative">
                        <span class="label-text">
                            {{ selected.label }}
                        </span>
                        <div @mouseleave="showSelect = false">
                            <select-dropdown
                                :options='stringOptions'
                                :show='showSelect'
                                borderRadius='0px'
                                border='1px solid #ced4da'
                                width='200px'
                                top='40px'
                                left='0'
                                @selected='selectComparison'
                            />
                        </div>
                    </div>
                    <div @click="showSelect = !showSelect">
                        <BaseIcons icon='downArrow' class="down-icon" />
                    </div>
                </div>
                <div class="comparison-input">
                    <BaseInput
                        borderRadius='0px'
                        class='w-100'
                        :inputValue='comparisonString'
                        @value='setValue'
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .string-filter {
        .controls {
            .selected-option {
                cursor: pointer;
                .selected-label {
                    border: 1px solid #e2e5ed;
                    min-width: 200px;
                    max-width: max-content;
                    padding: 0 10px;
                    height: 39px;
                    .label-text {
                        position: relative;
                        top: 5px;
                    }
                }
                .down-icon {
                    position: relative;
                    top: 5px;
                    left: 15px;
                    cursor: pointer;
                }
            }
        }
    }
</style>

<script>
import SelectDropdown from '../SelectDropdown';

export default {
    name: 'StringFilter',
    props: ['show'],
    components: {
        SelectDropdown,
    },
    data: () => ({
        selected: '',
        showSelect: false,
        stringOptions: [
            { id: 'is', label: 'title is' },
            { id: 'contains', label: 'title contains' },
            { id: 'not_contain', label: 'title does not contain' },
        ],
        comparisonString: '',
    }),
    mounted() {
        this.selectComparison(this.stringOptions[0]);
    },
    methods: {
        selectComparison(option) {
            this.selected = option;
            this.showSelect = false;
        },
        setValue(value) {
            this.comparisonString = value;
        },
        sendBackOptions() {
            if (!this.$props.show) return false;
            const { selected, comparisonString } = this;
            const filterObject = {
                type: selected.id,
                string: comparisonString,
            }
            this.$emit('comparisonObject', filterObject);
        }
    },
    watch: {
        selected() { this.sendBackOptions() },
        comparisonString() { this.sendBackOptions() },
        show() { this.sendBackOptions() },
    }
}
</script>