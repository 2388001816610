<template>
	<div class="url-redirect-container">
		<div
			class="response-header-section d-flex justify-content-between poppins-medium"
		>
			<div class="title">Configure URL Redirect</div>
			<div
				class="back-btn base-links cursor-pointer"
				@click="$emit('section', '')"
			>
				Change Interaction
			</div>
		</div>
		<div class="redirect-url mt-3">
			<BaseInput
				placeholder="Enter your URL here"
				border="1px solid #ccc"
				borderRadius="8px"
				class="url-search"
				:inputValue="url"
				keyTrigger="Enter"
				@value="url = $event"
			/>
			<p class="mt-3">
				This end any conditional logic connectedto this step and
				redirects viewer to 3rd party link.
			</p>
		</div>
	</div>
</template>

<style lang="less">
.url-redirect-container {
	.redirect-url {
		p {
			font-size: 15px;
			color: #1e39419a;
		}
	}
}
</style>

<script>
export default {
	name: "UrlRedirect",
	props: ["interactionsData"],
	mounted() {
		this.populateInteractionsData();
	},
	data: () => ({
		url: "",
	}),
	methods: {
		// handleVideoUrl() {
		// 	this.videoUrl = this.url;
		// 	console.log(this.videoUrl);
		// },
		populateInteractionsData() {
			const interactionsData = this.$props.interactionsData;
			if (interactionsData) {
				this.url = interactionsData.url;
			}
		},
	},
	watch: {
		$data: {
			handler(data) {
				let { url } = data;
				this.$emit("data", {
					url,
				});
			},
			deep: true,
		},
		interactionsData: {
			handler() {
				this.populateInteractionsData();
			},
			deep: true,
		},
	},
};
</script>
