import components from './components';
import VueRouter from 'vue-router'
import Vue from 'vue'

const mode = 'history';
const routes = [
    routeObject('/test', 'Test', components.Test, 'Ping Api', false),

    // Auth Pages
    routeObject('/', 'Login', components.Login, 'Login', false, false),
    routeObject('/register', 'Register', components.Register, 'Register', false, false),
    routeObject('/subscription', 'Subscription', components.Subscription, 'Subscription', true, false),
    routeObject('/reset-password', 'ResetPassword', components.ResetPassword, 'ResetPassword', false, false),

    // Projects
    routeObject('/projects', 'Videos', components.Projects, 'Projects', true, false),
    routeObject('/projects/:id', 'ProjectVideos', components.ProjectCampaigns, 'Videos', true, false),
    routeObject('/projects/:id/campaign', 'Campaign', components.CreateCampaign, 'Campaign', true, false),
    routeObject('/projects/:id/EditCampaign', 'EditCampaign', components.Edit, 'EditCampaign', true, false),

    // Campaign
    routeObject('/campaigns/:id/:current_step/create', 'Campaign', components.CreateCampaignType, 'Campaign', true, false),
    routeObject('/campaigns/:id/:project_id/EditCampaign', 'EditCampaign', components.Edit, 'EditCampaign', true, false),
    routeObject('/campaigns/:id/:current_step/record', 'Record', components.Record, 'Record', true, true),
    routeObject('/campaigns/:id/:current_step/upload', 'Upload', components.Upload, 'Upload', true, true),
    routeObject('/campaigns/:id/videos/:videoId', 'Interaction', components.VideoInteractions, 'VideoInteraction', true, true),

    // Settings
    routeObject('/settings', 'Settings', components.Settings, 'Settings', true, false),
    routeObject('/settings/:section', 'Settings', components.Settings, 'Settings', true, false),
    routeObject('/settings/:section/:id', 'Settings', components.Settings, 'Settings', true, false),

    // External Pages
    routeObject('/email-update/:hash', 'EmailUpdate', components.EmailUpdate, 'EmailUpdate', true, true),

    // Test
    routeObject('/test-component', 'Test', components.Select, 'Test', false, false),

    //Public
    routeObject('/watch/:video_id', 'public', components.Public, 'Public',false, true),
];

Vue.use(VueRouter);
const router = new VueRouter({ mode, routes });
router.beforeEach((to, from, next) => {
    let token = window.localStorage.getItem('token');
    let isLoggedIn = token != '' && token != null && token != 'null';
    const authPage = to.meta.auth;
    if (typeof authPage === 'undefined' || isLoggedIn === authPage) {
        next()
        return true;
    } else {
        if (!token) {
            next({path: '/'});
        } else {
            next({path: '/projects'})
        }
    }
});

router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || process.env.VUE_APP_NAME;
	});
});


function routeObject(path, name, component, title, auth, disableNav, children = null) {
    return {
        path,
        name,
        component,
        meta: { title, auth, disableNav },
        children,
    }
}

export default router;
