<template>
    <div class="embed-email" v-if='show'>
        <div class="radio-options d-flex">
            <BaseRadio
                label='Ellipse'
                :selected='type === "ellipse"'
                @clicked='type = "ellipse"'
            />
            <BaseRadio
                class="pl-4"
                label='Portrait'
                :selected='type === "portrait"'
                @clicked='type = "portrait"'
            />
        </div>
        <div class="copy-btn-text d-flex justify-content-between pt-5">
            <div class="copy-title helvetica-medium">
                Embed Code
            </div>
            <BaseButton
                title='Copy'
                background='#0DABD8'
                color='#fff'
                height='39px'
                borderRadius='20px'
                width='100px'
                @clicked='copyToClipboard'
            />
        </div>
        <div class="textarea-link">
            <textarea></textarea>
        </div>
        <div class="embed-size d-flex pt-2">
            <label for="size" class="base-label">Size</label>
            <BaseInput
                class="pl-4"
                type='number'
                :inputValue='size'
                @value='size = $event'
                
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .embed-email {
        .textarea-link {
            textarea {
                background: #fff 0 0 no-repeat padding-box;
                border: 1px solid #e2e5ed;
                border-radius: 6px;
                opacity: 1;
                width: 469px;
                height: 134px;
                padding: 15px;
                margin-top: 15px;
                color: #98a4b3;
                resize: none;
                outline: none;
            }            
        }
        .embed-size {
            label {
                position: relative;
                top: 6px;
            }
        }
    }
</style>

<script>
export default {
    name: 'EmbedEmail',
    props: ['show', 'video'],
    data: () => ({
        type: 'ellipse',
        size: 200,
    })
}
</script>