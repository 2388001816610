<template>
    <div
        class="timer-container poppins-bold"
        :class="mobileElement ? 'mobile-timer' : 'desktop-timer'"
        :style='{
            width,
            height,
            fontSize,
            fontFamily,
            padding,
            borderRadius,
        }'
    >
        <div class="digits d-flex">
            <div class="hours d-flex" v-if='hours'>
                <div class="digit">
                    {{ leadingZero(clock.hours) || '00' }}
                </div>
                <div class="divider">
                    :
                </div>
            </div>
            <div class="minutes d-flex">
                <div class="digit">
                    {{ leadingZero(clock.minutes) || '00' }}
                </div>
                <div class="divider">
                    :
                </div>
            </div>
            <div class="seconds">
                {{ leadingZero(clock.seconds) || '00' }}
            </div>
        </div>
    </div>
</template>

<style lang="less">
    .timer-container {
        background: #fff;
        width: max-content;
        padding: 15px;
        margin: auto;
        font-size: 30px;
        border-radius: 10px;
        .digits {
            display: flex;
            align-content: center;
            justify-content: center;
            height: 100%;
        }
    }
    .mobile-timer {
        
    }
</style>

<script>
import moment from 'moment';

export default {
    name: 'Timer',
    props: [
        'hours',
        'minutes',
        'seconds',
        'realTime',
        'stop',
        'width',
        'height',
        'fontSize',
        'fontFamily',
        'padding',
        'borderRadius',
        'static',
        'mobileElement'
    ],
    data: () => ({
        intervalId: null,
        clock: {
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
        secondsTotal: 0,
    }),
    mounted() {
        this.initTime();
    },
    destroyed() {
        this.clearTimer();
    },
    methods: {
        initTime() {
            const isStatic = this.$props.static;
            if (isStatic) {
                return this.setStaticTime();
            }
            const realTime = this.$props.realTime;
            this.intervalId = setInterval(() => {
                if (realTime) {
                    this.initRealTime();
                } else {
                    this.initTimer();
                }
            }, 1000);
        },
        initRealTime() {
            const timeObj = {};
            timeObj.hours = moment().format('HH');
            timeObj.minutes = moment().format('mm');
            timeObj.seconds = moment().format('ss');
            this.clock = timeObj;
        },
        initTimer() {
            let { hours, minutes, seconds } = this.clock;
            if (seconds === 59) {
                seconds = 0;
                if (minutes !== 59) {
                    minutes = parseInt(minutes) + 1;
                } else {
                    minutes = 0;
                    if (hours < 23) {
                        hours = parseInt(hours) + 1;
                    } else {
                        hours = 0;
                    }
                }
            } else {
                seconds = parseInt(seconds) + 1;
            }
            
            const clock = {
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            }
            this.secondsTotal = this.secondsTotal + 1;
            this.$emit('totalDuration', this.secondsTotal);
            this.clock = clock;
        },
        leadingZero(digit) {
            digit = !digit || digit === 'undefined' ? 0 : digit;
            let split = digit.toString().split('');
            if (split.length === 1) {
                return `0${digit}`;
            }
            return digit;
        },
        clearTimer() {
            clearInterval(this.intervalId);
            const timeObj = {};
            timeObj.hours = 0;
            timeObj.minutes = 0;
            timeObj.seconds = 0;
            this.clock = timeObj;
        },
        setStaticTime() {
            const { hours, minutes, seconds } = this.$props;
            this.clock = { hours, minutes, seconds };
        }
    },
    watch: {
        seconds() {
            this.setStaticTime();
        }
    }
};
</script>