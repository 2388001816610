<template>
    <div class="reset-password-container">
        <div class="reset-password-content">
            <div class="reset-password-body">
                <div class="upper-section">
                    <big-command-logo />
                    <div v-if='!showSuccessMessage'>
                        <div class="title-text mt-2">
                            Reset Your Password
                        </div>
                        <div class="description-text mt-3 mb-3">
                            Fear not. We’ll email you instructions
                            to reset your password.
                        </div>
                    </div>
                    <div v-else class="title-text mt-2">
                        Done and Done!
                    </div>
                </div>
                <div class="reset-form" v-if='!showSuccessMessage'>
                    <label for="email" class="base-label">Email Address</label>
                    <BaseInput
                        type='email'
                        placeholder='Enter your account email address'
                        inputId='email'
                        name='email'
                        keyTrigger='Enter'
                        :inputValue='email'
                        :error='emailError'
                        @keyPressed='sendEmail'
                        @value='email = $event'
                    />
                    <div class="form-buttons d-flex justify-content-between mt-5">
                        <router-link to='/' class="base-links">Return to Sign In</router-link>
                        <BaseButton
                            :loading='loading'
                            color='#fff'
                            background='#0DABD8'
                            title='Next'
                            borderRadius='20px'
                            width='97px'
                            height='36px'
                            padding='5px'
                            @clicked='sendEmail'
                        />
                    </div>
                </div>
                <div v-else class="success-section">
                    <success-check-with-message
                        text='Success!'
                        :subText='successText'
                    />
                    <div class="success-text pt-3">
                        If the email doesn’t show up soon, check your spam 
                        folder. We sent it from accounts@bigcommand.com
                    </div>
                    <div class="return-btn pt-3 d-flex justify-content-end">
                        <BaseButton
                            :loading='loading'
                            color='#fff'
                            background='#0DABD8'
                            title='Return to Login'
                            borderRadius='20px'
                            width='155.91px'
                            height='36px'
                            padding='5px'
                            @clicked='$router.push({path: "/"})'
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .reset-password-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #1D2331;
        font-family: "Helvetica Neue";
        .reset-password-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .reset-password-body {
                background: #fff;
                width: 471px;
                border: 1px solid #c9edf8;
                border-radius: 10px;
                padding: 41px 48.5px 39.9px;
                position: relative;
                font-size: 14px;
                .upper-section {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .title-text {
                        font-size: 24px;
                        font-family: "Helvetica Neue-Medium";
                    }
                    .description-text {
                        width: 270px;
                        font-size: 16px;
                    }
                }
                .reset-form {
                    .form-buttons {
                        a {
                            font-size: 16px;
                        }
                    }
                }
                .success-text {
                    font-size: 16px;
                    width: 400px;
                }
                .success-section {
                    width: 380px;
                }
            }
        }
    }
</style>

<script>
import BigCommandLogo from '../../components/Common/BigCommandLogo';
import validator from 'validator';
import SuccessCheckWithMessage from '../../components/Common/SuccessCheckWithMessage';

export default {
    name: 'ResetPassword',
    components: {
        BigCommandLogo,
        SuccessCheckWithMessage,
    },
    data: () => ({
        email: '',
        emailError: '',
        loading: false,
        showSuccessMessage: false,
        successText: '',
    }),
    methods: {
        sendEmail() {
            const valdiated = this.validate();
            
            if (valdiated) {
                this.loading = true;
                const email = this.email;
                this.axios.post('auth/user/reset-password', { email })
                    .then(response => {
                        this.handleResponse(response.data);
                    })
                    .catch (error => {
                        console.log(error);
                        this.loading = false;
                    })
            }
        },
        handleResponse(data) {
            this.loading = false;
            if (data.success) {
                this.showSuccessMessage = true;
                const emailHidden = this.hideEmailText();
                this.successText = `We’ve sent an email to ${emailHidden} with password reset instructions.`;
            }
        },
        validate() {
            let error = null;
            const email = this.email;
            if (validator.isEmpty(email)) {
                error = 'Please enter your account email';
            } else if (!validator.isEmail(email)) {
                error = 'Please enter a correct email';
            }
            this.emailError = error;
            return !error;
        },
        hideEmailText() {
            const email = this.email;
            let starsAdded = '';
            const beforeAt = email.slice(0, email.indexOf('@') + 1);
            const afterAt = email.slice(email.indexOf('@') + 1);
            const afterDot = afterAt.slice(afterAt.indexOf('.'));

            const beforeDomain = afterAt.slice(0, afterAt.indexOf('.'))
                .replace(/./g, '*');
            const splitEmail = beforeAt.split('');

            
            for (let index in splitEmail) {
                let prevIndex = parseInt(index) - 1;


                const character = splitEmail[index];
                if (index == 0 || splitEmail[prevIndex] === '@' || character === '@') {
                    starsAdded += character;
                } else {
                    starsAdded += '*';
                }
            }
            return starsAdded + beforeDomain + afterDot;
        }
    }
}
</script>