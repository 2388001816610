<template>
	<div id="app">
		<div class="message-container-global smooth-shadow">
			<div class="message-global"></div>
		</div>
		<div class='axios-loader-container'>
			<div class='axios-loader'></div>
		</div>
		<main-navbar :canBeShown='navbar'/>
		<div class="view-section" :class="{'auth-sections': navbar}">
			<router-view></router-view>
		</div>
	</div>
</template>

<style lang="less" scoped>
	.auth-sections {
		position: relative;
		top: 63px;
	}
	.message-container-global {
		position: fixed;
		top: 70px;
		left: 0;
		right: 0;
		margin: auto;
		width: max-content;
		height: max-content;
		z-index: 100;
		background: #00ACDC;
		padding: 5px 15px;
		color: #fff;
		border-radius: 5px;
		animation-name: appear;
		animation-iteration-count: 1;
		animation-duration: 0.3s;
		display: none;
	}

    .axios-loader-container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: auto;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);;
        transition: 0.3s;
        display: none;
        .axios-loader:before, .axios-loader:after{
            content: "";
            position: absolute;
            width: 50px;
            height: 50px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 100%;
            border: 10px solid #fff;
            border-top-color: #00ACDC;
          }
        
        .axios-loader:before{
            z-index: 100;
            animation: spin 1s infinite;
            animation-delay: 0ms;
        }
        
        .axios-loader:after{
            border: 10px solid #FAFAFA;
        }
    }

	@keyframes spin{
        0%{
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      
        100%{
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
    }

</style>

<script>
import MainNavbar from './components/Navbar';

export default {
	name: "App",
	components: {
		MainNavbar,
	},
	data: () => ({
		navbar: false,
	}),
	mounted() {
		this.navbarToggle();
	},
	methods: {
		navbarToggle(route = null) {
            if (!route) {
                route = this.$route;
            }

            const meta = route.meta;
            let canBeShown = false;

            if (meta) {
                if (meta.auth && !meta.disableNav) {
                    canBeShown = true;
                }
            }
            this.navbar = canBeShown;
        }
	},
	watch: {
        $route (to) {
            this.navbarToggle(to);
        }
    }
};
</script>

<style>
</style>
