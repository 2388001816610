<template>
    <div class="project-campaigns">
        <div class="campaigns-content mt-lg-5 mt-5">
            <div class="campaigns-data flex-column w-100">
                <campaigns-header
                    @query="searchCampaigns"
                    @openCampaignModal="goToCampaignCreation"
                    :count='campaignsAll.length'
                    :title='project ? project.title : "Loading..."'
                />
                <campaign-table
                    class="pt-5 mb-5"
                    :loading="loading"
                    :campaigns="campaigns"
                    @delete="openModal($event, 'showDeleteModal')"
                    @shareAndEmbed="openModal($event, 'showShareEmbedModal')" 
                    @edit="goToCampaignEdit"
                />
            </div>
        </div>
        <div class="modals">
            <confirm-modal
                :show="showDeleteModal"
                title="DELETE CAMPAIGN"
                subText="This will permanently delete this campaign"
                type="delete"
                @close="closeModal('showDeleteModal')"
                @confirmed="performAction('delete')"
            />
            <share-and-embed
                :show="showShareEmbedModal"
                @close="closeModal('showShareEmbedModal')"
                :video="selectedCampaign"
            />
        </div>
        <messages-popup :show='showMessage' :message='message' @closeMessages='showMessage = false' />
    </div>
</template>

<style lang="less" scoped>
.project-campaigns {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 63px;
    left: 0;
    .campaigns-content {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        padding: 30px;
        border-radius: 10px;
    }
}
@media (max-width: 960px) {
    .project-campaigns {
        .campaigns-content {
            width: 99%;
        }
    }
}
</style>

<script>
import CampaignsHeader from "../../components/Projects/CampaignsHeader";
import CampaignTable from "../../components/Projects/CampaignTable";
import ConfirmModal from "../../components/Common/Modals/ConfirmModal";
import ShareAndEmbed from "../../components/Common/Modals/ShareAndEmbed";
import MessagesPopup from '../../components/Common/MessagesPopup';

export default {
    name: "ProjectCampaigns",
    components: {
        CampaignsHeader,
        CampaignTable,
        ConfirmModal,
        ShareAndEmbed,
        MessagesPopup
    },
    data: () => ({
        showCampaignModal: false,
        showCampaignWarning: false,
        loading: false,
        campaigns: [],
        campaignsAll: [],
        showDeleteModal: false,
        selectedCampaign: null,
        showShareEmbedModal: false,
        message: '',
        showMessage: false,
        project: null,
    }),
    mounted() {
        this.getCampaigns();
    },
    methods: {
        goToCampaignCreation() {
            const id = this.$route.params.id;
            this.$router.push({
                path: `/projects/${id}/campaign`
            });
        },goToCampaignEdit(campaign){
            this.$router.push({
                path: `/campaigns/${campaign.campaign_id}/${this.project.project_id}/EditCampaign`
            });
        },
        handleWarningClick(option) {
            this.showCampaignWarning = false;
            const closeCampaign = option === "yes";
            this.showCampaignModal = !closeCampaign;
        },
        getCampaigns() {
            const projectId = this.$route.params.id;
            const url = `projects/${projectId}/campaigns`;
            this.loading = true;
            this.axios
                .get(url)
                .then(response => {
                    this.loading = false;
                    if (response.data.success) {
                        this.campaigns = response.data.campaigns;
                        this.campaignsAll = response.data.campaigns;
                        this.project = response.data.project;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$router.push({path: '/projects'});
                    console.log(error);
                });
        },
        openModal(campaign, type) {
            this[type] = true;
            this.selectedCampaign = campaign;
        },
        closeModal(type) {
            this[type] = false;
            this.selectedCampaign = null;
        },  
        performAction(type) {
            switch (type) {
                case "delete":
                    this.deleteCampaign();
                    break;
            }
        },
        deleteCampaign() {
            // console.log(this.campaigns[0].id);
            const id = this.campaigns[0].id;
            const url = `projects/campaigns/${id}`;
            this.axios
                .delete(url)
                .then(res => {
                    console.log(res);
                    location.reload();
                })
                .catch(err => {
                    console.log(err);
                });
            this.showDeleteModal = false;
            // Todo: Delete
            const campaign = this.selectedCampaign;
            if (campaign && campaign.id) {
                const url = `campaigns/${campaign.id}`;
                this.axios.delete(url)
                    .then(response => {
                        if (response.data.success) {
                            this.getCampaigns();
                            location.reload();
                        } else {
                            this.message = 'Failed to delete campaign';
                            this.showMessage = true;
                        }
                    })
            }
        },
        searchCampaigns(query) {
            this.campaigns = this.campaignsAll.filter(c =>
                c.title.toLowerCase().includes(query)
            );
        }
    }
};
</script>
