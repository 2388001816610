<template>
    <div class="embed-inline" v-if="show">
        <div class="radio-options d-flex justify-content-between">
            <BaseRadio
                label='Adaptive Format'
                :selected='type === "adaptive"'
                @clicked='type = "adaptive"'
            />
            <BaseRadio
                label='Vertical Format'
                :selected='type === "vertical"'
                @clicked='type = "vertical"'
            />
            <BaseRadio
                label='Card Fromat'
                :selected='type === "card"'
                @clicked='type = "card"'
            />
        </div>
        <div class="copy-btn-text d-flex justify-content-between pt-5">
            <div class="copy-title helvetica-medium">
                Embed Code
            </div>
            <BaseButton
                title='Copy'
                background='#0DABD8'
                color='#fff'
                height='39px'
                borderRadius='20px'
                width='100px'
                @clicked='copyToClipboard'
            />
        </div>
        <div class="textarea-link">
            <textarea></textarea>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .embed-inline {
        .textarea-link {
            textarea {
                background: #fff 0 0 no-repeat padding-box;
                border: 1px solid #e2e5ed;
                border-radius: 6px;
                opacity: 1;
                width: 469px;
                height: 134px;
                padding: 15px;
                margin-top: 15px;
                color: #98a4b3;
                resize: none;
                outline: none;
            }            
        }
    }
</style>

<script>
export default {
    name: 'EmbedInline',
    props: ['show', 'video'],
    data: () => ({
        type: 'adaptive',
    }),
    methods: {
        copyToClipboard() {
            const video = this.$props.video;
            const url = video.url;
            if (url) {
                let el = document.createElement('textarea');
                el.value = url; 
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$emit('copySuccess');
            }
        },
    }
}
</script>