<template>
<div>
<div v-if="choiceStep">
<div style="position:relative , margin-top:-178px">
<div v-for="(choice,i) in choiceStep" :key="choice.id" >         
<div style="positing:relative">
   <div class="action-tag-choice"  :style="{ top: (50 * i)+'px' }" >
      <div class="action-tag-choice-alphabet-serial">
          <span class="action-tag-choice-serial">{{i}}</span>
       </div>
             <span class="action-tag-choice-text"> On action, jump to:  </span>
              <button  @mouseover="onMouseOver(choice.id)" @mouseleave="onMouseOut(choice.id)" @click="onChoiceMenuClick()" :disabled="index =='End' || dropdownDisable"    class="action-tag-circle">
                   <span class="action_tag_text_circle"  style="border:none">{{clickedStepCount ? clickedStepCount : index+2}}</span>
                   <div class="action-tag-end" v-if="index =='End'" style="border:none"> 
                       <span  class="action-tag-text-end">End</span>
                   </div>                                
              </button>
                    <div v-if="isOpenChoiceDropdown"  id="dd" class="choice-wrapper-dropdown" tabindex="1">
                    <p v-on-clickaway="away" ></p>
                    <ul class="dropdown">
                            <template v-for="i in Length">
                                <li :key='i' v-if="i > (index + 2)" @click="clickedStep(i)" >
                                    <span class="Dropdown-step"> Step {{i}}</span>
                                </li>
                            </template>
                    </ul>
                </div>
                    <div v-if="isShow(choice.id)" class="choiceStepToolTip">
                        You chose to jump on step {{selectedStep ? selectedStep : clickedStepCount}}
                        <div class="arrow-down"></div>
                    </div> 
                                       
               </div>
            </div>
        </div>                       
</div>
</div>
<div v-else class="action-tag">
            <span class="action-tag-text"> On action, jump to:  </span>
            <button @mouseover="isHover = true" @mouseleave="isHover =false" @click="onMenuClick()" :disabled="index =='End' || dropdownDisable"  class="action-tag-circle">
                  <span class="action_tag_text_circle"  style="border:none">{{selectedStep ? selectedStep : clickedStepCount ? clickedStepCount : index+2}}</span>
                  <div class="action-tag-end" v-if="index =='End'" style="border:none"> 
                   <span  class="action-tag-text-end">End</span>
                 </div>             
            </button>                         
        </div>

<div v-if="isHover" class="stepToolTip">
    You chose to jump on step {{selectedStep ? selectedStep : clickedStepCount}}
    <div class="arrow-down"></div>
</div>

 <div v-if="isOpenDropdown"  id="dd" class="wrapper-dropdown" tabindex="1">
                            <p v-on-clickaway="away" ></p>
                            <ul class="dropdown">
                                    <template v-for="i in Length"  >
                                        <li :key='i' v-if="i > (index + 2)" @click="clickedStep(i)" >
                                            <span class="Dropdown-step"> Step {{i}}</span>
                                        </li>
                                    </template>
                            </ul>
</div> 
 


</div>

</template>
<script>

import { mixin as clickaway } from 'vue-clickaway';


export default {
     mixins: [ clickaway ],
     props:['index','isActive','Length','dropdownDisable','video_id','selectedStep','choiceStep'],
    data: ()=> {
    return {
        name:'StepDropdown',
        isOpenDropdown:false,
        isDisabled:false,
        clickedStepCount: '',
        isHover:false,
        choiceHover:false,
        isOpenChoiceDropdown:false,
        stepDropdownShowState : {}
    }
    },
    methods:{
        onMouseOver(i) {
                this.$set(this.stepDropdownShowState, i , true);
       },
        onMouseOut(i) {
             this.$set(this.stepDropdownShowState, i , false);
        },
        isShow(i) {
            return this.stepDropdownShowState[i] == true;
        },
        
         onMenuClick() {
           this.isOpenDropdown = !this.isOpenDropdown;
           this.selectedStep=false;
           },
         onChoiceMenuClick() {
           this.isOpenChoiceDropdown = !this.isOpenChoiceDropdown;
           this.selectedStep=false;
           },
     away: function() {
     this.isOpenDropdown = false;
     this.isOpenChoiceDropdown = false;
    },
   clickedStep(step){
    this.clickedStepCount = step;
    this.$emit('stepSelected',{video_id:this.video_id , current_step:step});
   },
}
}
</script>


<style scoped>
.stepToolTip {
      position: absolute;
      width: 225px;
      height: 37px;
      left: 300px;
      top: 85px;
      padding: 10px;
      font-size: 14px;
      text-align: center;
      color: rgb(3, 3, 3);
      background: #E8F0F6;
      border-radius: 5px;
      text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
      }
.choiceStepToolTip { 
    position: relative;
    width: 225px;
    height: 37px;
    left: 82px;
    top: -90px;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    color: rgb(3, 3, 3);
    background: #E8F0F6;
    border-radius: 5px;
    text-shadow: rgb(0 0 0 / 10%) 1px 1px 1px;
    box-shadow: rgb(0 0 0 / 10%) 1px 1px 2px 0px;
}

.arrow-down {
  width: 25px; 
  height: 0px; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;  
  border-top: 20px solid #E8F0F6;
  position:absolute;
  left:93px;
}
 .action-tag {
 background-color: #E8F0F6;
 border-radius: 32px;
 position:absolute;
 width: 219px;
 height: 40px;
 left: 220px;
 bottom: 0px;
 }
 .action-tag-choice {
 background-color: #E8F0F6;
 border-radius: 32px;
 position:absolute;
 width: 219px;
 height: 40px;
 left: 214px;
 bottom: 138px;
 }
 .action-tag-choice-serial {
    position: absolute;
    top: 7px;
    left: 14px;
 }
 .action-tag-choice-alphabet-serial {
    background-color: #8b3c92;
    width: 33px;
    height: 40px;
    text-align: center;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    color: white;
 }
.action-tag-choice-text{
 position: absolute;
 left: 41px;
 top: 5px;    
}
 .action-tag-text {
 position: absolute;
 left: 26px;
 top: 5px;
 }
 .action_tag_text_circle{
 font-size: 15px;
 position:absolute;
 top: 0px;
 right: 6px;
 font-weight: bold;    
 }
.action-tag-circle {
 width: 28px;
 height: 28px;
 border-radius: 60%; 
 color: #FFFFFF;  
 background-color: purple;
 position:absolute;
 padding: 2px;
 padding-right: 4px;
 padding-left: 6px;
 left: 179px;
 top: 4px;
 text-align: center;
}
.action-tag-end{
width: 48px;
height: 40px;
position:absolute;
background-color: purple;
right: -4px;
bottom: -9px;
border-bottom-right-radius: 15px;
border-top-right-radius: 15px;
left: -12px;

}
.action-tag-text-end{
font-size: 15px;
font-weight: bold;
position: absolute;
left: 50%;
top: 50%;
transform:  translateY(-50%) translateX(-56%);
color:#FFFFFF;
}

.wrapper-dropdown {
    /* Size and position */
    
    position: absolute;
    width: 105.6px;
    margin: 10px auto 0px auto;
    left: 361px;
    z-index: 1;
    /* padding: 10px; */

    /* Styles */
    background: #fff;
    border-radius: 7px;
    border: 1px solid rgba(0,0,0,0.15);
    box-shadow: 0 1px 1px rgba(50,50,50,0.1);
    cursor: pointer;
    outline: none;

    /* Font settings */
    font-weight: bold;
    color: #8AA8BD;
}
.choice-wrapper-dropdown {
    /* Size and position */
    
    position: absolute;
    width: 105.6px;
    margin: 10px auto 0px auto;
    left: 145px;
    z-index: 5;
    top: 30px;
    /* Styles */
    background: #fff;
    border-radius: 7px;
    border: 1px solid rgba(0,0,0,0.15);
    box-shadow: 0 1px 1px rgba(50,50,50,0.1);
    cursor: pointer;
    outline: none;

    /* Font settings */
    font-weight: bold;
    color: #8AA8BD;
}
.dropdown {
    list-style: none;
    padding: 0;
    margin:0;
    position: relative;
}

.dropdown::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid #e3d6d6;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.dropdown li {
    padding: 5px 10px;
}

.Dropdown-step {
    text-align: center;
    width: 42px;
    height:17px;
    display: block;
    margin: auto;
    color: black;
    font-size: 12px;
    font-weight: lighter;
    line-height: 18px;
    right: 17px;
}

  
</style>

