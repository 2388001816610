<template>
    <div class="multi-choice-container">
        <div
            class="response-header-section d-flex justify-content-between poppins-medium"
        >
            <div class="title">Configure multi-choice</div>
            <div
                class="back-btn base-links cursor-pointer"
                @click="$emit('section', '')"
            >
                Change Interaction
            </div>
        </div>
        <div class="choose-options mt-3">
            <div class="form-group" v-for="(input, k) in inputOptions" :key="k">
                <div class="d-flex">
                    <input
                        type="text"
                        class="form-control"
                        v-model="input.title"
                    />
                    <BaseIcons
                        :icon="'remove'"
                        class="device-icon remove-icon"
                        @clicked="remove(k)"
                        v-show="k || (!k && inputOptions.length > 0)"
                    />
                    <BaseIcons
                        :icon="'directionArrows'"
                        class="device-icon arrow-icon"
                        :class="{ event: input.event }"
                        @clicked="addToPreview(k)"
                    />
                </div>
            </div>
            <div class="add-more-optn mt-4">
                <BaseIcons
                    :icon="'iconsPlus'"
                    class="device-icon"
                    v-if="inputOptions.length < 5"
                    @clicked="add()"
                />
                <span v-if="inputOptions.length < 5">Add new choice</span>
            </div>
        </div>
        <div class="response-settings">
            <input-with-label
                v-for="(element, index) in inputElements"
                :key="index"
                :headLabel="element.headLabel"
                :subLabel="element.subLabel"
                :class="element.class"
                :inputValue="getDataVar(element.input)"
                @value="handleInputs(element.input, $event)"
                :inputType="element.type"
            />
            <switch-with-label
                class="collect-data-toggle"
                :toggle="toggles.collect_data"
                label="Collect data on submission"
                @clicked="handleToggles('collect_data')"
            />
        </div>
    </div>
</template>

<style lang="less">
.multi-choice-container {
    .choose-options {
        .form-group {
            position: relative;
            .form-control {
                border-radius: 5px;
            }
        }
        .remove-icon {
            position: absolute;
            top: 5px;
            right: 35px;
        }
        .arrow-icon {
            top: 5px;
            left: 5px;
        }
        .event {
            pointer-events: none;
        }
        .add-more-optn {
            color: #1e39419a;
            font-size: 15px;
            font-weight: 600;
            .device-icon {
                display: inline-flex;
                margin-right: 15px;
            }
        }
    }
    .response-settings {
        .collect-data-toggle {
            width: 224px;
            .switch-label-trigger-text {
                padding-right: 0px !important;
                font-size: 12px;
            }
        }
    }
}
</style>

<script>
import SwitchWithLabel from "../../../Common/SwitchWithLabel";
import InputWithLabel from "../InputWithLabel";

function chooseOptn(title) {
    this.title = title || "";
}

export default {
    name: "MultiChoice",
    props: ["interactionsData"],
    components: {
        SwitchWithLabel,
        InputWithLabel,
    },
    mounted() {
        this.populateInteractionsData();
    },
    data: () => ({
        toggles: {
            collect_data: false,
        },
        delay_interaction_by: 15,
        inputElements: [
            {
                input: "delay_interaction_by",
                headLabel: "Delay interaction by",
                subLabel: "seconds",
                class: "mb-4",
                type: "number",
            },
        ],
        inputOptions: [
            new chooseOptn("Sisco denim TV"),
            new chooseOptn("Apple 4K TV"),
        ],
        optionTitle: [],
        chooseOptnTitle: "",
    }),
    methods: {
        handleToggles(toggle) {
            this.toggles[toggle] = !this.toggles[toggle];
        },
        handleInputs(toggle, value) {
            if (value > 3) {
                value = 3;
            }
            this[toggle] = parseInt(value);
        },
        getDataVar(variable) {
            return this[variable];
        },
        populateInteractionsData() {
            const interactionsData = this.$props.interactionsData;
            if (interactionsData) {
                const toggles = this.toggles;
                toggles.collect_data = interactionsData.collect_data;
                this.toggles = toggles;
                this.delay_interaction_by =
                    interactionsData.delay_interaction_by;
                this.chooseOptnTitle = interactionsData.optionTitle;
            }
        },
        add() {
            this.inputOptions.push(new chooseOptn(""));
        },
        remove(index) {
            this.inputOptions.splice(index, 1);
            this.optionTitle.splice(index, 1);
        },
        addToPreview(index) {
            let inputOption = this.inputOptions[index];
            inputOption.event = !inputOption.event;
            this.$set(this.inputOptions, index, inputOption);
            this.optionTitle.push(this.inputOptions[index].title);
        },
    },
    watch: {
        $data: {
            handler(data) {
                let { toggles, delay_interaction_by, optionTitle } = data;
                const { collect_data } = toggles;
                this.$emit("data", {
                    collect_data,
                    delay_interaction_by,
                    optionTitle,
                });
            },
            deep: true,
        },
        interactionsData: {
            handler() {
                this.populateInteractionsData();
            },
            deep: true,
        },
    },
};
</script>
