<template>
    <div class="library-upload-container">
        <div
            class="upload-box"
            @drop="handleDrop"
            @dragover="handleDragOver"
            @dragenter="handleDragEnter"
            @dragleave="handleDragLeave"
        >
            <BaseButton
                title='Upload a video'
                :background='uploadInProgress ? "#862d837b" : "#862D83"'
                color='#fff'
                height='36px'
                width='147px'
                fontSize='14px'
                class="upload-button"
                @clicked='openFileUploader'
            />
            <div class="uploader-text pb-2 pl-5">
                <div class="big-title general-font-size poppins-medium">or drag n’ drop video</div>
                <div class="small-text poppins-light">Only mp4, mov and webm videos accepted *</div>
                <div class="small-text poppins-light">Videos can’t exceed 200mb and 5 minutes max *</div>
            </div>
            <input
                v-if='!uploadInProgress'
                type='file'
                multiple
                accept="video/*"
                ref='fileInput'
                class="position-fixed invisible"
                @change="prepareVideoFiles" />
        </div>
        <div class="progress-tracker pt-5">
            <div
                class="upload-progress pb-5"
                v-for="(video, index) in videos"
                :key='index'
            >
                <div class="d-flex">
                    <upload-progress
                        :percentage="video.percentage"
                        :failed='video.failed'
                        :cancelled='video.cancelled'
                        :title='video.title'/>
                    <BaseIcons v-if='video.percentage < 100 && !video.cancelled && !video.failed' icon='cancel' class="progress-icon" @clicked='cancelUpload(video)' />
                    <BaseIcons icon='refresh' v-if='video.cancelled || video.failed' class="progress-icon" @clicked='restartUpload(video)'/>
                </div>
            </div>
        </div>
        <messages-popup :show='showMessage' :messages="messages" duration="4000" @closeMessages='closeMessages' />
    </div>
</template>

<style lang="less">
    .library-upload-container {
        .upload-box {
            border: 2px dashed #524C4C;
            background: #F0F1F2;
            border-radius: 10px;
            display: flex;
            padding: 30px 30px 0 30px;
            justify-content: center;
        }
        .progress-icon {
            position: relative;
            bottom: 12px;
            left: 10px;
        }
    }
    @media (max-width: 480px) {
        .library-upload-container {
            .upload-box {
                flex-direction: column;
                padding: 10px !important;
                .uploader-text {
                    padding: 10px !important;
                }
                .upload-button {
                    position: relative;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }
</style>

<script>
import MessagesPopup from '../../Common/MessagesPopup.vue';
import UploadProgress from '../../Common/Widgets/UploadProgress.vue';
export default {
    props: ['maxSize'],
    components: { UploadProgress, MessagesPopup },
    name: 'Uploader',
    data: () => ({
        videos: [],
        uploadProgress: [],
        message: '',
        completedUploads: 0,
        uploadInProgress: false,
        messages: [],
        showMessage: false,
    }),
    methods: {
        handleDrop(event) {
            event.preventDefault();
            if (this.uploadInProgress) return false;
            const files = Object.values(event.dataTransfer.files);

            const videos = files.filter(file => file.type.includes('video'));
            this.prepareVideos(videos);
        },
        prepareVideos(videos) {
            this.videos = [];
            for (let file of videos) {
                const title = file.name;
                this.videos.push({
                    title,
                    file,
                    percentage: 0,
                    failed: false,
                    cancelled: false,
                    video_id: this.makeVideoId(5),
                    cancel_token: this.axios.CancelToken.source(),
                });
            }
            this.uploadVideos();
        },
        handleDragOver(event) {
            event.preventDefault();
        },
        handleDragEnter() {
            document.querySelector('.upload-box').style.cursor = 'move';
        },
        handleDragLeave() {
            document.querySelector('.upload-box').style.cursor = 'pointer';
        },
        uploadVideos() {
            const campaignId = this.$route.params.id;
            this.uploadInProgress = true;
            for (let video of this.videos) {
                const sizeValidated = this.validateVideoSize(video);
                if (sizeValidated) {
                    this.startUploadRequest(video, campaignId);
                }
            }
            if (this.messages.length) {
                this.showMessage = true;
            }
        },
        startUploadRequest(video, campaignId) {
            const data      = new FormData();
            const videoFile = video.file;

            data.append('video', videoFile);
            data.append('campaign_id', campaignId);
            data.append('video_id', video.video_id);
            console.log("data in video",video);
            this.handleVideoUploadRequest(data, video);
        },
        handleVideoUploadRequest(data, video) {
            
            const url = '/videos/upload';
            this.loading = true;
            this.axios.post(url, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: function (event) {
                    this.showProgress(event, data.get('video_id'));
                }.bind(this),
                cancelToken: video.cancel_token.token
            })
            .then(response => {
                this.loading = false;
                if (response.data.success) {
                    video.percentage = 100;
                    this.completedUploads = this.completedUploads + 1;
                    if (this.completedUploads === this.videos.length) {
                        this.uploadInProgress = false;
                        this.$emit('refresh');
                    }
                } else {
                    video.failed = true;
                }
            }).catch(error => {
                if (error && !error.message === 'undefined') {
                    video.failed = true;
                }
                console.log(error);
                this.uploadProgress = 0;
                this.loading = false;
                this.message = 'Failed to upload';
            })
        },
        makeVideoId(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
               result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        showProgress(event, videoId) {
            const total = parseInt(event.total);
            const loaded = parseInt(event.loaded);
            let percent = (loaded * 100) / total;
            const videos = this.videos;

            percent = percent > 90 ? 90 : percent;
            for (let video of videos) {
                if (video.video_id === videoId) {
                    video.percentage = percent;
                }
            }
            this.videos = videos;
        },
        cancelUpload(video) {
            video.cancel_token.cancel();
            video.cancelled = true;
            video.failed = false;
        },
        restartUpload(video) {
            const campaignId = this.$route.params.id;

            video.cancelled = false;
            video.failed = false;
            video.cancel_token = this.axios.CancelToken.source();
            this.startUploadRequest(video, campaignId);
        },
        openFileUploader() {
            if (!this.$refs.fileInput) return false;
            this.$refs.fileInput.click();
        },
        prepareVideoFiles(event) {
            const files = Object.values(event.target.files);
            this.prepareVideos(files);
        },
        validateVideoSize(video) {
            const file = video.file;
            const maxSize = parseInt(this.$props.maxSize);
            if (file) {
                const size = file.size || 0;
                if (size > maxSize) {
                    this.videos = this.videos.filter(v => v.video_id != video.video_id)
                    this.messages.push(`${video.title} exceeds the size limit of 200 mb`);
                    return false;
                }
            }
            return true;
        },
        closeMessages() {
            this.messages = [];
            this.showMessage = false;
        }
    }
};
</script>