<template>
    <div class="interaction-menu-item d-flex rounded cursor-pointer" @click='$emit("section", name)'>
        <div class="icon px-3 d-flex align-self-center">
            <BaseIcons :icon='icon' />
        </div>
        <div class="texts d-flex flex-column justify-content-center py-3 pr-5">
            <div class="header poppins-medium">{{ header }}</div>
            <div class="sub-header poppins-light">{{ subHeader }}</div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .interaction-menu-item {
        background: #fff;
        height: 56px;
        .header {
            font-size: 14px;
        }
        .sub-header {
            font-size: 10px;
        }
    }
</style>

<script>
export default {
    name: 'InteractionMenuItem',
    props: ['icon', 'header', 'subHeader', 'name'],
}
</script>