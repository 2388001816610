const InteractionMenuItems = [
    {
        name: 'viewerResponse',
        icon: 'viewerResponse',
        header: 'Viewer’s response',
        subHeader: 'Collect feedbacks and interactions via video, audio or text',
    },
    {
        name: 'multiChoice',
        icon: 'multiChoice',
        header: 'Multi-choice',
        subHeader: 'Create polls and conditional logics based on choices',
    },
    {
        name: 'urlRedirect',
        icon: 'urlRedirect',
        header: 'URL Redirect',
        subHeader: 'Redirects viewer to a designated link at the end of video',
    },
    {
        name: 'cta',
        icon: 'cta',
        header: 'CTA button',
        subHeader: 'Display a button to drive click-throughs',
    },
    {
        name: 'appointments',
        icon: 'appointments',
        header: 'Appointments & booking',
        subHeader: 'Display your calendar and book appointments',
    },
    {
        name: 'leadGeneration',
        icon: 'leadGeneration',
        header: 'Lead generation',
        subHeader: 'Collect leads and grow your email list',
    },
    {
        name: 'eCommerce',
        icon: 'eCommerce',
        header: 'eCommerce Card',
        subHeader: 'Display cards to promote & sell your eCommerce products',
    },
    {
        name: 'paymentCollectHand',
        icon: 'paymentCollectHand',
        header: 'Collect payments',
        subHeader: 'Accept payments via Stripe, Braintree or Paypal',
    },
    {
        name: 'share',
        icon: 'share',
        header: 'Share',
        subHeader: 'Encourage your viewers to share and win',
    },
];

export {
    InteractionMenuItems,
}