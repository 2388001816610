<template>
    <transition name='slide-fade'>
        <div class="messages-box" v-if='messagesArray && messagesArray.length'>
            <div class="messages-content">
                <div class="messages-body p-5 text-center">
                    <div
                        class="message-tag"
                        v-for='(message, index) in messagesArray'
                        :key='index'
                    >
                        {{ message }}
                    </div>
                    <div class="messages-btn d-flex justify-content-end">
                        <BaseButton
                            title='Close'
                            @clicked='close'
                            class="d-flex align-self-end mt-5"
                            width='max-content'
                            :background='background || "#862D83"'
                            color='#fff'
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .messages-box {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 18;
        .messages-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .messages-body {
            background: #fff;
            width: 520.64px;
            border: 1px solid #0DABD8;
            border-radius: 10px;
            position: relative;
            font-size: 20px;
        }
    }
</style>

<script>
export default {
    name: 'MessagesBox',
    props: ['messages', 'background'],
    data: () => ({
        messagesArray: [],
    }),
    mounted() {
        this.showMessage();
    },
    methods: {
        showMessage() {
            const messages = this.$props.messages;
            const type = typeof messages;
            if (messages) {
                if (type === 'string') {
                    this.messagesArray.push(messages);
                } else if (type === 'object') {
                    this.messagesArray = messages;
                }
            }
        },
        close() {
            this.$emit('closeMessages');
        }
    },
    watch: {
        messages() {
            this.showMessage();
        }
    }
}
</script>