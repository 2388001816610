<template>
    <transition name="slide-fade">
        <div class="video-controls w-100">
            <div class="d-flex controls-container justify-content-center pt-5">
                <div class="flex-column toggle-btn position-relative pl-3 pr-3">
                    <!-- Video Cam Control -->
                    <BaseIcons
                        :icon='devices.video ? "recordingVideoCam" : "recordingVideoCamDisabled"'
                        :tooltip='!selectSection ? "Select your camera" : ""'
                        tooltipTop='120px'
                        tooltipLeft='-25px'
                        class="desktop-element"
                        @dbClicked='$emit("toggle", "video")'
                        @clicked='toggleSelectSection("video")'
                    />
                    <BaseIcons
                        :icon='devices.video ? "recordingVideoCamMobile" : "recordingVideoCamDisabledMobile"'
                        :tooltip='!selectSection ? "Select your camera" : ""'
                        tooltipTop='120px'
                        tooltipLeft='-25px'
                        class="mobile-element"
                        @dbClicked='$emit("toggle", "video")'
                        @clicked='toggleSelectSection("video")'
                    />
                    <BaseSelect
                        :selected='selected'
                        v-if='devices.video'
                        :options='options.video'
                        :show='selectSection === "video"'
                        top='120px'
                        width='300px'
                        left='-80px'
                        class="desktop-element"
                        arrowPosition='center'
                        @selected='$emit("switchDevice", {video: $event})'
                    />
                    <!-- Video Cam Control -->
                </div>
                <div class="flex-column toggle-btn position-relative pl-3 pr-3">
                    <!-- Mic Control -->
                    <BaseIcons
                        :icon='devices.audio ? "recordingMic" : "recordingMicDisabled"'
                        :tooltip='!selectSection ? "Select your mic" : ""'
                        tooltipTop='120px'
                        tooltipLeft='-20px'
                        class="desktop-element"
                        @dbClicked='$emit("toggle", "audio")'
                        @clicked='toggleSelectSection("audio")'
                    />
                    <BaseIcons
                        :icon='devices.audio ? "recordingMicMobile" : "recordingMicDisabledMobile"'
                        :tooltip='!selectSection ? "Select your mic" : ""'
                        tooltipTop='120px'
                        tooltipLeft='-20px'
                        class="mobile-element"
                        @dbClicked='$emit("toggle", "audio")'
                        @clicked='toggleSelectSection("audio")'
                    />
                    <BaseSelect
                        :selected='selected'
                        :options='options.audio'
                        v-if='devices.audio'
                        :show='selectSection === "audio"'
                        top='120px'
                        width='300px'
                        left='-80px'
                        class="desktop-element"
                        arrowPosition='center'
                        @selected='$emit("switchDevice", {audio: $event})'
                    />
                    <!-- Mic Control -->
                </div>
                <div class="flex-column toggle-btn position-relative pl-3 pr-3 desktop-element">
                    <BaseIcons
                        :icon='devices.screen ? "recordingScreen" : "recordingScreenDisabled"'
                        :tooltip='!selectSection ? "Turn on/off your screen" : ""'
                        tooltipTop='120px'
                        tooltipLeft='-40px'
                        @dbClicked='$emit("toggle", "screen")'
                    />
                </div>
            </div>
            <div class="mobile-element">
                <BaseSelect
                    :selected='selected'
                    :options='options.video'
                    v-if='devices.video'
                    :show='selectSection === "video"'
                    top='190px'
                    arrowPosition='left'
                    @selected='$emit("switchDevice", {video: $event})'
                />
                <BaseSelect
                    :selected='selected'
                    :options='options.audio'
                    v-if='devices.audio'
                    :show='selectSection === "audio"'
                    top='190px'
                    arrowPosition='right'
                    @selected='$emit("switchDevice", {audio: $event})'
                />
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .video-controls {
        position: absolute;
        z-index: 15;
    }
    @media (max-width: 960px) {
        .video-controls {
            .controls-container {
                justify-content: space-between !important;
                margin-top: 50px;
                .toggle-btn {
                    padding: 0 10px 0 10px !important;
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'VideoTopControls',
    props: ['devices', 'selected', 'options'],
    data: () => ({
        selectSection: '',
    }),
    methods: {
        toggleSelectSection(section) {
            const selected = this.selectSection;
            if (selected !== section) {
                this.selectSection = section;
            } else {
                this.selectSection = null;
            }
        }
    }
}
</script>