<template>
    <div class="mobile-settings-navbar">
        <div
            class="menu-button poppins smooth-shadow"
            @click="showDropdown = !showDropdown"
        >
            <span class="dots">
                ...
            </span>
        </div>
        <select-dropdown
            :options='navItems'
            :show='showDropdown'
            width='250px'
            top='40px'
            @selected='redirect'
            @close='showDropdown = false'
        />
    </div>
</template>

<style lang="less" scoped>
    .mobile-settings-navbar {
        .menu-button {
            background: #fff;
            width: max-content;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            font-weight: 600;
            cursor: pointer;
            transition: 0.2s;
            .dots {
                position: relative;
                bottom: 2px;
            }
            &:hover {
                background: rgb(236, 236, 236);
            }
            &:active {
                transform: scale(0.9);
            }
        }
    }
</style>

<script>
import SelectDropdown from '../../Common/SelectDropdown';
import { SETTINGS_SIDEBAR } from '../../../assets/js/constants';

export default {
    name: 'MobileSettingsNavbar',
    components: {
        SelectDropdown
    },
    data: () => ({
        navItems: SETTINGS_SIDEBAR,
        showDropdown: false,
    }),
    methods: {
        redirect(section) {
            this.$emit('section', section.section);
        }
    }
}
</script>