var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-container",on:{"click":function($event){return _vm.$emit('clicked')},"dblclick":function($event){return _vm.$emit('dbClicked')},"mouseover":function($event){_vm.showToolTip = true},"mouseleave":function($event){_vm.showToolTip = false}}},[(_vm.iconPath)?_c('div',{staticClass:"clickable"},[_c('img',{style:({
                width: _vm.imageWidth ? _vm.imageWidth : "auto",
                height: _vm.imageHeight ? _vm.imageHeight : "auto",
            }),attrs:{"src":_vm.iconPath,"alt":"icon"}})]):_vm._e(),(_vm.tooltip && _vm.showToolTip)?_c('div',{staticClass:"base-tooltip desktop-element",style:({
        top: _vm.tooltipTop,
        left: _vm.tooltipLeft,
        background: _vm.tooltipBg,
        color: _vm.tooltipColor,
    })},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }