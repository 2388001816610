<template>
    <div class="invoice-listing mb-5">
        <div class="listing-header">
            <div class="listing-title helvetica-medium p-5">
                Billing History
            </div>
        </div>
        <div class="listing-body">
            <table>
                <thead>
                    <tr class="base-header-background">
                        <th class="pl-5 pr-5 pt-3 pb-3">Name</th>
                        <th class="pl-5 pr-5 pt-3 pb-3">Total</th>
                        <th class="pl-5 pr-5 pt-3 pb-3">Status</th>
                        <th class="pl-5 pr-5 pt-3 pb-3"></th>
                    </tr>
                </thead>
                <tbody>
                    <BaseLoader :showLoader='loading' />
                    <tr v-if='!loading && !invoices.length'>
                        <td colspan="4" class="text-center pt-4 pb-4">
                        <!-- <div class="pt-4 text-center" v-if='!loading && !invoices.length'> -->
                            No invoices found
                        <!-- </div> -->
                        </td>
                    </tr>
                    <tr
                        v-else-if='!loading && invoices.length'
                        v-for='invoice in invoices'
                        :key='invoice.id'
                    >
                        <td class="base-divider pl-5 pr-5 pt-4 pb-4">
                            <div class="helvetica-medium regular-size-fonts">
                                {{ invoice.receipt_id }}
                            </div>
                            <div class="small-fonts helvetica-medium">
                                {{ invoice.plan.name }}
                            </div>
                        </td>
                        <td class="base-divider pl-5 pr-5 pt-4 pb-4">
                            ${{ invoice.total }}
                        </td>
                        <td
                            class="base-divider pl-5 pr-5 pt-4 pb-4"
                        >
                            <div :class="invoice.paid ? 'paid-status' : 'unpaid-status'">
                                {{ invoice.paid ? 'Paid' : 'Not Paid' }}
                            </div>
                        </td>
                        <td class="base-divider pl-5 pr-5 pt-4 pb-4">
                            <BaseButton
                                title='View'
                                borderRadius='18px'
                                border='1px solid #dcdfe6'
                                hoverColor='#0098c4'
                                hoverBg='#f9fbfb'
                                @clicked='viewInvoice(invoice.id)'
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .invoice-listing {
        background: #fff;
        border-radius: 7px;
        .unpaid-status {
            background: #fffaf5;
            color: #ee5951;
            padding: 2px 0;
            border-radius: 12px;
            text-align: center;
        }
        .paid-status {
            background: #f5fefa;
            color: #0ad688;
            padding: 2px 0;
            border-radius: 12px;
            text-align: center;
        }
        .listing-header {
            .listing-title {
                font-size: 20px;
            }   
        }
        .listing-body {
            width: 100%;
            table {
                border-collapse: separate;
                border-spacing: 0px;
                width: 100%;
                background: #fff;
                overflow-x: auto;
                white-space: nowrap;
            }
        }
    }
</style>

<script>
export default {
    name: 'InvoiceListing',
    props: ['userId','section'],
    data: () => ({
        loading: false,
        invoices: [],
    }),
    mounted() {
        // console.log(this.$props.section);
        this.getInvoices();
    },
    methods: {
        getInvoices() {
            const userId = this.$props.userId || null;
            this.loading = true;
            this.axios.get(`billing/invoices?user_id=${userId}`)
                .then(response => {
                    this.loading = false;
                    if (response.data.success) {
                        // console.log(response.data.invoices);
                        this.invoices = response.data.invoices;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                })
        },
        viewInvoice(id) {
            this.$router.push({
                path: `/settings/invoice/${id}`,
            });
            this.$emit('updateSection', 'invoice');
        }
    }
};
</script>
