const SAMPLE_VIDEOS = [
    {
        id: 1,
        title: "My sample",
        thumbnail:
            "https://previews.123rf.com/images/goodluz/goodluz1601/goodluz160100349/50631035-der-industriedesigner-auf-projekt-arbeiten-im-b%C3%BCro.jpg",
        steps: 6,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 0,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "2 Days Ago",
    },
    {
        id: 2,
        title: "Horses",
        thumbnail:
            "https://videohive.img.customer.envatousercontent.com/files/ce9a2972-43b1-4dfd-997e-888df672e5ff/inline_image_preview.jpg?auto=compress%2Cformat&fit=crop&crop=top&max-h=8000&max-w=590&s=17e0c4a798a8b82d9215e55412ff62bf",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "5 Days Ago",
    },
    {
        id: 3,
        title: "Horses",
        thumbnail:
            "https://motionarray.imgix.net/preview-451279-cEhy4IEJiPOZeGl3-large.jpg?w=1400&q=60&fit=max&auto=format",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
    {
        id: 4,
        title: "New Video",
        thumbnail:
            "https://iso.500px.com/wp-content/uploads/2015/03/business_cover.jpeg",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
    {
        id: 5,
        title: "Creep",
        thumbnail:
            "https://miro.medium.com/max/2600/1*mtGIfXRPG2FG_zbKJhwWzA.png",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
    {
        id: 6,
        title: "Over Achiever",
        thumbnail:
            "https://media.gettyimages.com/photos/doing-homework-stock-image-picture-id1077316070?s=612x612",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
    {
        id: 7,
        title: "Over Achiever",
        thumbnail:
            "https://media.gettyimages.com/photos/doing-homework-stock-image-picture-id1077316070?s=612x612",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
    {
        id: 8,
        title: "Over Achiever",
        thumbnail:
            "https://media.gettyimages.com/photos/doing-homework-stock-image-picture-id1077316070?s=612x612",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
    {
        id: 9,
        title: "Over Achiever",
        thumbnail:
            "https://media.gettyimages.com/photos/doing-homework-stock-image-picture-id1077316070?s=612x612",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
    {
        id: 10,
        title: "Over Achiever",
        thumbnail:
            "https://media.gettyimages.com/photos/doing-homework-stock-image-picture-id1077316070?s=612x612",
        steps: 10,
        impressions: 12,
        views: 10,
        clicks: 5,
        leads: 9,
        path:
            "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
        last_modified: "1 Month Ago",
    },
];

const FONT_SIZES = [
    {
        id: "1rem",
        label: "Extra Small",
    },
    {
        id: "2rem",
        label: "Small",
    },
    {
        id: "3rem",
        label: "Regular",
    },
    {
        id: "4rem",
        label: "Medium",
    },
    {
        id: "5rem",
        label: "Large",
    },
    {
        id: "6rem",
        label: "Extra Large",
    },
];

const SETTINGS_SIDEBAR = [
    {
        id: 1,
        label: "Account Information",
        icon: "settingsUsers",
        section: "account",
    },
    {
        id: 2,
        label: "Subscription & Billing",
        icon: "settingsCreditCard",
        section: "subscription",
    },
    {
        id: 3,
        label: "Integration",
        icon: "settingsIntegration",
        section: "integrations",
    },
];

const INTEGRATIONS_NAVBAR = [
    {
        id: "email",
        label: "Email",
    },
    {
        id: "webinar",
        label: "Webinar",
    },
    {
        id: "other",
        label: "Other",
    },
    {
        id: "payment_gateway",
        label: "PaymentGateway",
    },
];

const INTEGRATIONS = [
    {
        name: "Aweber",
        parent: "email",
        icon: "aweber",
        service: "aweber",
        description: "Aweber Integration",
        auth: "api/oauth/aweber",
    },
    {
        name: "MailChimp",
        parent: "email",
        icon: "mailchimp",
        service: "mailchimp",
        description: "MailChimp Integration",
        auth: "api/oauth/mailchimp",
    },
    {
        name: "GetResponse",
        parent: "email",
        icon: "getResponse",
        service: "getResponse",
        description: "GetResponse Integration",
        auth: "api/oauth/getresponse",
    },
    {
        name: "Keap",
        parent: "email",
        icon: "keap",
        service: "keap",
        description: "Keap Integration",
        auth: "api/oauth/keap",
    },
    {
        name: "ConvertKit",
        parent: "email",
        icon: "convertKit",
        service: "convertKit",
        description: "ConvertKit Integration",
        auth: {
            action: "/oauth/convertkit",
            inputs: [
                {
                    name: "API Key",
                    type: "text",
                    placeholder: "Your ConvertKit API Key",
                    key: "APIkey",
                    model: "",
                },
                {
                    name: "API Secret Key",
                    type: "text",
                    placeholder: "Your ConvertKit API Secret",
                    key: "APISecretKey",
                    model: "",
                },
            ],
        },
    },
    {
        name: "ActiveCampaign",
        parent: "email",
        icon: "activeCampaign",
        service: "activeCampaign",
        description: "ActiveCampaign Integration",
        auth: {
            action: "/oauth/activecampaign",
            inputs: [
                {
                    name: "API Url",
                    type: "text",
                    placeholder: "Your ActiveCampaign API Url",
                    key: "APIUrl",
                    model: "",
                },
                {
                    name: "API Key",
                    type: "text",
                    placeholder: "Your ActiveCampaign API Key",
                    key: "APIKey",
                    model: "",
                },
            ],
            error: false,
            errorMessage: "",
        },
    },
    {
        name: "GoToWebinar",
        parent: "webinar",
        icon: "gotoWebinar",
        service: "gotoWebinar",
        description: "GoToWebinar Integration",
        auth: "api/oauth/gotowebinar",
    },
    {
        name: "WebinarJam",
        parent: "webinar",
        icon: "webinarJam",
        service: "webinarJam",
        description: "WebinarJam Integration",
        auth: {
            action: "/oauth/webinarjam",
            inputs: [
                {
                    name: "API Key",
                    type: "text",
                    placeholder: "Your WebinarJam API Key",
                    key: "APIKey",
                    model: "",
                },
            ],
        },
    },
    {
        name: "Zapier",
        parent: "other",
        icon: "zapier",
        service: "zapier",
        description: "Zapier Integration",
        auth: {
            action: "/oauth/zapier",
        },
        url:
            "https://zapier.com/app/login?next=/developer/public-invite/115527/62eeb615c36636d039a7177f4da0021f/",
    },
    {
        name: "Stripe",
        parent: "payment_gateway",
        icon: "stripe",
        service: "stripe",
        description: "Stripe Integration",
        auth: {
            action: "/oauth/stripe",
            inputs: [
                {
                    name: "API Published Key",
                    type: "text",
                    placeholder: "Your Stripe API Key",
                    key: "APIKey",
                    model: "",
                },
                {
                    name: "API Secret Key",
                    type: "text",
                    placeholder: "Your Stripe API Secret Key",
                    key: "APISecretKey",
                    model: "",
                },
                {
                    name: "Client Id",
                    type: "text",
                    placeholder: "Your Stripe Client Id",
                    key: "APIClientId",
                    model: "",
                },
            ],
        },
        url: "",
    },
];

const CURRENCY = [
    {
        id: "USD",
        label: "USD",
    },
    {
        id: "GBP",
        label: "GBP",
    },
    {
        id: "EUR",
        label: "EUR",
    },
    {
        id: "AUD",
        label: "AUD",
    },
    {
        id: "CAD",
        label: "CAD",
    },
    {
        id: "NZD",
        label: "NZD",
    },
    {
        id: "HKD",
        label: "HKD",
    },
    {
        id: "INR",
        label: "INR",
    },
    {
        id: "JPY",
        label: "JPY",
    },
    {
        id: "CNY",
        label: "CNY",
    },
];

const ACCOUNT_INTEGRATIONS_SAMPLE = {
    connected: [{}, {}, {}],
    email: [
        {
            id: 182,
            user_id: 13,
            display_name: "ActiveCamp",
            service_name: "ActiveCampaign",
            service_key: "activeCampaign",
            api_key: "o83ojskjakl",
            hash_key: null,
            service_url: "https://bigcommand.api-us1.com",
            access_token: null,
            refresh_token: null,
            account_id: null,
            metadata: null,
            refresh_flag: 0,
            created_at: "2021-03-17 10:23:45",
            updated_at: "2021-03-17 10:23:45",
            integrated_at: "Mar 17, 2021 at 10:23 AM",
            last_activity: "2 days ago",
        },
        {
            id: 186,
            user_id: 13,
            display_name: "MailChimp mine",
            service_name: "MailChimp",
            service_key: "mailchimp",
            api_key: "o83ojskjakl",
            hash_key: null,
            service_url: "https://bigcommand.api-us1.com",
            access_token: null,
            refresh_token: null,
            account_id: null,
            metadata: null,
            refresh_flag: 0,
            created_at: "2021-03-17 10:23:45",
            updated_at: "2021-03-17 10:23:45",
            integrated_at: "Mar 17, 2021 at 10:23 AM",
            last_activity: "2 days ago",
        },
    ],
    other: [
        {
            id: 183,
            user_id: 13,
            display_name: "My Zapier",
            service_name: "zapier",
            service_key: "zapier",
            api_key: null,
            hash_key: null,
            service_url: null,
            access_token: null,
            refresh_token: null,
            account_id: null,
            metadata: null,
            refresh_flag: 0,
            created_at: "2021-03-17 10:23:45",
            updated_at: "2021-03-17 10:23:45",
            integrated_at: "Mar 17, 2021 at 10:23 AM",
            last_activity: "2 days ago",
        },
    ],
    social_media: [],
    crm: [],
    webinar: [
        {
            id: 183,
            user_id: 13,
            display_name: "My webinar",
            service_name: "webinarJam",
            service_key: "webinarJam",
            api_key: null,
            hash_key: null,
            service_url: null,
            access_token: null,
            refresh_token: null,
            account_id: null,
            metadata: null,
            refresh_flag: 0,
            created_at: "2021-03-17 10:23:45",
            updated_at: "2021-03-17 10:23:45",
            integrated_at: "Mar 17, 2021 at 10:23 AM",
            last_activity: "2 days ago",
        },
    ],
    payment_gateway: [
        {
            id: 183,
            user_id: 13,
            display_name: "Stripe",
            service_name: "stripe",
            service_key: "stripe",
            api_key:
                "pk_test_51J0PPJSAUeEGnEtXJxFj8E2lIc1elgeuLg9InlRD82YtecVK6BfpY1YAEe0fiEUXXQe7O3gNzLScSonufEqbj8VU00yz24Tf0M",
            hash_key:
                "sk_test_51J0PPJSAUeEGnEtXIudTFZSfiwjkrYQDeSEO0nOjwiYvhKnE4VJo9Fernr92OfrMrBwJ3sf7lA28xF9LLTnTLqoo00yrZQOwut",
            service_url: null,
            access_token: null,
            refresh_token: null,
            account_id: null,
            metadata: null,
            refresh_flag: 0,
            created_at: "2021-06-09 15:23:45",
            updated_at: "2021-06-09 15:23:45",
            integrated_at: "June 9, 2021 at 4:30 PM",
            last_activity: "1 days ago",
        },
    ],
};

export {
    SAMPLE_VIDEOS,
    FONT_SIZES,
    SETTINGS_SIDEBAR,
    INTEGRATIONS_NAVBAR,
    INTEGRATIONS,
    ACCOUNT_INTEGRATIONS_SAMPLE,
    CURRENCY,
};
