<template>
<transition name="fade">
<div style="position:relative">
    <button @click="showCampaignOptions" class="circle-button p-2 bd-highlight">
        <div class="dot1"></div>
        <div class="dot2"></div>
        <div class="dot3"></div>
    </button>  

<div v-if="CampaignOptionsDropdown == true" class="campaignOptions">
 <p v-on-clickaway="away" ></p>
<div class="arrow-up"></div>
    <ul class="dropdown" style="list-style-type:none">
        <li class="dropdown-list">View campaign performance</li>
        <li @click="ShareEmbed"  class="dropdown-list">Share and embed</li>
        <li @click="DomainCustomizationModal"  class="dropdown-list">Custom Domain</li>
        <li @click="IntergrationModal"  class="dropdown-list">Intergration & mapping</li>
        <li @click="ConfirmDeleteCampaign" class="dropdown-list-delete">Delete campaign</li>
     </ul>  
</div>

<ShareAndEmbed 
:show='openShareModel'
:video='campaignData'
@close='closeShareModel' 
/>

<ErrorIntergrationMappingModal
:show='openIntergrationModal'
@close='closeIntergrationModal'
/>

<DomainCustomization 
:show='openDomainCustomization'
@close='closeDomainCustomization'
/>

<ConfirmModal
:show="openConfirmModal"
:subText="'Are you sure you want to delete this campaign ?'"
:title="'DELETE CAMPAIGN'"
:type="'delete'"
@close="closeConfirmModal"
@confirmed="DeleteCampaign"
 />

</div>  

  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import ShareAndEmbed from './Modals/ShareAndEmbed';
import ErrorIntergrationMappingModal from './Modals/ErrorIntergrationMappingModal';
import DomainCustomization from './Modals/DomainCustomization';
import ConfirmModal from './Modals/ConfirmModal';

export default {
mixins: [ clickaway ],
components:{
    ShareAndEmbed,
    ErrorIntergrationMappingModal,
    DomainCustomization,
    ConfirmModal,
},
props:['isLoaded','campaignData'],
data(){
    return{
        CampaignOptionsDropdown:false,
        openShareModel:false,
        openIntergrationModal:false,
        SelectedCampaign:null,
        openDomainCustomization:false,
        openConfirmModal:false,
    }   
},
methods: {
    closeDomainCustomization(){
      this.openDomainCustomization = false;
    },
    DomainCustomizationModal(){
       this.openDomainCustomization = true;
    },

    closeShareModel() {
      this.openShareModel =false;    
    },
     away: function() {
      this.CampaignOptionsDropdown = !this.CampaignOptionsDropdown;
    },
    ShareEmbed() {
      this.openShareModel = true;
    },
    IntergrationModal() {
       this.openIntergrationModal = true;
    },
    closeIntergrationModal(){
      this.openIntergrationModal = false;
    },
    showCampaignOptions() {
      this.CampaignOptionsDropdown = !this.CampaignOptionsDropdown;
    },
    ConfirmDeleteCampaign(){
      this.openConfirmModal = true;
   
    },
    closeConfirmModal(){
      this.openConfirmModal = false;
    },
    
    DeleteCampaign() {           
            const campaign = this.campaignData;
            if (campaign && campaign.id) {
              this.openConfirmModal = false;
                const url = `campaigns/${campaign.id}`;
                this.axios.delete(url)
                    .then(response => {
                        if (response.data.success) {
                           const projectId= this.$route.params.project_id;
                           this.$router.push(`/projects/${projectId}`);
                                
                        } else {
                            this.message = 'Failed to delete campaign';
                            this.showMessage = true;
                        }
                    })
            }
        }
}
}
</script>

<style>
.circle-button {
  width: 40px;
  height:40px;
  background-color: #1E3941;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.dot1 {
 width: 5px;
 height: 5px;
 background-color: white;
 position: absolute;
 border-radius: 50%;
 top:16px;
}
.dot2 {
 width: 5px;
 height: 5px;
 background-color: white;
 position: absolute;
 left: 16px;
 border-radius: 50%;
 top:16px;
}
.dot3 {
 width: 5px;
 height: 5px;
 background-color: white;
 position: absolute;
 left:24px;
 border-radius: 50%;
 top:16px;
 }

.campaignOptions {
 position: absolute;
 z-index: 5;
 border-radius: 10px;
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 width: 269px;
 height: 245px;
 left: -125px;
 top: 55px;
 font-size: 14px;
 text-align: center;
 color: rgb(3, 3, 3);
 background: #E8F0F6;
 text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
}

.arrow-up {
 width: 0; 
 height: 0; 
 border-left: 20px solid transparent;
 border-right: 20px solid transparent;
 position: absolute;
 left: 127px;
 bottom: 235px;
 border-bottom: 20px solid #E8F0F6;
}
.dropdown {
    position: absolute;
    text-align: justify;
    padding: 0;
}
.dropdown-list {
    padding: 10px;
}

.dropdown-list:hover{
  background:#d9e7f7;
}
.dropdown-list-delete{
    padding: 10px;
    color: red;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}

</style>