<template>
    <div class="projects-container">
        <div class="projects-content flex-column position-relative">
            <projects-header
                @filter='filterData'
                @createProject='showCreateModal = true'
                @query='searchProjects'
                @changeView='changeView'
                :totalProjects='projects.length'
            />

            <create-project-modal @reload='getProjects' :show='showCreateModal' @close='showCreateModal = false' />
            <BaseLoader :showLoader='loading' class="mt-4" />
            <div class="body-section" v-if='!loading'>
                <projects-tiles :projects='projects' class="pt-3" :show='dashboardType === "tiles"' />
                <projects-table :projects='projects' class="pt-3" :show='dashboardType === "table"' />
            </div>
        </div>
    </div>
</template>

<script>
import ProjectsHeader from '../../components/Projects/ProjectsHeader';
import CreateProjectModal from '../../components/Projects/CreateProjectModal';
import ProjectsTiles from '../../components/Projects/ProjectsTiles';
import ProjectsTable from '../../components/Projects/ProjectsTable';

export default {
    name: 'ProjectIndex',
    components: {
        ProjectsHeader,
        CreateProjectModal,
        ProjectsTiles,
        ProjectsTable,
    },
    data: () => ({
        createCampaign: true,
        showCreateModal: false,
        projects: [],
        dashboardType: 'tiles',
        loading: false,
    }),
    mounted() {
        this.getProjects();
    },
    methods: {
        filterData(data) {
            console.log(data);
            // Todo: backend search logic
        },
        searchProjects(query) {
            console.log(query);
            // Todo: backend search logic
        },
        getProjects() {
            this.loading = true;
            this.axios.get('projects')
                .then(response => {
                    this.loading = false;
                    this.projects = response.data;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                })
        },
        changeView() {
            let dashboardType = this.dashboardType;
            if (dashboardType === 'tiles') {
                dashboardType = 'table';
            } else {
                dashboardType = 'tiles';
            }
            this.dashboardType = dashboardType;
        }
    }
};
</script>

<style lang="less" scoped>
    .projects-container {
        width: 100%;
        height: 100%;
        position: relative;
        top: 63px;
        left: 0;
        background: #fff;
        .projects-content {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            padding: 30px;
            border-radius: 10px;
        }
    }
    @media (max-width: 960px) {
        .projects-container {
            .projects-content {
                width: 98%;
                padding: 10px;
            }
        }
    }
</style>