<template>
  <transition name="fade">
       <div class="Domain-customization-embed-modal" v-if="show">
            <div class="Domain-customization-embed-content">
                <div class="Domain-customization-embed-container">
                    <div class="Domain-customization-embed-head d-flex justify-content-between p-3">
                        <div></div>
                        <div class="Domain-customization-embed-head-title helvetica-medium">Domain Customization</div>
                        <div class="share-close-btn cursor-pointer" @click="$emit('close')">
                            <BaseIcons icon='cross' />
                        </div>
                         </div> 
                          <div class="domain-customization-body">
                            <form class="domain-customization-form">
                            <label class="label" >Choose a new domain</label>
                            <select name="Special.adilo.com" id="domain" class="domain-customization-form-select">
                              <option value="Special adilo">Special adilo</option>
                              <option value="Special adilo">Special adilo</option>
                            </select>
                            
                            <a href="#" class="domain-customization-form-link">+ Connect new domain</a>
                            <br><br>
                            <a href="">https://special.adilo.com/7ueUR9</a>
                        
                           <div class="Domain-customization-body-button-yes" style="margin-left=64px">
                             <BaseButton
                                title='Yes'
                                background='#862D83'
                                color='#fff' 
                                height='36px'
                                width='147px'
                              :loading='loading'
                                />
                        </div>
                        <div class="Domain-customization-body-button-no" style="margin-left=64px" >
                             <BaseButton
                                title='No'
                                background='#C094BF'
                                color='#fff'
                                height='36px'
                                width='147px'
                              :loading='loading'
                                />
                        </div>
                        </form>
                        </div>
                     </div>
                 </div>
             </div>            
  </transition>    
</template>

<script>


export default {
props:['show'],
data () {
return{

}
},
methods:{

}

}
</script>

<style lang="less" scoped>

    .Domain-customization-embed-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        .divide {
            border-bottom: 1px solid rgba(187, 187, 187, 0.616);
        }
        .highlighted {
            background: rgba(0, 0, 0, 0.274) !important;
        }
        .Domain-customization-embed-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .Domain-customization-embed-container {
                background: #fff;
                width: 637px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                height: max-content;
                position: relative;
                font-size: 14px;
                .Domain-customization-embed-head {
                    border-bottom: 1px solid #0DABD8;
                    .-embed-head-title {
                        font-size: 15px;
                        position: relative;
                        top: 5px;
                    }
                }
                .domain-customization-body{
                  height: 100px;
                  padding: 100px;
                  position: relative;
                }
                  .domain-customization-body-text{
                    position: absolute;
                    bottom: 117px;
                    left: 178px;
                    font-size: 17px;
                  }
                    .domain-customization-body-button{
                      position: relative;
                      top: 3px;
                      left: 160px;
                      background: rgb(134, 45, 131);
                      color: rgb(255, 255, 255);
                      width: 147px;
                      height: 36px;
                      border-radius: 5px;
                  }
                    .domain-customization-form{
                      position: absolute;
                      bottom: 23px;
                      width: 570PX;
                      left: 37px;
                      height: 163px;
                    }
                     .domain-customization-form .label{
                       display: block;
                       font-size: 18px;
                     }
                       .Domain-customization-body-button-yes{
                          position: absolute;
                          left: 350px;  
                          margin-top: 20px;                                  
                       }
                       .Domain-customization-body-button-no{
                          margin-left: 74px;
                          margin-top: 20px;  
                      } 
                        .domain-customization-form-select{
                           width: 404px;
                           height: 36px;
                           border-radius: 10px;
                           border: 1px solid #47a5e8;
                        } 
                        .domain-customization-form-link{
                          color: green;
                        }
                  } 
            }
         }  
      
</style>