<template>
    <div class="checkbox-labels d-flex" @click="$emit('clicked', label)">
        <div class="checkbox">
            <BaseIcons :icon='icon' />
        </div>
        <div class="label">
            {{ label }}
        </div>
    </div>
</template>

<style lang="less" scoped>
    .checkbox-labels {
        cursor: pointer;
        &:hover {
            color: #00ACDC;
        }
        .label {
            padding: 1.5px 0 0 10px;
        }
    }
</style>

<script>
export default {
    name: 'CheckboxLabels',
    props: ['icon', 'label']
}
</script>