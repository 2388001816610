<template>
    <transition name="fade">
        <div class="project-tiles" v-if="show">
            <div
                v-for='project in projects'
                :key='project.id'
                class="tiles"
            >
                <span @click='redirectToVideos(project.project_id)'>
                    <tile
                        :count='project.project_campaigns_count'
                        :title='project.title'
                    />
                </span>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .project-tiles {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        left: 0;
        right: 0;
        .tiles {
            width: 25%;
            border: 2px solid transparent;
            transition: 0.3s;
            &:hover {
                border: 2px solid #21455E;
                background: #F6F7F9;
            }
        }
    }
    @media (max-width: 960px) {
        .project-tiles {
            flex-direction: column;
            .tiles {
                width: 100% !important;
                height: 240px;
                padding-bottom: 40px;
            }
        }
    }
</style>

<script>
import Tile from '../Common/Tile';

export default {
    name: "ProjectsTiles",
    props: ['projects', 'show'],
    components: {
        Tile,
    },
    data: () => ({
        videos: [],
        tileData: {
            imageBig: null,
            imageSmall: null,
            count: null,
        }
    }),
    mounted() {
    },
    methods: {
        getVideoImage(videos, index) {
            const row = videos[index];
            return row && row.thumbnail ? row.thumbnail : null;
        },
        redirectToVideos(id) {
            this.$router.push({
                path: `/projects/${id}`,
            });
        }
    }
}
</script>