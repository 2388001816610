import Test from '../components/Test';
import Login from '../views/Auth/Login';
import Register from '../views/Auth/Register';
import Subscription from '../views/Subscription';
import ResetPassword from '../views/Auth/ResetPassword';
import Projects from '../views/Projects/ProjectIndex';
import ProjectCampaigns from '../views/Projects/ProjectCampaigns';
import Record from '../views/Videos/Record';
import Select from '../components/Common/Base/BaseSelect';
import CreateCampaign from '../views/Projects/CreateCampaign';
import CreateCampaignType from '../views/Projects/CreateCampaignType';
import Edit from '../views/Projects/EditCampaign';
import Upload from '../views/Videos/Upload';
import Settings from '../views/Settings.vue';
import EmailUpdate from '../views/EmailUpdate.vue';
import VideoInteractions from '../views/VideoInteractions.vue';
import Public from '../views/public/public.vue';

export default {
    Test,
    Login,
    Register,
    Subscription,
    ResetPassword,
    Projects,
    ProjectCampaigns,
    Record,
    Select,
    CreateCampaign,
    CreateCampaignType,
    Upload,
    Settings,
    EmailUpdate,
    VideoInteractions,
    Edit,
    Public,
}
