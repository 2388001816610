<template>
	<div class="collect-payment-container">
		<div
			class="response-header-section d-flex justify-content-between poppins-medium"
		>
			<div class="title">Configure Payment Collection</div>
			<div
				class="back-btn base-links cursor-pointer"
				@click="$emit('section', '')"
			>
				Change Interaction
			</div>
		</div>
		<div class="response-triggers mt-4">
			<switch-with-label
				v-for="(element, index) in togglesElements"
				:key="index"
				:toggle="toggles[element.toggle]"
				:label="element.label"
				@clicked="handleToggles(element.toggle)"
				class="my-2"
			/>
		</div>
		<div class="payment-inputs">
			<div class="misc-option d-flex pb-2">
				<div class="action-text">Select Currency</div>
				<div class="action-trigger position-relative">
					<drop-down-button
						:midText="currencyOptn.currency"
						rightIcon="downArrow"
						background="#fff"
						border-radius="5px"
						color="#9E9E9E"
						fontSize="15px"
						fontFamily="Poppins-Medium"
						width="159px"
						height="45px"
						@clicked="sizeDropdown = !sizeDropdown"
					/>
					<select-dropdown
						:show="sizeDropdown"
						:options="currencyOtions"
						borderRadius="0px"
						border="1px solid transparent"
						width="100%"
						top="60px"
						left="0"
						@selected="setCurrencyOptns"
					/>
				</div>
			</div>
			<input-with-label
				headLabel="Enter item price"
				inputType="text"
				class="item-input"
				:inputValue="price"
				keyTrigger="Enter"
				@value="price = $event"
			/>
		</div>
	</div>
</template>

<style lang="less" scoped>
.collect-payment-container {
	.response-triggers {
		.switch-label-trigger-text {
			font-size: 16px;
		}
	}
	.payment-inputs {
		.misc-option {
			.action-text {
				align-self: center;
				font-size: 15px;
				color: #1e39419a;
				width: 150px;
				text-align: left;
				font-weight: 400;
				font-family: Poppins;
			}
			.action-trigger {
				width: 340px;
				height: 45px;
				background: #fff;
				border-radius: 10px;
				display: flex;
				justify-content: flex-end;
				margin-left: 50px;
			}
		}
		.item-input {
			width: 100%;
			.label {
				color: #1e39419a;
				font-size: 15px;
				font-family: Poppins;
				font-weight: 400;
			}
			.type-input {
				width: 340px;
				margin-left: 50px;
				.form-control {
					height: 45px;
					border-radius: 10px;
					border: 1px solid rgba(134, 45, 131, 0.5) !important;
				}
			}
		}
	}
}
</style>

<script>
import { CURRENCY } from "../../../../assets/js/constants";
import SwitchWithLabel from "../../../Common/SwitchWithLabel";
import DropDownButton from "../../../Common/DropDownButton";
import SelectDropdown from "../../../Common/SelectDropdown";
import InputWithLabel from "../InputWithLabel";

export default {
	name: "CollectPayment",
	props: ["interactionsData"],
	components: {
		SwitchWithLabel,
		DropDownButton,
		SelectDropdown,
		InputWithLabel,
	},
	data: () => ({
		toggles: {
			accept_card: false,
			accept_paypal: false,
		},
		togglesElements: [
			{
				toggle: "accept_card",
				label: "Accept Card? (Stripe: nemistv@gmail.com)",
			},
			{
				toggle: "accept_paypal",
				label: "Accept PayPal? (nemistv@gmail.com)",
			},
		],
		currencyOtions: CURRENCY,
		sizeDropdown: false,
		currencyOptn: {
			id: "USD",
			currency: "USD",
		},
		price: "",
		currency: "USD",
	}),
	mounted() {
		this.populateInteractionsData();
	},
	methods: {
		handleToggles(toggle) {
			console.log(toggle);
			this.toggles[toggle] = !this.toggles[toggle];
		},
		setCurrencyOptns(currencyOptn) {
			this.currencyOptn.id = currencyOptn.id;
			this.currencyOptn.currency = currencyOptn.label;
			this.currency = currencyOptn.id;
		},
		populateInteractionsData() {
			const interactionsData = this.$props.interactionsData;

			if (interactionsData) {
				const toggles = this.toggles;
				toggles.accept_card = interactionsData.accept_card;
				toggles.accept_paypal = interactionsData.accept_paypal;
				this.price = interactionsData.price;
				this.currencyOptn.id = interactionsData.currency;
				// this.currencyOptn.currency = interactionsData.currency;
				this.currency = interactionsData.currency
					? interactionsData.currency
					: this.currency;
			}
		},
	},
	watch: {
		$data: {
			handler(data) {
				let { toggles, currency, price } = data;
				const { accept_card, accept_paypal } = toggles;
				this.$emit("data", {
					accept_card,
					accept_paypal,
					currency,
					price,
				});
			},
			deep: true,
		},
		interactionsData: {
			handler() {
				this.populateInteractionsData();
			},
			deep: true,
		},
	},
};
</script>
