<template>
    <div class="switch-label-trigger d-flex justify-content-between">
        <div class="switch-label-trigger-text pr-5 poppins">{{ label }}</div>
        <switch-toggle class="d-flex align-self-center" :active='toggle' @clicked='$emit("clicked")' />
    </div>
</template>

<style lang="less">
    .switch-label-trigger {
        .switch-label-trigger-text {
            color: #1e39419a;
        }
    }
</style>

<script>
import SwitchToggle from './Widgets/SwitchToggle'

export default {
    components: { SwitchToggle },
    name: 'SwitchWithLabel',
    props: ['toggle', 'label'],
}
</script>