<template>
    <div class="create-campaign-type">
        <BaseLoader
            :showLoader='mainRequest'
            :centered='true'
            color='#862D83'
        />
        <div class="cross-icon cross-icon-purple flex-column" @click='showWarning = true'>
            <BaseIcons icon='cross' />
            <div class="icon-text poppins-medium">ESC</div>
        </div>
        <div class="create-campaign-type-content w-100 h-100 d-flex flex-column justify-content-center"  v-if='!mainRequest'>
            <div class="section d-flex justify-content-between">
                <div
                    class="option flex-column"
                    @click='selected = "record"'
                    :class="{'purple-border': (selected === 'record')}"
                >
                    <BaseIcons icon='record' />
                    <div class="text poppins-medium pt-1">Record a video</div>
                    <div class="sub-text poppins-light pt-1">on your connected cam</div>
                </div>
                <div
                    class="option flex-column"
                    @click='selected = "upload"'
                    :class="{'purple-border': (selected === 'upload')}"
                >
                    <BaseIcons icon='upload' />
                    <div class="text poppins-medium pt-1">Upload a video</div>
                    <div class="sub-text poppins-light pt-1">or choose from library</div>
                </div>
            </div>
            <div class="create-campaign-type-footer d-flex justify-content-between">
                <div></div>
                <BaseButton
                    title='Continue'
                    background='#862D83'
                    color='#fff'
                    height='36px'
                    width='147px'
                    :loading='loading'
                    @clicked='processRequest'
                />
            </div>
        </div>
        <div class="modals">
            <campaign-warning @clicked='handleWarningConfirm' />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .create-campaign-type {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 15;
        background: #F5F8FA;
        overflow: auto;
        .section {
            width: 70%;
            display: flex;
            align-self: center;
            .creation-title {
                font-size: 30px;
            }
            .option {
                width: 305.22px;
                height: 151px;
                background: #fff;
                border-radius: 5px;
                padding: 15px;
                cursor: pointer;
                border: 2px solid transparent;
                transition: 0.3s;
                &:hover {
                    border: 2px solid #862D83;
                    transform: scale(1.1);
                }
                &:active {
                    transform: scale(0.98);
                }
                .text,
                .sub-text {
                    font-size: 17px;
                }
            }
        }
        .create-campaign-type-footer {
            width: 70%;
            align-self: center;
            position: relative;
            top: 200px;
        }
    }
    @media (max-width: 960px) {
        .create-campaign-type {
            .section {
                flex-direction: column;
                align-items: center;
                height: 50%;
            }
            .create-campaign-type-footer {
                top: 70px;
            }
        }
    }
</style>

<script>
import CampaignWarning from '../../components/Projects/CampaignWarning';

export default {
    name: 'CreateCampaignType',
    components: {
        CampaignWarning,
    },
    data: () => ({
        selected: '',
        loading: false,
        mainRequest: false,
        showWarning: false,
        projectId: null,
    }),
    mounted() {
        this.checkIfExists();
    },
    methods: {
        checkIfExists() {
            const campaignId = this.$route.params.id;
            const url = `/campaigns/${campaignId}`;
            this.mainRequest = true;
            this.axios.get(url)
                .then(response => {
                    this.mainRequest = false;
                    this.handleMainRequestResponse(response.data);
                })
                .catch(error => {
                    this.mainRequest = false;
                    console.log(error);
                })
        },
        handleMainRequestResponse(response) {
            const video = response.video;
            const projectId = response.project_id;
            if (video) {
                const hasPath = video.path;
                // This means this page should be blocked
                if (hasPath) {
                    // Todo: Redirect to video
                }
            }
            this.projectId = projectId;
        },
        processRequest() {
            if (!this.selected) return false;
            const campaignId = this.$route.params.id;
            const url = `/campaigns/${campaignId}`;
            this.loading = true;
            this.axios.put(url)
                .then(response => {
                    this.handleCampaignUpdateResponse(response.data);
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                })
        },
        handleCampaignUpdateResponse(response) {
            this.loading = false;
            if (response.success) {
                this.redirect();
            }
        },
        redirect() {
            const selected = this.selected;
            const campaignId = this.$route.params.id;
            const current_step = this.$route.params.current_step;
            const path = `/campaigns/${campaignId}/${current_step}/${selected}`;
            this.$router.push({ path });
        },
        handleWarningConfirm(response) {
            this.showWarning = false;
            const goBack = response === 'yes';
            const projectId = this.projectId;
            const path = projectId ? `/projects/${projectId}` : '/projects';
            if (goBack) {
                this.$router.push({ path });
            }
        }
    }
}
</script>